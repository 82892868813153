import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth/index';
import alerts from './modules/alerts/index'
import subscriptions from './modules/subscriptions/index'
import contacts from './modules/contacts/index'
import settings from './modules/settings/index'
import login from './modules/login/index'
import modal from './modules/modal/index'
import bookmarks from './modules/bookmarks/index'
import tagManager from './modules/tagManager/index'
import pageTransition from './modules/pageTransition/index'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    alerts,
    subscriptions,
    contacts,
    settings,
    login,
    modal,
    bookmarks,
    tagManager,
    pageTransition
  },
});
