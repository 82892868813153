import client from './api/apiConnector.js';

export default {
  async getSessions() {
    const response = await client().get('/auth/sessions');
    return response.data;
  },
  async disconnectSessions() {
    const response = await client().delete('/auth/sessions');
    return response.data;
  },
  async disconnectSession(uuid) {
    const response = await client().delete('/auth/sessions/' + uuid);
    return response.status;
  }
};