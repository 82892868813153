import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const isMobileDisplay = window.matchMedia(`screen and (max-width: ${process.env.VUE_APP_MOBILE_BREAKPOINT})`).matches;

const routes = [
  //Init
  {
    path: '',
    name: 'Init',
    component: () => import('@v/Init.vue'),
  },
  // Account
  {
    path: '',
    name: 'Account',
    component: () => import('@v/Account/Account.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@v/Account/Dashboard.vue'),
        meta: { previousRoute: 'Dashboard'},
        alias: '/D'
      },
      {
        path: '',
        name: 'Roadbook',
        component: () => import('@v/Account/Roadbook.vue'),
        meta: { previousRoute: 'Dashboard'},
      },
      {
        path: '',
        name: 'PersonalInfo',
        component: () => import('@v/Account/PersonalInfo.vue'),
        meta: { previousRoute: 'AccountMenu' },
      },
      {
        path: '',
        name: 'AlertPeriods',
        component: () => import('@v/Account/AlertPeriods.vue'),
        meta: { previousRoute: 'AccountMenu' },
      },
      {
        path: '',
        name: 'Subscriptions',
        component: () => import('@v/Account/Subscriptions.vue'),
        meta: { previousRoute: 'AccountMenu' },
      },
      {
        path: '',
        name: 'Favorites',
        component: () => import('@v/Account/Favorites.vue'),
        meta: { previousRoute: 'AccountMenu'},
        props: true
      },
      {
        path: '',
        name: 'BookmarksHistory',
        component: () => import('@v/Account/BookmarksHistory.vue'),
        meta: { previousRoute: 'AccountMenu'},
        props: true
      },
      {
        path: '',
        name: 'BookmarksDeleted',
        component: () => import('@v/Account/BookmarksDeleted.vue'),
        meta: { previousRoute: 'AccountMenu'},
        props: true
      },
      {
        path: '',
        name: 'AlertSelectedPeriod',
        component: () => import(`@v/Account/alert-period/AlertPeriod${isMobileDisplay ? 'Mobile' : 'Desktop'}`),
        children: [
          {
            path: '',
            name: 'AlertPeriodSubscriptions',
            component: () => import('@v/Account/alert-period/AlertSubscriptionsMobile.vue'),
            meta: { previousRoute: 'AlertPeriods' },
          }
        ],
      },
    ]
  },
  {
    path: '',
    name: 'AccountMenu',
    component: () => import('@v/Account/AccountMenu.vue'),
  },
  // Login
  {
    path: '',
    name: 'AlertPeriod',
    component: () => import('@v/Login/AlertPeriod.vue'),
    meta: { previousRoute: 'CreateAccount' },
  },
  {
    path: '',
    name: 'AlertSubscriptions',
    component: () => import('@v/Login/AlertSubscriptions.vue'),
    meta: { previousRoute: 'AlertPeriod' },
  },
  {
    path: '/:token',
    name: 'Login',
    alias: '/a',
    component: () => import('@v/Login/Login.vue'),
  },
  {
    path: '',
    name: 'ValidateLogin',
    component: () => import('@v/Login/ValidateLogin.vue'),
  },
  {
    path: '',
    name: 'Welcome',
    component: () => import('@v/Login/Welcome.vue'),
  },
  {
    path: '/:token',
    name: 'CreateAccount',
    component: () => import('@v/Login/CreateAccount.vue'),
  },
  {
    path: '',
    name: 'FinishAuthentication',
    component: () => import('@v/Login/FinishAuthentication.vue'),
  },
  {
    path: '',
    name: 'ConsumedToken',
    component: () => import('@v/Login/ConsumedToken.vue'),
  },

];

const router = new VueRouter({
  routes,
  mode: 'abstract'
})

export default router
