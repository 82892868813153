<template lang="pug">
  .alert-tile
    .alert-top-bar
      .alert-text
        .alert-title {{ subscription.name_translations[language] ? subscription.name_translations[language] : subscription.name }}
        .alert-description {{ subscription.description_translations[language] ? subscription.description_translations[language] : subscription.description }}
      .switchers
        #mobile-switch(
          v-if="subscription.expedition_mode.includes('mobile')"
        )
          span(:class="{ selected: (isSelectedSwitch(subscription, 'mobile') && isMobileSet) || toToogle('mobile')}") {{ $t('by_phone') }}
          .switch(
            :active="(subscription.subscribe.includes('mobile')  && isMobileSet) || toToogle('mobile')"
            @click="toggleSubscription('mobile')"
          )
        #email-switch(
          v-if="subscription.expedition_mode.includes('email')"
        )
          span(:class="{ selected: (isSelectedSwitch(subscription, 'email') && isEmailSet) || toToogle('email') }") {{ $t('by_mail') }}
          .switch(
            :active="(subscription.subscribe.includes('email') && isEmailSet) || toToogle('email')"
            @click="toggleSubscription('email')"
          )
</template>

<script>

export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    }
  },
  computed: {
    language() {
      return this.$store.state.settings.site_lang;
    },
    emailToken() {
      return this.$store.state.auth.tunnel == "token" && this.$store.state.auth.auth.email;
    },
    mobileToken() {
      return this.$store.state.auth.tunnel == "token" && this.$store.state.auth.auth.mobile;
    },
    isMobileSet() {
      return (this.$store.state.auth.login.type == 'mobile' || this.$store.state.contacts.information.mobile ? true : false ) || this.mobileToken;
    },
    isEmailSet() {
      return (this.$store.state.auth.login.type == 'email' || this.$store.state.contacts.information.email ? true : false ) || this.emailToken;
    },
    hasValidatedMissingInfo() {
      return this.$store.state.contacts.information.tmp_login ? this.$store.state.contacts.information.tmp_login.validated : false;
    },
    modalState() {
      return this.$store.state.modal.opened;
    },
  },
  watch: {
    modalState(newVal, oldVal) {
      this.$emit('change', this.subscription);
    }
  },
  methods: {
    toggleSubscription(type) {
      if( type == 'mobile' && !this.isMobileSet || type == 'email' && !this.isEmailSet ) {
        this.$toast( this.$t('toast.missing-information') + type, {
          toastClassName: "toast-missing-info",
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        this.$store.dispatch('contacts/setInformationByKey', {
          key: "toToogle",
          value: {
            type: type,
          }
        });

        this.openModal();
      }
      else {
        if (this.subscription.subscribe.includes(type)) {
          const index = this.subscription.subscribe.indexOf(type);
          this.subscription.subscribe.splice(index, 1);
          this.$toast( this.$t('toast.desubscription') + type + '.', {
            toastClassName: "toast-desubscription",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        } else {
          this.subscription.subscribe.push(type);
          this.$toast( this.$t('toast.subscription') + type + '.', {
            toastClassName: "toast-subscription",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }
        this.$emit('change', this.subscription);
      }
    },
    isSelectedSwitch(subscription, type) {
      return subscription.subscribe.includes(type);
    },
    toToogle(type) {
      if(this.$store.state.contacts.information.tmp_login && this.$store.state.contacts.information.toToogle && this.$store.state.contacts.information.tmp_login.type == type && this.$store.state.contacts.information.tmp_login.validated) {
        if (this.subscription.subscribe.includes(type)) {
          const index = this.subscription.subscribe.indexOf(type);
          this.subscription.subscribe.splice(index, 1);
        } else {
          this.subscription.subscribe.push(type);
        }
        this.$store.state.contacts.information.toToogle = null;
        this.$emit('change', this.subscription);
        return true;
      }
      return false;
    },
    openModal() {
      this.$store.commit('modal/setType', 'MissingInformation');
      this.$store.commit('modal/setOpened', true);
    },
  }
}
</script>
