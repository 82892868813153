import DatePicker from './RcDatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          from: null,
          to: null,
        }
      },
    },
  },
  data() {
    return {
      hovering: null,
    }
  },
  methods: {
    handleInput(newValue) {
      let from = newValue.from;
      let to = newValue.to;

      if(from && to && from.isAfter(to)) {
        to = from.clone();
      }
      else if(from && to && to.isBefore(from)) {
        from = to.clone();
      }

      this.$emit('periodChange', {from, to});
    }
  },
};