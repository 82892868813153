<template lang="pug">
    .containt-footer
        .text {{ $t(this.spanText) }}
        .action
          button.btn(@click="redirectToRoute")
            account.account
            .btn-text {{ $t(this.btnText) }}
</template>

<script>

import Account from '@/assets/account.svg';

export default {
  components: {
    Account
  },
  props: {
    spanText: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    redirect: {
      type: String,
      required: true
    }
  },
  methods: {
      redirectToRoute() {
          this.$store.dispatch("tagManager/sendEvent", {
            event: this.redirect == "CreateAccount" ? 'cta_to_create_new_account' : 'cta_to_login' ,
          });
          this.$router.push({name: this.redirect});
      }
  }
}
</script>