import Vue from 'vue';
import { generateMockAlertsPeriod } from '../../../../tests/mocks/alerts.js';

const state = {
  myAlerts: [],
  alertsPeriod: null,
  hasSet: false
};

const mutations = {
  setSubscriptions(currentState, newSubscriptions) {
    Vue.set(currentState.alertsPeriod, 'subscriptions_type', newSubscriptions);
  },
  setSubscriptionsType(currentState, newSubscriptions) {
    let updatedSubscriptions = [];
    newSubscriptions.forEach(subscription => {
      let existingSubscription = currentState.alertsPeriod.subscriptions_type.filter( (sub_type) => {
        return sub_type.id == subscription.id
      });
      if(existingSubscription.length) {
        //ALREADY EXIST
        let updatedSubType = subscription;
        updatedSubType.subscribe = existingSubscription[0].subscribe;
        updatedSubscriptions.push(updatedSubType)
      }
      else {
        updatedSubscriptions.push(subscription)
      }
    });
    currentState.alertsPeriod.subscriptions_type = newSubscriptions;
  },
  setAlertPeriod(currentState, newPeriod) {
    Vue.set(currentState.alertsPeriod, 'start_date', newPeriod.start);
    Vue.set(currentState.alertsPeriod, 'end_date', newPeriod.end);
  },
  setAlertPeriodTitle(currentState, title) {
    Vue.set(currentState.alertsPeriod, 'title', title);
  },
  setUserAlerts(currentState, newAlerts) {
    Vue.set(currentState, 'myAlerts', newAlerts);
  },
  set(currentState, alertPeriod) {
    currentState.alertsPeriod = alertPeriod;
  },
  reset(currentState) {
    currentState.alertsPeriod = null;
    currentState.myAlerts = [];
  },
  hasSet(currentState, hasSet) {
    currentState.hasSet = hasSet;
  }
};

export { mutations, state };