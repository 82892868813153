import { generateMockAlertsPeriod } from '@mocks/alerts';
import client from './api/apiConnector.js';


export default {
  async getSubscriptions() {
    const response = await client().get('/alerts/subscriptions_type');
    return response.data;
  },
  async getAlertPeriods() {
    const response = await client().get('/contact/alerts');
    return response.data;
  },
  async getAlertPeriod(periodId) {
    const response = await client().get(`/contact/alerts/${periodId}`);
    return response.data;
  },
  async setAlertPeriod(periodId, newAlertPeriod) {
    if (!!periodId) {
      await client().put(`/contact/alerts/${periodId}`, newAlertPeriod);
      return periodId;
    } else {
      const response = await client().post(`/contact/alerts`, newAlertPeriod);
      return response.data.id;
    }
  },
    async getAllInterests() {
        const response = await client().get('/contact/interests')
        return response.data;
    },
  async deleteAlertPeriod(periodId) {
    const response = await client().delete(`/contact/alerts/${periodId}`);
    return response.data;
  },
  async getInterests() {
    const response = await client().get('/contact/interests');
    return response.data;
  },
  async setInterests(body) {
    try {
      await client().put('/contact/interests', body);
      return 'success';
    } catch (error) {
      return "unknown_error";
    }
  },
  async getInformation() {
    const response = await client().get('/contact/informations');
    if(response.data.birthday) {
      //format date field to YYYY-MM-DD
      const date = new Date(response.data.birthday);
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      response.data.birthday = year + "-" + month + "-" + day;
    }
    return response.data;
  },
  async sendValidationCode({ type, value }) {
    try {
      const body = { is_login: false, type, value };
      await client().post('/auth/otp/send', body);
      return 'success';
    } catch (error) {
      return error.response.data.error || 'unknown_error';
    }
  },
  async validateCode({ type, value, otp }) {
    try {
      const body = { type, otp, value };
      await client().post('/auth/otp/validate', body);
      return 'success';
    } catch (error) {
      return error.response.data.error || 'unknown_error';
    }
  },
  async setInformations(body) {
    try {
      await client().put('/contact/informations', body);
      return 'success';
    } catch (error) {
      return "unknown_error";
    }
  },
  async getRoadbook() {
      try {
          const response = await client().get('/contact/roadbooks');
          return response.data;
      } catch ($error) {
          return 'error';
      }
  },
  getCountries() {
    let countries =
      [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cape Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Democratic Republic of the Congo",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "North Korea",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of the Congo",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Barthelemy",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "United Kingdom",
        "United States",
        "Unknown",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe"
    ]

    let nameCountries = [];
    Object.entries(countries).forEach( (value) => {
      nameCountries.push({'value': value[1]});
    });
    return nameCountries;
  },
  async getProfiles() {
    try {
      const response = await client().get('/contact/informations/profile');
      return response.data;
    } catch (error) {
      return null;
    }
  }
};