<template lang="pug">
  .block__alertSubscriptionsMobile.containt
    previous-route-button
    .content
      .main-title {{ $t('alert_periods.subscriptions.title') }}
      .text {{ $t('alert_periods.subscriptions.text') }}
      .alert-period-date(v-if="alertPeriod" @click="goToPeriod")
        .start-date
          .from {{ $t('from') }}
          .date {{ alertPeriod.start_date ? formatDate(alertPeriod.start_date) : '' }}
        .divider
        .end-date
          .to {{ $t('to') }}
          .date {{ alertPeriod.end_date ? formatDate(alertPeriod.end_date) : '' }}
      alert-list(
        v-if="alertPeriod"
        :subscriptions="alertPeriod.subscriptions_type"
        @change="alerts => $emit('subscriptionsChange', alerts)"
      )
</template>

<script>
import alertPeriod from './alertPeriod';

export default {
  mixins: [alertPeriod],
  methods: {
    goToPeriod() {
      this.$router.push({ name: 'AlertPeriodChoice' });
    },
    formatDate(timestamp) {
      return this.$moment.unix((timestamp + 2 * 60 * 60 * 1000) / 1000).locale('fr').format('ddd DD MMM YYYY');
    },
  },
}
</script>

<style lang="scss">
  // #alert-subscriptions-mobile {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   .content {
  //     max-width: 400px;
  //   }
  //   .previous-route-button {
  //     align-self: flex-start;
  //   }
  //   .alert-period-date {
  //     @include date-period;
  //     border: 1px solid var(--secondary);
  //     padding: 6px;
  //     cursor: pointer;
  //     margin: 30px 0;
  //   }
  // }
</style>