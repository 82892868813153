<template lang="pug">
  section.block__validateLogin( :class="{'fader' : !isMobileDisplay }" )
    header-container(type="login")
    vue-scroll
      .containt
        .sub-containt.small
          .tunnel-title {{ $t('otp.title') }}
          .tunnel-text( v-if="loginType=='mobile'" ) {{ $t("otp.description_mobile") }} <br> <strong> {{ format(loginValue) }} </strong>
          .tunnel-text( v-else) {{ $t("otp.description_email") }} <br> <strong> {{ loginValue }} </strong>
          text-field.validation-code-input(
            :isDisabled="false"
            ref="validateCodeInput"
            v-model="code"
            :placeholder="$t('validation_key')"
            :isLock="true"
            :class="{ 'error' : isWrong }"
            @input="isWrong=false"
          )
          .spaceHorizontale(v-if="!isMobileDisplay")
          .actions
            button.next-btn.full(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="validateCode") {{ $t('confirm') }}
            .spaceHorizontale
            .spaceHorizontale
            .generate-new.wait(v-if="!hasWaited")
              span {{ $t('otp.wait') }} <strong> {{ cpt }} {{ $t('otp.seconds') }} </strong>
            .generate-new( v-else @click="sendNewEmail()")
              i.material-icons autorenew
              span {{ $t('otp.generate_new') }}
        footer-redirect(redirect="CreateAccount" spanText="footer_redirect.no_account_yet.span" btnText="footer_redirect.no_account_yet.btn")
</template>

<script>
import authService from '@/services/auth.js';
import HeaderContainer from '@/views/HeaderContainer.vue'
import TextField from '@/components/rc-text-field/RcTextField.vue';
import FooterRedirect from '@/components/FooterRedirect.vue';

export default {

  components: {
    TextField,
    FooterRedirect,
    HeaderContainer,
  },
  data() {
    return {
      code: null,
      tunnel: 'login',
      hasWaited: false,
      cpt: 180,
      isWrong: false
    };
  },
  created () {
    if (!!this.$route.params.previousRoute) {
      this.tunnel = this.$route.params.previousRoute;
    }
    setTimeout( this.waited , 180*1000);
    setInterval(() => {
      if(!this.hasWaited && this.cpt > 0) {
        this.cpt--;
      }
      else {
        clearInterval();
      }
    }, 1000);
    document.addEventListener('keypress', this.handleEnter);
  },
  mounted () {
    if (!this.loginType || !this.loginValue) {
      this.$router.push({ name: 'Login' });
    }
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },
  methods: {
    handleEnter(e) {
      if(e.key == "Enter") this.validateCode();
    },
    format(tel) {
      tel = tel.replaceAll(' ', '');
      if(tel[0] == 0 ) {
        tel = "+33" + tel.substring(1);
      }
      tel = "+33" + tel.replace('+33', '');
      let formattedTel = '';
      for( let i =0 ; i < tel.length ; i++) {
        if( ( i%2 == 0 && i > 2) || i == 3) {
          formattedTel += ' ';
        }
        formattedTel += tel[i];
      }
      return formattedTel;
    },
    handleResponse(response) {
      switch (response) {
        case 'success':
          this.$store.dispatch('auth/setOTPLogin', { otp: this.code });

          if (this.tunnel == 'login') {
            this.$router.push({ name: 'FinishAuthentication' });
          } else {
            this.$router.push({ name: 'AlertPeriod' });
          }
          break;
        case 'invalid_login':
          this.$toast( this.$t('toast.invalid-otp') + type, {
            toastClassName: "toast-error",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.isWrong = true;
        default:
          this.$toast( this.$t('toast.invalid-otp'), {
            toastClassName: "toast-error",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.isWrong = true;
      }
    },
    async validateCode() {
      this.loading = true;

      const response = await authService.validateCode({
        type: this.loginType,
        value: this.loginValue,
        otp: this.code
      });
      this.handleResponse(response);
      this.loading = false;
    },
    cancel() {
      this.$router.push({ name: 'Login' });
    },
    async sendNewEmail() {
      let response = await authService.sendValidationCode({ type: this.loginType, value: this.loginValue, is_login: !!this.userId });
    },
    waited() {
      this.hasWaited = true;
    }
  },
  computed: {
    loginType() {
      return this.$store.state.auth.login.type;
    },
    loginValue() {
      return this.$store.state.auth.login.value;
    }
  },
}
</script>