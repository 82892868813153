<template lang="pug">
bookmark(type="deleted")
</template>

<script>
import Bookmark from '@/components/Bookmark.vue';

export default {
  components: {
    Bookmark
  }
}
</script>
