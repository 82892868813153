<template lang="pug">
  .validate-opt.containt(:class="{'to-slide': toSlide }" )
    .tunnel-title {{ $t("otp.title") }}
    .tunnel-text( v-if="loginType=='mobile'" ) {{ $t("otp.description_mobile") }} <br> <strong> {{ format(loginValue) }} </strong>
    .tunnel-text( v-else) {{ $t("otp.description_email") }} <strong> {{ loginValue }} <br> </strong>
    text-field.validation-code-input.full(
      :isDisabled="false"
      ref="validateCodeInput"
      v-model="code"
      :placeholder="$t('validation_key')"
      :isLock="true"
      :class="{ 'error' : isWrong }"
      @input="isWrong=false"
    )
    button.validate-btn.next-btn(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="validateCode") {{ $t('confirm') }}
    .spaceHorizontale
    .spaceHorizontale
    .generate-new.wait(v-if="!hasWaited")
      span {{ $t('otp.wait') }} <strong> {{ cpt }} {{ $t('otp.seconds') }} </strong>
    .generate-new(v-else @click="sendNewOTP")
      i.material-icons autorenew
      span {{ $t('otp.generate_new') }}
</template>

<script>
import authService from '@/services/auth.js';
import TextField from '@/components/rc-text-field/RcTextField.vue';
import contactService from '@s/contacts';

export default {
  components: {
    TextField,
  },
  data() {
    return {
      code: null,
      hasWaited: false,
      cpt: 180,
      isWrong: false

    };
  },
  mounted () {
    if (!this.loginType || !this.loginValue) {
      this.$store.commit('modal/resetModal');
    }
    setTimeout( this.waited , 180*1000);
    setInterval(() => {
      if(!this.hasWaited && this.cpt > 0) {
        this.cpt--;
      }
      else {
        clearInterval();
      }
    }, 1000);
  },
  created() {
    document.addEventListener('keypress', this.handleEnter);
  },
  computed: {
    toSlide() {
      return this.$route.name=="AlertPeriods" || this.$route.name=="AlertSubscriptions";
    },
    loginType() {
      return this.$store.state.contacts.information.tmp_login ? this.$store.state.contacts.information.tmp_login.type : '';
    },
    loginValue() {
      return this.$store.state.contacts.information.tmp_login ? this.$store.state.contacts.information.tmp_login.value : '';
    }
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },
  methods: {
    handleEnter(e) {
      if(e.key == "Enter") this.validateCode();
    },
    format(tel) {
      tel = tel.replaceAll(' ', '');
      if(tel[0] == 0 ) {
        tel = "+33" + tel.substring(1);
      }
      tel = "+33" + tel.replace('+33', '');
      let formattedTel = '';
      for( let i =0 ; i < tel.length ; i++) {
        if( ( i%2 == 0 && i > 2) || i == 3) {
          formattedTel += ' ';
        }
        formattedTel += tel[i];
      }
      return formattedTel;
    },
    handleResponse(response) {
      switch (response) {
        case 'success':
          this.$store.dispatch('contacts/setInformationByKey', {
            key: this.loginType,
            value: this.loginValue
          });
          this.$store.dispatch('contacts/setInformationByKey', {
            key: "tmp_login",
            value: {
              type: this.loginType,
              value: this.loginValue,
              validated: true
            }
          });
          if(this.$route.name !== "AlertSubscriptions") this.$store.dispatch('auth/setOTPLogin', { otp: this.code });


          this.$store.commit('modal/resetModal');
        break;
        default:
          this.$toast( this.$t('toast.invalid-otp'), {
            toastClassName: "toast-error",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.isWrong = true;
      }
    },
    async validateCode() {
      this.loading = true;
      this.$store.dispatch('login/resetLogin');
      const response = await authService.validateCode({
        type: this.loginType,
        value: this.loginValue,
        otp: this.code
      });
      this.handleResponse(response);
      this.loading = false;
    },
    async sendNewOTP() {
      await contactService.sendValidationCode({ type: this.loginType, value: this.loginValue });
    },
    waited() {
      this.hasWaited = true;
    }
  },
}
</script>

<style lang="scss" scoped>



.generate-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color_red);
  cursor: pointer;
  transition: all .3s;

  i {
    transition: all .6s;
    margin-right: 10px;
  }
}

</style>