<template lang="pug">
  .alert-period-choice
    date-picker(
      v-for="(_, i) in monthSpan"
      :key="`picker-${i}`"
      :month-offset="i"
      :value="value"
      :range="value"
      :hovering="hovering"
      @hover="day => { hovering = day; }"
      @input="handleInput"
    )
    i.material-icons.show-less(v-if="monthSpan>2" @click="previousMonth") keyboard_arrow_left
    .show-more(v-if="isMobileDisplay" @click="nextMonth") {{ "voir plus de dates"}}

</template>

<script>
import DatePicker from './RcDatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          from: null,
          to: null,
        }
      },
    },
  },
  data() {
    return {
      hovering: null,
      monthSpan: 2,
      pickersObserver: null,
    }
  },
  mounted () {
    this.observeDatePickers();
  },
  methods: {
    async observerCallback(e) {
      if (e[0].isIntersecting) {
        const pickers = this.$el.querySelectorAll('.date-picker');
        const previousLastPicker = pickers[pickers.length-1];
        this.pickersObserver.unobserve(previousLastPicker);
        await this.$nextTick();
        const newpickers = this.$el.querySelectorAll('.date-picker');
        const lastPicker = newpickers[newpickers.length-1];
        this.pickersObserver.observe(lastPicker);
      }
    },
    observeDatePickers (){
      const options = { threshold: 1 };
      this.pickersObserver = new IntersectionObserver(e => this.observerCallback(e), options);
      const pickers = this.$el.querySelectorAll('.date-picker');
      const lastPicker = pickers[pickers.length-1];
      this.pickersObserver.observe(lastPicker);
    },
    handleInput(newValue) {
      let from = newValue.from;
      let to = newValue.to;
      let changes = false;

      if(from && to && from.isAfter(to)) {
        to = from.clone();
        changes = true;
      }
      else if(from && to && to.isBefore(from)) {
        from = to.clone();
        changes = true;
      }
      this.$emit('input', {from, to});
    },
    previousMonth() {
      if (this.monthSpan > 2) {
        this.monthSpan = this.monthSpan - 1;
      }
    },
    nextMonth() {
      this.monthSpan = this.monthSpan + 1;
    },
  },
}
</script>