<template lang="pug">
section.block__Roadbook.fader(:class="{ 'mobile-display': isMobileDisplay }")
  vue-scroll
    .header-title(v-if="isMobileDisplay")
      .prev-btn.material-icons(@click="goBack") arrow_back_ios
      .header {{ $t('roadbook.title')}}
    .header(v-else) {{ $t('roadbook.title')}}
    .desc(v-if="roadbooks.length") {{ $t('roadbook.description')}}

    .roadbooks(v-if="roadbooks.length")
        .roadbook( :key="roadbook.id" v-for="roadbook in roadbooks")
            .text
                .head
                    .roadbook
                    .title {{ roadbook.id }}
                .dates {{ $t('roadbook.changed')}} <strong> {{ format(roadbook.date) }} </strong>
            .next-btn(@click="openRoadbook(roadbook)") {{ $t('roadbook.see')}}
    .roadbooks(v-else)
      .no-result {{ $t('roadbook.no-result') }}

</template>

<script>
import Roadbook from "@/assets/roadbook.svg";
import contactService from '@s/contacts';

export default {
  components: {
    Roadbook,
  },
  data() {
    return {
      roadbooks : []
    }
  },
  async mounted() {
    this.checkConnection();
    this.roadbooks = await contactService.getRoadbook();
  },
  created() {
    var self = this; //Enable call to this in function
    document.addEventListener("swiped-right", function(e) {
      self.goBack();
    });
  },
  methods: {
    async checkConnection() {
      const authResponse = await this.$store.dispatch("auth/getAuth");
      if (authResponse == "error" || authResponse.connected == false) {
        this.$router.push({ name: "Login" });
      }
    },
    goBack() {
      this.$store.commit("pageTransition/setPageTransition", "back");
      const { previousRoute } = this.$route.meta;

      if (this.isMobileDisplay) {
        this.$router.push({ name: "AccountMenu" });
      } else if (!previousRoute || previousRoute == this.$route.name) {
        this.$router.push({ name: "Dashboard" });
      } else if (typeof previousRoute === "string") {
        this.$router.push({ name: previousRoute });
        return;
      }
    },
    redirect(route) {
      this.$router.push(route);
    },
    openRoadbook(roadbook) {
      window.open(`https://roadbook.travel/r/${roadbook.id}`, '_blank').focus();
    },
    format(date) {
      let formatted_date = new Date(date);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return formatted_date.toLocaleDateString(undefined, options)
    },
  },
};
</script>
