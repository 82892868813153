export default {
  data() {
    return {
      isMobileDisplay: false
    }
  },
  created() {
    this.isMobileDisplay = window.matchMedia(`screen and (max-width: 1023px)`).matches
    window.addEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.isMobileDisplay = window.matchMedia(`screen and (max-width: 1023px)`).matches;
    },
  },
};