<template lang="pug">
.header-container
    previous-route-button
    component.top-logo(:is="Welcome")
    .close-app(v-if="isMobileDisplay" @click="closeApp")
      .material-icons close
    .close-app(v-else @click="closeApp") Fermer
      .material-icons close
</template>

<script>

export default {
  data() {
    return {};
  },
  props: {
    type: String
  },
  computed: {
    siteKey() {
      if(this.$store.state.settings.context == "app") {
        return 'les-deux-alpes'; //dev
      }
      return this.$store.state.settings.siteKey ;
    },
    Welcome() {
      return () => import(`@/assets/${this.siteKey}.svg`);
    },
  },
  methods: {
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
      document.querySelector('destination-connect-front').classList.remove('active');
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: this.type ? this.type : null
      });
    }
  },
}
</script>