<template lang="pug">
  .validate-opt.containt( ref="modalContainer" )
    .tunnel-title {{ $t("otp.title-info") }}
    .tunnel-text {{ $t("toast.missing-information") }} <strong> {{ missingInfoType == "email" ? 'e-mail' : missingInfoType }} </strong>
    .missing-info-field
        input.missing-info-input.mail-field(
            v-if=" missingInfoType == 'email' "
            :placeholder="$t('personnal_information.email_address')"
            :isDisabled="false"
            ref="missingInfo"
            @input="isGoodFormat(missingInfoType)"
            )
        phone-field(
            v-else
            v-model="mobileInput"
            default-country-code="FR"
            ref="missingInfo"
            :translations="translations"
            :placeholder="$t('personnal_information.mobile_number')"
             @input="isGoodFormat(missingInfoType)"
        )
    button.validate-btn.next-btn(ref="validate" v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="validateInput") {{ $t('confirm') }}
</template>

<script>
import PhoneField from 'vue-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js';
import TextField from '@/components/rc-text-field/RcTextField.vue';
import contactService from '@s/contacts';

export default {
  components: {
    TextField,
    PhoneField
  },
  data() {
    return {
      code: null,
      isWrong: false,
      mobileInput: '',
       translations: {
        countrySelectorLabel: this.$t('phone_input.country_selector_label'),
        countrySelectorError: this.$t('phone_input.country_selector_error'),
        phoneNumberLabel: this.$t('phone_input.phone_number_label'),
        example: this.$t('phone_input.example'),
      },
    };
  },
  mounted() {
    this.isGoodFormat(this.missingInfoType);
  },
  computed: {
    missingInfoType() {
      return this.$store.state.contacts.information.toToogle ? this.$store.state.contacts.information.toToogle.type : '';
    },
  },
  methods: {
    isGoodFormat(type) {
      if(type == 'email') {
        const validationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validationRegex.test(this.$refs.missingInfo.value)) {
          this.$refs.validate.style['pointer-events'] = 'none';
          this.$refs.validate.style['opacity'] = '0.5';

        }
        else {
          this.$refs.validate.style.removeProperty('pointer-events');
          this.$refs.validate.style['opacity'] = '1';
          this.$refs.missingInfo.style['border'] = "solid 1px green";
        }
      }
      else {
        if( this.mobileInput && this.replaceAll(this.mobileInput, ' ', '').length >= 9 && this.replaceAll(this.mobileInput,' ', '').length < 11) {
          this.$refs.validate.style.removeProperty('pointer-events');
          this.$refs.validate.style['opacity'] = '1';
        }
        else {
          this.$refs.validate.style['pointer-events'] = 'none';
          this.$refs.validate.style['opacity'] = '0.5';
        }
      }
    },
    async validateInput() {
      switch(this.missingInfoType) {
        case 'email':
          const validationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!validationRegex.test(this.$refs.missingInfo.value)) {
            return;
          }

          let value = this.$refs.missingInfo.value;
          const emailCode = await contactService.sendValidationCode({ type: "email", value });
          if( emailCode == 'UserAlreadyExists') {
            //no changes
            this.$toast( this.$t('personnal_information.input_email.error.UserAlreadyExists'), {
              toastClassName: "toast-error",
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            return;
          }
          else if (emailCode !== 'success') {
            //email send error -> quota opt exceed -> reset email & alert user
            this.$toast( this.$t('toast.otp-quota'), {
              toastClassName: "toast-error",
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            return;
          }

          this.$store.dispatch('contacts/setInformationByKey', {
            key: "tmp_login",
            value: {
              type: 'email',
              value: value,
              validated: false
            }
          });

          this.openModal();
        break;
        case 'mobile':
          const { countryCode } = this.$refs.missingInfo.results;

          let valueMobile = parsePhoneNumber(this.mobileInput, countryCode).format('E.164');
          const mobileCode = await contactService.sendValidationCode({ type: "mobile", value: valueMobile });

          if( mobileCode == 'UserAlreadyExists') {
            this.$toast( this.$t('personnal_information.input_mobile.error.UserAlreadyExists'), {
              toastClassName: "toast-error",
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            //no changes
            return;
          }
          else if (mobileCode !== 'success') {
            //email send error -> quota opt exceed -> reset email & alert user
            this.$toast( this.$t('toast.otp-quota'), {
              toastClassName: "toast-error",
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            return;
          }

          this.$store.dispatch('contacts/setInformationByKey', {
            key: "tmp_login",
            value: {
              type: "mobile",
              value: valueMobile,
              validated: false
            }
          });

          this.openModal();
        break;
      }
    },
    replaceAll(str, find, replace) {
      //FIX OLD DEVICE COMPATIBILITY OF replaceAll()
      return str.replace(new RegExp(find, 'g'), replace);
    },
    openModal() {
      this.$refs.modalContainer.classList.add('to-fade');
      setTimeout(
        () => {
          this.$store.commit('modal/setType', 'ValidateOTP');
          this.$store.commit('modal/setOpened', true);
        }
        ,150
      );
    },
  },
}
</script>