<template lang="pug">
    div.skeleton-container
        component(:is="type" v-for="i in range" :key="i" :hasDescription="hasDesc")
</template>

<script>
import SkeletonBookmarks from './SkeletonBookmarks.vue';
import SkeletonNotifications from './SkeletonNotifications.vue';
import SkeletonFinishAuthentification from './SkeletonFinishAuthentification.vue';

export default {
    props: {
        type:  {
            type: String,
            required: true
        },
        count: {
            type: Number,
            default: 6
        },
        hasDesc: {
            type: Boolean,
            default: true
        }
    },
    components: {
        Bookmarks: SkeletonBookmarks,
        Notifications: SkeletonNotifications,
        Authentification: SkeletonFinishAuthentification
    },
    computed: {
        range() {
            const arr = [];
            for(let i = 0; i < this.count; i++) {
                arr.push(i);
            }
            return arr;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/skeleton.scss';
</style>
