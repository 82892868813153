<template lang="pug">
    div.skeleton-column
        div.skeleton.skeleton-line.title(v-if="!isSubscriptions")
        div.skeleton.skeleton-line.desc(v-if="!isSubscriptions")
        div.skeleton.skeleton-line.date-picker(v-if="!isSubscriptions")
        div.skeleton.skeleton-line.smaller
        div.skeleton-row.notifications
            div.notification(v-for="i in [0, 8]")
                div.skeleton.skeleton-fill.notifications
                div.skeleton.skeleton-fill.notifications
                div.skeleton.skeleton-fill.notifications
                    div.skeleton.skeleton-line
            div.notification
                div.skeleton.skeleton-fill.notifications
                    div.skeleton.skeleton-line

</template>

<script>
import SkeletonMixin from '@/mixins/Skeleton.mixin';

export default {
    mixins: [SkeletonMixin],
    computed: {
        isSubscriptions() {
            return this.$route.name == 'Subscriptions';
        }
    }
}

</script>

<style lang="scss" scoped>
@import '../../styles/skeleton.scss';
    .skeleton-line.title {
        margin-top: 0;
        width: 60%;
    }
    .skeleton-line.desc {
        width: 50%;
        margin: 2rem 0;
        @include media('<desktop') {
            margin-top: 4rem !important;
        }
    }
    .skeleton-line.date-picker {
        width: 80%;
        height: 5rem;
        margin: 2rem 0;
    }
    .skeleton-row.notifications {
        margin: 1rem 0;
        @media (min-width: 1600px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .skeleton-fill.notifications {
        margin: 2rem 0;
    }
    .notification {
        width: 80%;
        height: 8rem;

        @media (min-width: 1600px) {
            width: 37%;
            height: 10rem;
            margin: 2rem 1rem 0;
        }
    }

    @include media('<desktop') {
        .skeleton-line.title, .skeleton-line.date-picker, .skeleton-fill.notifications , .notification {
            margin: 40px auto;
        }
        .skeleton-line.desc, .smaller {
            margin: 0 40px;
        }
        .skeleton-line.date-picker {
            height: 9rem !important;
        }

    }
</style>
