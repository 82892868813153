<template lang="pug">
  section.block__consumedToken#consumed-token
    .close-app(@click="closeApp") Fermer
      .material-icons close
    .containt
      .tunnel-title {{ $t('consumed_token.title') }}
      .spaceHorizontale
      .spaceHorizontale
      .spaceHorizontale
      .text {{ $t('consumed_token.text') }}
      .spaceHorizontale
      .spaceHorizontale
      .spaceHorizontale
      .next-btn(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="goToAccount()") {{ $t('login_me_in') }}
</template>

<script>
export default {
  methods: {
    goToAccount() {
      this.$router.push({ name: 'Login' });
    },
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
      document.querySelector('destination-connect-front').classList.remove('active');
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: 'consummed_token'
      });
    }
  },
}
</script>

<style lang="scss">
  #consumed-token {
    margin: auto;
    text-align: center;
  }
</style>