<template lang="pug">
.block__alertPeriods.subscription(
    :class="{ 'mobile-display': isMobileDisplay }"
    :key="reload"
)
    vue-scroll
      .fader
        .subscriptions
            .header-title(v-if="isMobileDisplay")
                .prev-btn.material-icons(@click="goBack") arrow_back_ios
                .title.header {{ $t('subscriptions.title') }}
            .container
                .title.header(v-if="!isMobileDisplay") {{ $t('subscriptions.title') }}
                .desc(v-if="!loading") {{ $t('subscriptions.desc') }}
                skeleton(
                    v-if="loading"
                    type="notifications"
                )
            .notifications-selection
                .subscriptions
                    .subscription(
                        v-for="(subscription, index) in completeSubscriptionsList" :key="index"
                        :class="{ 'large': completeSubscriptionsList.length == 1 }"
                    )
                        .text
                            .text-container
                                .alert-title {{ $t(`selector.${subscription.value}`) }}
                        .switchers
                            #email-switch
                                span(:class="{ selected: (isEmailSet && isSubscribe(subscription)) || toToogle(subscription) }") {{ $t('by_mail') }}
                                .switch(:active="(isEmailSet && isSubscribe(subscription)) || toToogle(subscription)"  @click="toggleSubscription(subscription)")
</template>

<script>
import Skeleton from '@/components/skeletons/Skeleton.vue';
import contactService from '@s/contacts';
import subscriptionsService from '@s/subscriptions';

export default {
components: {
    Skeleton,
},
created() {
    var self = this;//Enable call to this in function
    document.addEventListener('swiped-right', function(e) {
      self.goBack();
    });
},
mounted () {
    this.checkConnection();
    this.getSubscriptions();
},
data() {
    return {
        loading: false,
        reload: 0,
        completeSubscriptionsList: [],
    };
},
computed: {
    isEmailSet() {
      return this.$store.state.contacts.information.email;
    },
    modalState() {
      return this.$store.state.modal.opened;
    },
    subscriptionsList() {
      return this.$store.state.contacts.information.subscriptions;
    }
  },
watch: {
  modalState(newVal, oldVal) {
      this.reload = Math.random(); //forces re-render
  },
},
methods: {
    async checkConnection() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      if ( authResponse == "error" || authResponse.connected == false) {
          this.$router.push({ name: 'Login' });
      }
    },
    isSubscribe(subscription) {
      return this.subscriptionsList.find(sub => subscription.value === sub) ? true : false;
    },
    async getSubscriptions(loaded = false) {
        if (!loaded) {
        this.loading = true;
        }
        this.$store.dispatch('contacts/getInformation', false);//update user subscriptions
        this.completeSubscriptionsList = await subscriptionsService.getSubscriptions();
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    goBack() {
        this.$store.commit("pageTransition/setPageTransition", "back");
        const { previousRoute } = this.$route.meta;

        if (this.isMobileDisplay) {
          this.$router.push({ name: 'AccountMenu' });
        } else if (!previousRoute || previousRoute == this.$route.name) {
          this.$router.push({ name: 'Dashboard' });
        } else if (typeof previousRoute === 'string' ) {
          this.$router.push({ name: previousRoute });
        return;
        }
    },
    async toggleSubscription(subscription) {
      const type = 'email';
      const isSet = this.subscriptionsList.find(sub => sub === subscription.value) || false;

      if (isSet) {
        if(document.querySelectorAll('.toast-desubscription').length < 2) {
          this.$toast( this.$t('toast.desubscription') + type + '.', {
            toastClassName: "toast-desubscription",
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });

          const newSubscriptionsList = this.subscriptionsList.filter(sub => sub != subscription.value);

          this.$store.dispatch('contacts/setInformationByKey', {
            key: "subscriptions",
            value: newSubscriptionsList
          });
        }} else {
          if( !this.isEmailSet ) {
            this.$toast( this.$t('toast.missing-information') + type, {
              toastClassName: "toast-missing-info",
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });

            this.$store.dispatch('contacts/setInformationByKey', {
              key: "toToogle",
              value: {
                type: 'email',
                id: subscription.label
              }
            });
            this.openModal();

          } else {
            const newSubscriptionsList = [...this.subscriptionsList,subscription.value]
            this.$store.dispatch('contacts/setInformationByKey', {
              key: "subscriptions",
              value: newSubscriptionsList,
            });
            if(document.querySelectorAll('.toast-subscription').length < 2) {
              this.$toast( this.$t('toast.subscription') + type, {
                toastClassName: "toast-subscription",
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
            }
          }
      }
      let body = {};
      body['subscriptions'] = this.subscriptionsList;
      await contactService.setInformations(body);
    },
    async saveSubscriptions(subscriptionsLists) {
      let body = {};
      body['subscriptions'] = subscriptionsLists;
      await contactService.setInformations(body);
      await this.getSubscriptions('loaded');
    },
    toToogle(subscription) {
      if(this.$store.state.contacts.information.tmp_login && this.$store.state.contacts.information.toToogle && this.$store.state.contacts.information.tmp_login.type == 'email' && this.$store.state.contacts.information.toToogle.id == subscription.label && this.$store.state.contacts.information.tmp_login.validated) {
        this.$store.state.contacts.information.toToogle = null;
        const newSubscriptionsList = [...this.subscriptionsList,subscription.value]
        this.$store.dispatch('contacts/setInformationByKey', {
          key: "subscriptions",
          value: newSubscriptionsList,
        });
        this.saveSubscriptions(this.subscriptionsList);
        return true;
      }
      return false;
    },
    openModal() {
      this.$store.commit('modal/setType', 'MissingInformation');
      this.$store.commit('modal/setOpened', true);
    },
}
}
</script>

<style>

</style>