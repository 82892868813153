<template lang="pug">
.collapse-dropdown(@click="e => toogle(e)" v-droppable="{exclude: ['modify', 'save'], handler: 'disappear' , active: visible}")
    .dropbtn(:class="{ 'placeholder' : value == placeholder}")
        img.flag(v-if="(placeholder == 'Pays' || placeholder == 'Country') && flagSrc" :src="flagSrc")
        .value.first-row {{  translate && !checkable ? $t(`selector.${findValue(value)}`) : value }}
    .dropdown-content(ref="dropdown" v-show="visible")
        slot(v-for="(item, i) in options")
            .option(:key="i" :value="checkable ? item.label : item.value" @click="(event) => select(event, item)")
                input.checkbox(v-if="checkable" type="checkbox" :checked="item.status")
                img.flag(v-if="placeholder == 'Pays' || placeholder == 'Country'" :src="getFlagUrl(item.value)")
                .value {{ translate ? $t(`selector.${item.value}`) : (item.label || item.value) }}
    i.material-icons.dropdown(:class="{'open': visible}") keyboard_arrow_right
</template>

<script>
import Vue from 'vue';

let handleOutsideClick;

Vue.directive('droppable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
        e.stopPropagation()
        const handler = binding.value
        if (!el.contains(e.target)) {
            vnode.context[handler.handler]()
        }
    }
    document.addEventListener('click', handleOutsideClick)
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
  }
});
export default {
    data() {
        return {
            value: this.placeholder,
            visible: false,
            flagSrc: null

        }
    },
    mounted() {
        if(this.placeholder == 'Pays' || this.placeholder == 'Country') {
            document.addEventListener('keypress', (e) => {
                let key = e.key;
                let found = false;
                this.options.forEach( (option, index) => {
                    if(!found && option.value.substr(0,1).toLowerCase() == key) {
                        this.$refs.dropdown.scrollTop = 34 * index;
                        found = true;
                    }
                });
            });
            if(this.$store.state.contacts.information.country) {
                this.value = this.$store.state.contacts.information.country;
                this.flagSrc = this.getFlagUrl(this.value);
            }
        }
        else if((this.placeholder == 'Profil' || this.placeholder == 'Profile') && this.$store.state.contacts.information.profile) {
            let item = this.options.filter( (e) => { return e.value == this.$store.state.contacts.information.profile});
            this.value = item[0]?.label;

        }
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        translate: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        select(event, item) {
            if (!this.checkable) {
                this.value = item.label || item.value;
                this.flagSrc = this.placeholder == 'Pays' || this.placeholder == 'Country' ? this.getFlagUrl(item.value) : null;
                this.visible = false;
                this.$emit('input', item.value);
                event.stopPropagation()
            } else {
                this.$emit('input', item);
            }
        },
        toogle(e) {
            if (!this.checkable) {
                this.visible = !this.visible;
            } else if (e.target.classList.contains('first-row') || e.target.classList.contains('dropbtn') || e.target.classList.contains('dropdown')) {
                this.visible = !this.visible;
            }
        },
        disappear() {
            this.visible = false;
        },
        getFlagUrl(name) {
            name= name.replaceAll(' ', '-');
            return require(`@/assets/flags/${name}.png`);
        },
        findValue(value) {
            return this.options.filter( (e) => { return e.label == value})[0].value;
        }
    }
}
</script>

<style lang="scss" scoped>

    .dropdown {
        position: absolute;
        transform: rotate(90deg);
        margin-top: -42px;
        margin-left: 370px;
        cursor: pointer;
        transition: all .3s ease;
        color: $color-black;
        @media screen and (max-width: 1023px) {
            margin-left: 0;
            right: 0;
        }
        &.open {
            transform: rotate(90deg);
        }
    }
    .collapse-dropdown {
        position: relative;
        height: 49px;
        width: 435px;
        border: none;
        background-color: var(--color_white);
        color: var(--primary-color);
        text-indent: 1rem;
        outline: none;
        font-family: 'Montserrat';
        cursor: pointer;
        margin-bottom: 18px;
        padding-right: 2px;

        @media screen and (max-width: 1023px) {
            width: 100%;
        }
        .dropbtn {
            line-height: 49px;
            &.placeholder {
                color: var(--color_grey_blue);
            }
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .flag {
                width: 26px;
                margin-left: 1rem;
            }
            .first-row {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 390px;
            }
        }
        .dropdown-content {
            width: 435px;
            position: absolute;
            box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
            z-index: 10;
            max-height: 140px;
            overflow: auto;
            @media screen and (max-width: 1023px) {
                width: 100%;
            }

            .option {
                font-family: 'Montserrat';
                font-size: 1rem;
                border: none;
                background-color: var(--color_white);
                color: var(--color_black);
                text-indent: 1rem;
                line-height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: all .2s ease;
                padding: 0.25rem 0;
                &:hover {
                    transition: all .2s ease;
                    background-color: var(--primary-color);
                    color: white;
                }
                .flag {
                    width: 26px;
                    margin-left: 1rem;
                }

                .checkbox {
                    pointer-events: none;
                    -webkit-appearance: auto;
                    appearance: none;
                    background-color: white;
                    margin: 0;
                    margin-left: 15px;
                    cursor: pointer;

                    font: inherit;
                    color: var(--primary-color);
                    width: 1.15em;
                    height: 1.15em;
                    border: 0.15em solid var(--primary-color);
                    border-radius: 0.15em;
                    transform: translateY(-0.075em);

                    display: grid;
                    place-content: center;
                }

                .checkbox::before {
                    content: "";
                    width: 0.65em;
                    height: 0.65em;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                    transform: scale(0);
                    transform-origin: bottom left;
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em var(--form-control-color);
                    /* Windows High Contrast Mode */
                    background-color: var(--primary-color);
                }

                .checkbox:checked::before {
                transform: scale(1);
                }
            }
        }
    }
</style>