import axios from 'axios';
import store from '../../store';

export default (headers = {
  'Content-Type': 'application/json',
}) =>
  axios.create({
    withCredentials: true,
    timeout: 30000,
    headers,
    responseType: 'json',
    baseURL: store.state.settings.endpoint ? store.state.settings.endpoint : 'https://www.les-deux-alpes.wp.rc-preprod.com/wp-json/woody', // If env == dev -> url from les-deux-alpes -> else get url from store
  });
