import client from './api/apiConnector.js';

export default {
  async getSubscriptions() {
    try {
      const response = await client().get('/contact/informations/subscriptions');
      return response.data;
    } catch (error) {
      return [];
    }
  }
};