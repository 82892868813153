export default {
    setOpened(state, newValue) {
        state.opened = newValue;
    },
    setType(state, newValue) {
        state.type = newValue;
    },
    resetModal(state) {
        state.opened = false;
        state.type = null;
    }
};