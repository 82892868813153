<template lang="pug">
  .personalSessions
    .main-title(v-if="!isMobileDisplay") {{ $t('personnal_information.sessions.main_title') }}
    .tunnel-subtitle(v-else) {{ $t('personnal_information.sessions.main_title')}}
    .desc {{ $t('personnal_information.sessions.desc') }}
    .sessionsItems
      .session-item(
        v-for="item in sessionsItems"
        :key="item.session_uuid"
      )
        .device
          pc.pc(v-if="isDesktop(item)")
          phone.phone(v-else)
          span {{ item.device }} - {{ item.browser }}
        .location {{ item.location }}
        .location {{ item.create_date }}
        .action.disconnect(v-if="!item.current_session" @click="disconnectSession(item.session_uuid)") {{ $t('personnal_information.sessions.disconnect') }}
        .action.active(v-else) {{ $t('personnal_information.sessions.active') }}
</template>

<script>
import sessionsService from '@/services/sessions.js';
import Phone from '@/assets/phone.svg';
import Pc from '@/assets/pc.svg';


export default {
  components: {
    Phone,
    Pc
  },
    data() {
        return {
            loading: false,
            sessionsItems: [],
        }
    },
    mounted() {
      this.getSessions();
    },
    methods: {
        async getSessions() {
          this.loading = true;
          const items = await sessionsService.getSessions();

          this.sessionsItems = items.filter((value) => {
            if (value.consume == false && value.expire_date == null) {
              value.create_date = new Date(value.create_date).toLocaleDateString('fr-FR', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"});
              return value;
            }
          });

          let currentSession = this.sessionsItems.find((e) => { return e.current_session });
          let currentSessionIndex = this.sessionsItems.map(function(e) { return e.current_session; }).indexOf(true);
          this.sessionsItems.splice(currentSessionIndex, 1);
          this.sessionsItems.unshift(currentSession);
          this.loading = false;
        },
        async disconnectSession(uuid) {
          await sessionsService.disconnectSession(uuid);

          for (const key in this.sessionsItems) {
            if (this.sessionsItems[key].session_uuid == uuid) {
              if (this.sessionsItems[key].current_session === true) {
                // Router to Auth
                // remove Auth
                if (this.$store.state.settings.context != "app") {
                  const disconnectEvent = new Event('destiConnectEnd');
                  window.dispatchEvent(disconnectEvent);
                }

                this.$store.dispatch('auth/resetAuth');
                this.$store.dispatch('contacts/resetInformation');
                this.$store.dispatch('login/resetLogin');
                this.goToAuthentification();
              } else {
                this.sessionsItems.splice(key, 1);
              }
            }
          }
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'informations_disconnect_device',
          });
        },
        goToAuthentification() {
          this.$router.push({name: 'Login'});
        },
        isDesktop(item) {
          return item.device && item.device.indexOf('desktop') > -1 ? true :false ;
        }
    },
    computed: {
        /**
         * if there is only one session item, it obviously means that it's the current session,
         * so there is no need to disconnect all other sessions
         */
        hasOtherSessions() {
          return this.sessionsItems.length > 1 ? true : false;
        },
    }
}
</script>