import AlertList from '@c/alerts/AlertList/AlertList.vue';
import contactService from '@s/contacts';

export default {
  components: {
    AlertList,
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      alertPeriod: this.alert || null,
      alertPeriodDates: { from: null, to: null },
    };
  },
  mounted () {
    if (!this.periodId) {
      this.getSubscriptions()
      return;
    }
    if (!this.alertPeriod) {
      this.getAlertPeriod();
    }
  },
  methods: {
    async getSubscriptions() {
      this.loading = true;
      const subscriptions_type = await contactService.getSubscriptions();
      this.alertPeriod = { id: null, start_date: null, end_date: null, subscriptions_type };
      this.loading = false;
    },
    async handleSubscriptionChanges(newSubscriptions) {
      this.$set(this.alertPeriod, 'subscriptions_type', newSubscriptions);
      if (this.alertPeriod.start_date && this.alertPeriod.end_date) {
        await contactService.setAlertPeriod(this.periodId, this.alertPeriod);
      }
    },
    async saveAlertPeriod(type = "mobile") {
      this.loading = true;
      this.$set(this.alertPeriod, 'start_date', this.alertPeriodDates.from);
      this.$set(this.alertPeriod, 'end_date', this.alertPeriodDates.to);
      const periodId = await contactService.setAlertPeriod(this.periodId, this.alertPeriod);
      if (type == "desktop") {
        this.$router.push({name: 'AlertPeriods'});
      } else {
        this.$router.push({ name: 'AlertPeriodSubscriptions', params: {...(this.periodId ? {} : { periodId })} });
      }
      this.loading = false;
    },
    handlePeriodInput({ from, to }) {
      const start = from ? from.unix() * 1000 : null;
      const end = to ? to.unix() * 1000 : null;
      this.$set(this.alertPeriodDates, 'from', start);
      this.$set(this.alertPeriodDates, 'to', end);
    },
    async getAlertPeriod() {
      this.loading = true;
      this.alertPeriod = await contactService.getAlertPeriod(this.periodId);
      this.alertPeriodDates = {
        from: this.alertPeriod.start_date,
        to: this.alertPeriod.end_date,
      };
      this.loading = false;
    },
  },
  computed: {
    periodId() {
      return this.alertPeriod ? this.alertPeriod.id : parseInt(this.$route.params.periodId, 10) || null;
    },
    formattedValue() {
      const { from, to } = this.alertPeriodDates;
      return {
        from: from ? this.$moment.unix(from / 1000) : null,
        to: to ? this.$moment.unix(to / 1000) : null,
      }
    }
  },
}