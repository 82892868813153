<template lang="pug">
    div.skeleton-column.content
        div.skeleton-fill.sidebar
            .items
                div.skeleton.skeleton-row.skeleton-line.title
                div.skeleton.skeleton-row.skeleton-line.title
                div.skeleton.skeleton-row.skeleton-line.menu
                div.skeleton.skeleton-row.skeleton-line.menu
                div.skeleton.skeleton-row.skeleton-line.menu
                div.skeleton.skeleton-row.skeleton-line.menu
                div.skeleton.skeleton-row.skeleton-line.menu
                div.skeleton.skeleton-row.skeleton-line.deco

        div.dashboard
            div.head
                div.slider
                    div.skeleton.skeleton-row.img
                    div.skeleton.skeleton-row.skeleton-line.title
                    div.skeleton.skeleton-row.skeleton-line.slide
                    div.skeleton.skeleton-row.skeleton-line.slide(v-if="isMobileDisplay")
                div.skeleton.skeleton-row.illu

            div.skeleton.skeleton-row.card-row.large(v-if="isMobileDisplay")
                div.skeleton.skeleton-line.title
                div.skeleton.skeleton-line.desc
                div.skeleton.skeleton-line.desc
                div.skeleton.skeleton-line.link
            div.cards(v-for="i in [0, 1]")
                div.skeleton.skeleton-row.card(v-for="i in [0, 1]")
                    div.card-fill
                        div.skeleton.skeleton-line.title
                        div.skeleton.skeleton-line.desc
                        div.skeleton.skeleton-line.desc
                        div.skeleton.skeleton-line.link
</template>

<script>
import SkeletonMixin from '@/mixins/Skeleton.mixin';

export default {
    mixins: [SkeletonMixin],
}

</script>

<style lang="scss" scoped>
@import '../../styles/skeleton.scss';

.content {
    @include media('>=desktop') {
        height: 95vh;
        width: 90vw;
    }
    display: flex;
    overflow: hidden;
    .sidebar {
        width: 32%;
        background: $color-blue;
        border-top-left-radius: 20px;

        .items {
            width: 90%;
            margin: 60px auto 0 60px;

            .title {
                height: 50px;
                margin-bottom: 60px;
            }

            .menu {
                height: 40px;
                width: 50%;
                margin: 60px 0;
            }

            .deco {
                margin-top: 120px;
                width: 60%;
                display: flex;
                justify-content: flex-end;
            }
        }

    }

    .dashboard {
        margin-top: 6rem;
        width: 68%;

        @include media('<desktop') {
            width: 100%;
            margin-top: 4rem;
        }

        .head {
            width: 85%;
            margin: 0 auto 4rem;
            display: flex;
            justify-content: space-between;


            @include media('<desktop') {
                margin-bottom: 0;
            }

            .illu {
                height: 300px;
                width: 40%;
            }

            .slider {
                width: 40%;
                height: 300px;

                .img {
                    height: 100px;
                    width: 90%;
                    margin: auto;
                }

                .title {
                    height: 32px;
                    margin: 40px auto 70px;

                    @include media('<desktop') {
                        height: 60px;
                        margin: 35px auto;
                    }
                }

                .slide {
                    height: 48px;
                    width: 100%;

                    @include media('<desktop') {
                        height: 20px;
                        width: 70%;
                        margin: 35px auto;
                    }
                }
            }


            @media (max-width: 1600px) {
                .illu {
                    display: none;
                }

                .slider {
                    width: 80%;
                    margin: auto;

                    .img {
                        width: 70%;
                    }
                }
            }

        }

        .large {
            height: 160px;
            width: 85%;
            margin: auto;
            margin-top: 32px;
        }

        .cards {
            margin-top: 4rem;
            width: 85%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            .card {
                height: 200px;
                width: 48%;
                margin: 1rem 0;

                @include media('<desktop') {
                    height: 140px;
                }

                @media (max-width: 1600px) and (min-width: 1024px) {
                    &:nth-child(2n){
                        display: none;
                    }
                    width: 100%;
                }
            }
        }
    }

}


@include media('<desktop') {
    .sidebar {
        display: none;
    }

    .dashboard {
        width: 100%;
        margin: auto;
    }
}

</style>
