<template lang="pug">
  //- #alert-period-mobile.container
  .block__alertPeriodMobile
    router-view(
      v-if="alertPeriodDates"
      :alert="alertPeriod"
      :value="formattedValue"
      @periodChange="handlePeriodInput"
      @subscriptionsChange="handleSubscriptionChanges"
      @saveAlertPeriod="saveAlertPeriod"
    )
</template>

<script>
import alertPeriod from './alertPeriod';

export default {
  mixins: [alertPeriod],

  mounted () {
    if (this.$route.name === 'AlertSelectedPeriod') {
      this.$router.push({ name: 'AlertPeriodSubscriptions' });
    }
  },
}
</script>