import client from './api/apiConnector';

export default {
  async sendValidationCode({ type, value, is_login = false }) {
    try {
      const body = { is_login, type, value };
      const response = await client().post('/auth/otp/send', body);
      return response.data ? response.data.error || 'success' : 'success';
    } catch (error) {
      if (error.response != null && error.response != 'undefined') {
        if (error.response.data.message.indexOf('not been enabled for the region') > -1) {
          return 'unable_for_region';
        } else if (error.response.data.message.indexOf('The From phone number') > -1) {
          return 'unable_to_send';
        } else if (error.response.data.error == "UserAlreadyExists") {
          return 'already_in_use';
        } else if (error.response.data.error == "UserNotFound") {
          return 'user_not_found';
        } else if (error.response.data.error == "OTPQuotaExceededException") {
          return 'otp_quota_exceeded';
        }
      }

      console.warn(error);
      return 'unknown_error'
    }
  },
  async validateCode({ type, otp, value }) {
    try {
      const body = { type, otp, value };
      const response = await client().post('/auth/otp/validate', body);
      return response.data ? response.data.error || 'success' : 'success';
    } catch (error) {
      console.warn(error);
      return 'unknown_error'
    }
  },
  async createSession(loginParams) {
    if (loginParams.otp && loginParams.otp != 'undefined' && loginParams.otp != null) {
      delete loginParams.token;
    }

    try {
      const response = await client().post('/auth/sessions', loginParams);
      return response.data ? response.data.error || 'success' : 'success';
    } catch (error) {
      console.warn(error);
      return 'unknown_error';
    }
  },
  async getAuth(token) {
    try {
      const authResponse = await client().post('/auth/check', { token });
      return authResponse.data;
    } catch(e) {
      return 'error';
    }
  },
  async deleteSession() {
    await client().delete('/auth/sessions');
  }
};
