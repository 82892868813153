import client from './api/apiConnector';
import woodyClient from './api/woodyApiConnector';

export default {
    async getBookmarks() {
        try {
            const response = await client().get('/contact/bookmarks');
            return response.data;
        } catch ($error) {
            return 'error';
        }
    },
    async setBookmarks(data) {
        try {
            await client().post('/contact/bookmarks', data);
            return true;
        } catch(error) {
            return false;
        }
    },
    async deleteBookmark(id) {
        try {
            await client().delete('/contact/bookmarks/' + id);
            return true;
        } catch(error) {
            console.log(error);
            return false;
        }
    },
    async getBookmarksData(ids_str, lang) {
        try {
            const response = await woodyClient().get('/bookmarks?ids=' + ids_str + "&lang=" + lang);

            response.data.forEach(element => {
                element.displayed = true;
                const splittedTitle = element.title.split('-');
                if(splittedTitle.length > 1) {
                    let title = splittedTitle[splittedTitle.length - 1];
                    element.title = title.substring(0, title.lastIndexOf('('));// Fix - filter title to remove id, type
                }
            });

            return response.data;
        } catch($error) {
            return false;
        }
    }
};