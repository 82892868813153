import Vue from 'vue';

const state = {
  delay_transition: 500,
  desktop_back_show: true,
  context: 'app',
  site_lang: '',
  bookmarks: null,
  endpoint: '',
  season: '',
  siteKey: '',
  hasRoadbook: null,
  env: null
};

const mutations = {
  setBackgroundDesktop(currentState, newState) {
    currentState.desktop_back_show = newState;
  },
  setSiteLang(currentState, newState) {
    currentState.site_lang = newState;
  },
  setBookmarks(currentState, newState) {
    currentState.bookmarks = newState;
  },
  setContext(currentState, newState) {
    currentState.context = newState;
  },
  setEndpoint(currentState, newState) {
    currentState.endpoint = newState;
  },
  setSeason(currentState, newState) {
    currentState.season = newState;
  },
  setSiteKey(currentState, newState) {
    currentState.siteKey = newState;
  },
  setRoadbook(currentState, newState) {
    currentState.hasRoadbook = newState;
  },
  setEnv(currentState, newState) {
    currentState.env = newState;
  }

};

export { mutations, state };