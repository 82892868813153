// bookmarks is an Object containing the content associated with the bookmarks id (post_id from woody)
// bookmarksList store the status of the bookmarks (favorite, history or deleted)
const state = {
  bookmarks: {},
  bookmarksList: {
    favorite: [],
    history: [],
    deleted: []
  }
};

const getters = {

};

const actions = {
  setBookmarkContent({ commit }, { content, id }) {
    commit('setBookmarkContent', { content, id });
  },
  setBookmarks({commit}, { bookmarksList }) {
    commit('setBookmarks', { bookmarksList });
  },
  moveBookmark({commit}, { id, from, to }) {
    commit('moveBookmark', { id, from, to });
  }
};

const mutations = {
  setBookmarkContent(state, { content, id }) {
    state.bookmarks[id] = content;
  },
  setBookmarks(state, { bookmarksList }) {
    state.bookmarksList = bookmarksList;
  },
  moveBookmark(state, { id, from, to }) {
    state.bookmarks[from] = state.bookmarks[from].filter(bookmark => bookmark.id !== id);
    state.bookmarks[to].push(id);
  }
};

export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};