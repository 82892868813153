export default {
    methods: {
        applyAnimation() {
            let key = this.$vnode.key;

            if(key == undefined) {
                key = 0;
            }

            if(typeof key == 'string') {
                key = parseInt(key);
            }
            this.$el.classList.add(`animation-${key % 4}`);
        }
    },
    mounted() {
        this.applyAnimation();
    }
}