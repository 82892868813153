<template lang="pug">
  .block__MobilePeriodChoice(
    ref="container"
    :class="{ 'mobile-display': true }"
  )
    .header-title
      .close-app(@click="close()")
        .material-icons close
      .title.header {{ $t('notifications.period-choice') }}
    .container
      .notification(
        v-for="(alert, index) in alertPeriods"
        v-if="index == displayedAlert"
      )
        .content
          .modify-notification-dates.opened
            .holiday-dates.opened
              .header
                .header-input
                  i.material-icons.calendar event
                text-field.input-title(
                  :isDisabled="false"
                  :value="alert.title != '' ? alert.title.toUpperCase() : 'MON SEJOUR'"
                  ref="notificationTitle"
                  v-model="alert.title"
                  @input="sendEvent();alert.title = alert.title.toUpperCase()"
                )
                .dates {{ $t('alerts.from') }} {{ formatDate(alert.start_date) }} <br> {{ $t('alerts.to') }} {{ formatDate(alert.end_date) }}
              period-choice-desktop(
                class="opened"
                :value=`{
                from: alert.start_date ? $moment.unix(alert.start_date / 1000) : null,
                to: alert.end_date ? $moment.unix(alert.end_date / 1000) : null }`
                @periodChange="handlePeriodInput"
              )
              .reset-period(v-if="!!alertPeriods[0].start_date " @click="resetAlertPeriod()") {{ $t('reset') }}
              .actions.opened
                .abort(@click="close()") {{ $t('cancel') }}
                .save(@click="saveCalendar(alert)") {{ $t('save') }}
</template>

<script>
import TextField from "@c/rc-text-field/RcTextField.vue";
import contactService from "@s/contacts";
import PeriodChoiceDesktop from "../../components/period-choice/PeriodChoiceDesktop";
const delay = 500;

export default {
  components: {
    PeriodChoiceDesktop,
    TextField,
  },
  props: {
    init: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      date: null,
      alertPeriods: [{ start_date: null, end_date: null, subscriptions_type: [], title: "MON SEJOUR" }],
      displayedAlert: 0,
      formattedValue: {},
    };
  },
  mounted() {
    if(!this.init) {
      this.getAlertPeriods();
    }
  },
  methods: {

    resetAlertPeriod() {
      if(this.init) {
        this.alertPeriods[0].start_date = null;
        this.alertPeriods[0].end_date = null;
      }
      else {
        this.getAlertPeriods();
      }
    },
    formatDate(timestamp) {
      return timestamp ? this.$moment.unix((timestamp + 2 * 60 * 60 * 1000) / 1000).locale('fr').format('ddd DD MMM YYYY') : "...";
    },
    async getAlertPeriods(loaded = null) {
      if(this.init) {
         this.alertPeriods[0] = this.$store.state.alerts.alertsPeriod;
        this.formattedValue = {
          from: this.$moment.unix(this.alertPeriods[0].start_date / 1000) || null,
          to: this.$moment.unix(this.alertPeriods[0].end_date / 1000) || null
        };
      }
      else {
        this.alertPeriods = await contactService.getAlertPeriods();

        if(this.alertPeriods == 0) {
          //User has created an account and skipped steps of alertperiods
          this.alertPeriods[0] = this.$store.state.alerts.alertsPeriod;
        }

        this.$store.dispatch("alerts/setUserAlerts", this.alertPeriods);

        this.formattedValue = {
          from: this.alertPeriods[0] ? this.$moment.unix(this.alertPeriods[0].start_date / 1000) : null,
          to: this.alertPeriods[0] ? this.$moment.unix(this.alertPeriods[0].end_date / 1000) : null
        };
      }
    },
    async saveCalendar(alertPeriod) {
      const periodId = alertPeriod.id;
      alertPeriod.start_date = alertPeriod.start = !Number.isInteger(alertPeriod.start_date) ? parseInt(alertPeriod.start_date.format('x')) : alertPeriod.start_date;
      alertPeriod.end_date = alertPeriod.end = !Number.isInteger(alertPeriod.end_date) ? parseInt(alertPeriod.end_date.format('x')) : alertPeriod.end_date;
      let container = document.querySelector('.block__MobilePeriodChoice');
      container.classList.add('close');

      this.$store.dispatch('alerts/setAlertPeriod', alertPeriod);
      this.$store.dispatch('alerts/setAlertPeriodTitle', alertPeriod.title);

      if(!this.init) {
        await contactService.setAlertPeriod(periodId, alertPeriod);
        await this.getAlertPeriods();
      }

      this.$emit('reload');

      this.goBack();
    },
    async handlePeriodInput({ from, to }) {
      this.alertPeriods[0].start_date = from;
      this.alertPeriods[0].end_date  = to;
    },
    close() {
      document.querySelector('.block__MobilePeriodChoice').classList.add('close');
      setTimeout(() => {
        this.goBack();
      }, 500);
    },
    goBack() {
      this.$emit('close');
    },
    sendEvent() {
      this.$store.dispatch("tagManager/sendEvent", {
          event: 'notifications_name',
        });
    }
  }
};
</script>
