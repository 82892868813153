<template lang="pug">
  .block__destinationConnectFavorites.container(
    :class="{ 'mobile-display': isMobileDisplay }"
  )
    .header-title(v-if="isMobileDisplay")
      .prev-btn.material-icons(@click="goBack") arrow_back_ios
      .title.header
        span(v-if="type == 'deleted'") {{ $t('favorites.trash-title') }}
        span(v-if="type == 'history'") {{ $t('favorites.history-title') }}
        span(v-if="type == 'favorite'") {{ $t('favorites.title') }}
    .center
      vue-scroll
        div( :class="{'fader' : !isMobileDisplay}")
          #bookmarks
            .title.header(v-if="!isMobileDisplay")
              span(v-if="type == 'deleted'") {{ $t('favorites.trash-title') }}
              span(v-if="type == 'history'") {{ $t('favorites.history-title') }}
              span(v-if="type == 'favorite'") {{ $t('favorites.title') }}
            .desc(v-if="!emptyDisplayed() && ( type == 'deleted' || type == 'history')")
              span(v-if="type == 'deleted'") {{ $t('favorites.trash-desc') }}
              span(v-if="type == 'history'") {{ $t('favorites.history-desc') }}
            skeleton(
              v-if="loading"
              type="bookmarks"
              :hasDesc="type!='favorite'"
            )
            .empty-bookmarks(v-if="emptyDisplayed()")
              .no-favorite(v-if="type == 'favorite'")
                heart-filled
                .text {{ $t('favorites.no-favorites')}}
              .no-history(v-if="type == 'history'")
                visite
                .text {{ $t('favorites.no-history')}}
              .no-deleted(v-if="type == 'deleted'")
                corbeille
                .text {{ $t('favorites.no-registered')}}
              .redirect( @click="closeApp()")
                span {{ $t('favorites.redirect')}}
                .redirect-link {{ $t('favorites.link')}}
            .bookmarks-cards(
              :class="{'showMore': showBtns.active && showBtns.type == 'more'}"
            )
            .bookmark_container(
              v-if="displayedBookmarks.length > 0"
              :class="{'history': (type === 'history')}"
            )
              bookmark-card(
                :class="{ 'no-text': !hasText}"
                v-for="bookmark in displayedBookmarks"
                :key="bookmark.id"
                :item="bookmark"
                :type="type"
                :favorites="favoritesIds"
                @putInFavorites="putInSelected($event, 'favoriteItems')"
                @putInHistory="putInSelected($event, 'historyItems')"
                @putInTrash="putInSelected($event, 'deletedItems')"
                @delete="deleteItem($event, 'deletedItems')"
              )
              .actions(v-if="showBtns.active")
                .showMore(v-if="showBtns.type =='more'" @click="showMore('deleted')")
                  span(v-if="type == 'favorite'") {{ $t('favorites.show-more.favorite') }}
                  span(v-if="type == 'history'") {{ $t('favorites.show-more.history') }}
                  span(v-if="type == 'deleted'") {{ $t('favorites.show-more.deleted') }}
</template>



<script>
import Skeleton from '@c/skeletons/Skeleton.vue';
import favoritesService from '@s/favorites.js';
import BookmarkCard from '@c/BookmarkCard.vue';
import Corbeille from '@/assets/corbeille.svg';
import Visite from '@/assets/visite.svg';
import HeartFilled from '@/assets/heart-filled.svg';

const delay = 500;

export default {
    components: {
      BookmarkCard,
      Skeleton,
      Corbeille,
      Visite,
      HeartFilled
    },
    props: {
      type: {
        type: String,
        default: 'favorite',
      },
    },
    data() {
      return {
        loading: false,
        emptyBookmarks: false,
        bookmarks: [],
        favoritesIds: [],
        event: null,
        error: {
          value: false,
          message: ""
        },
        showBtns: {
          active: false,
          type: 'more'
        },
        hasText: false
      };
    },
    mounted() {
      this.loading = true;
      this.checkConnection();
      // this.addFavoritesFromWoody();
      this.getFavorites();
      this.addEvents();
    },
    created() {
      var self = this;//Enable call to "this" in function
      document.addEventListener('swiped-right', function(e) {
        self.goBack();
      });
    },
    methods: {
        async checkConnection() {
          const authResponse = await this.$store.dispatch('auth/getAuth');
          if ( authResponse == "error" || authResponse.connected == false) {
            this.$router.push({ name: 'Login' });
          }
        },
        displayAllItems() {
          this.bookmarks.forEach(element => {
            element.displayed = true;
          });
          this.setShowBtns();
        },
        // async addFavoritesFromWoody() {
        //   // check if bookmarks comes from woody and merge it if it is the case
        //   const bookmarks = document.querySelector('destination-connect-front').getAttribute('bookmarks');

        //   if (bookmarks) {
        //     const response = await favoritesService.setBookmarks(JSON.parse(bookmarks));
        //   }
        // },
        async getFavorites() {
          const data = await favoritesService.getBookmarks();
          let historyLength = data.bookmarks.favorite.length + data.bookmarks.history.length;
          if (
            (this.type != "history" && data.bookmarks && data.bookmarks[this.type].length < 1)
          ||
          (this.type == "history" && historyLength < 1)
          ) {
              this.emptyBookmarks = true;
          } else {
            let items = [];
            let missingItems = [];
            data.bookmarks[this.type].forEach(id => {
              if (this.$store.state.bookmarks.bookmarks[id]) {
                items.push(this.$store.state.bookmarks.bookmarks[id]);
              } else {
                missingItems.push(id);
              }
            })
            if(this.type == "history") {//hotfix merge fav/history
              data.bookmarks["favorite"].forEach(id => {
              if (this.$store.state.bookmarks.bookmarks[id]) {
                items.push(this.$store.state.bookmarks.bookmarks[id]);
              } else {
                missingItems.push(id);
              }
            })
            }

            if (missingItems.length > 0) {
              const ids_str = missingItems.join(',');

              let missingItemsData;

              if(this.$store.state.settings.context == "app") {
                missingItemsData = await favoritesService.getBookmarksData(ids_str, "hiver");//Default on dev, to test with real endpoint from les2alpes
              }
              else {
                missingItemsData = await favoritesService.getBookmarksData(ids_str, this.$store.state.settings.season && this.$store.state.settings.season != "" ? this.$store.state.settings.season : this.$store.state.settings.site_lang);
              }

              // merge items with missing data
              items = items.concat(missingItemsData);
            }

            if (items) {
              items.forEach(item => {
                // set bookmark content in the store
                this.$store.commit('bookmarks/setBookmarkContent', {
                  content: item,
                  id: item.id
                });

                if (data.bookmarks[this.type].includes(item.id) || (this.type == "history" &&data.bookmarks["favorite"].includes(item.id)) ) {
                  this.bookmarks.push(item);
                }
              });
            } else {
                console.log('an error occured while looking for data');
            }
          }
          this.favoritesIds = data.bookmarks.favorite;
          this.setShowBtns();
          setTimeout(() => {
            this.loading = false;
          }, 1000);

          let bookmarks = this.displayedBookmarks;
          bookmarks.forEach( bookmark => {
            if(bookmark.desc) {
              this.hasText = true;
            }
          });
        },
        // Add event listeners on favorites block
        addEvents() {
          document.querySelector('.block__destinationConnectFavorites').addEventListener('click', (e) =>  {
            if (!e.target.classList.contains('params')) {
              const bookmarksActions = document.querySelectorAll('.bookmarks-cards .actions');
              const bookmarksParams = document.querySelectorAll('.params.material-icons');
              bookmarksActions.forEach(bookmarksAction => {
                bookmarksAction.classList.remove('active');
              });
              bookmarksParams.forEach(bookmarksAction => {
                bookmarksAction.classList.remove('active');
              });
            }
          });
        },

        /**
         * Post a specific bookmark changment
         */
        async postBookmark(id, to) {
          let body = {};

          switch(to) {
            case 'favoriteItems':
              body.favorite = [id];
            break;
            case 'deletedItems':
              body.deleted = [id];
            break;
            case 'historyItems':
              body.history = [id];
            break;
          }

          const response = await favoritesService.setBookmarks(body);
          if (!response) {
            console.warn('error while posting bookmarks');
          } else if (this.$store.state.settings.context == "woody") {
            const params = {
              detail: {
                bookmarks: {}
              }
            };
            params.detail.bookmarks = body;
            let event = new CustomEvent("postBookmark", params);

            document.querySelector('destination-connect-front').dispatchEvent(event);
          }
        },


        async postBookmarks() {
          let body = {};
          body[this.type] = this.bookmarks.map(item => item.id);
          const response = await favoritesService.setBookmarks(body);

          if (!response) {
            console.warn('error while posting bookmarks');
          }

          return response;
        },

        async deleteBookmark(id) {
          const response = await favoritesService.deleteBookmark(id);
          if (!response) {
            console.warn('error while posting bookmarks');
          }
        },

        /**
         * @param itemId, item to delete
         */
        deleteItem(itemId) {
          setTimeout(() => {
            for (let key in this.bookmarks) {
              if (this.bookmarks[key].id == itemId) {
                this.bookmarks.splice(key, 1);
                this.deleteBookmark(itemId);
                break;
              }
            }
          }, delay);
          switch(this.type) {
            case 'favorite':
              type = 'favorites';
              break;
            case 'history':
              type = 'page_visited';
              break;
            case 'deleted':
              type = 'garbage';
              break;
            default:
              type = null;
              break;
          }
          if(type) {
            this.$store.dispatch("tagManager/sendEvent", {
              event: type + '_real_delete',
            });
          }
        },

      /**
       * Void function
       * Remove item from bookmarks and add it to listTo
       * @param itemId item id to move from one list to another
       */
      putInSelected(itemId, listTo) {

        setTimeout(() => {
          for (let key in this.bookmarks) {
            if (this.bookmarks[key].id == itemId) {
              if(this.type != "history") {
                this.bookmarks.splice(key, 1);
              }
              this.postBookmark(itemId, listTo);
              this.setShowBtns();
              break;
            }
          }
        }, delay);

        let type;
        let action;
        switch(this.type) {
          case 'favorite':
            type = 'favorites';
            break;
          case 'history':
            type = 'page_visited';
            break;
          case 'deleted':
            type = 'garbage';
            break;
          default:
            type = null;
            break;
        }
        switch(listTo) {
          case 'favoriteItems':
            action = 'add_favorite';
            break;
          case 'deletedItems':
            action = 'delete'
            break;
          case 'historyItems':
            action = 'add_visited';
            break;
          default:
            action = null;
            break;
      }
        if(type && action) {
          this.$store.dispatch("tagManager/sendEvent", {
            event: type + '_card_' + action,
          });
        }
      },
      setActiveItem(item) {
        this.activeItem = item;
      },
      isActiveItem(item) {
        return this.activeItem == item ? true : false;
      },
      setShowBtns() {
        this.showBtns.active = this.bookmarks.filter((element) => {
          return element.displayed;
        }).length > 4 ? true : false;
      },
      showMore(type) {
        this.showBtns.type = "less";
      },
      showLess(type) {
        this.showBtns.type = "more";
      },
      emptyDisplayed() {
        return !this.displayedBookmarks.length ;
      },
      goBack() {
        this.$store.commit("pageTransition/setPageTransition", "back");
        const { previousRoute } = this.$route.meta;

        if (this.isMobileDisplay) {
          this.$router.push({ name: 'AccountMenu' });
        }
        else if (previousRoute !== this.$route.name) {
          this.$router.push({ name: previousRoute });
        } else {
          this.$router.push({ name: 'Dashboard' });
        }

      },
      closeApp() {
        if (this.$store.state.settings.context == "woody") {
          const event = new CustomEvent("closeDestiConnect");
          document.querySelector('destination-connect-front').dispatchEvent(event);
        }
        document.querySelector('destination-connect-front').classList.remove('active');
        let type;
        switch(this.type) {
          case 'favorite':
            type = 'favorites';
            break;
          case 'history':
            type = 'page_visited';
            break;
          case 'deleted':
            type = 'garbage';
            break;
          default:
            type = null;
            break;
        }
        if(type) {
          this.$store.dispatch("tagManager/sendEvent", {
            event: type + '_website_link',
          });
        }
      }
  },
  computed: {
    displayedBookmarks() {
      return this.showBtns.type == 'less' ? this.bookmarks.filter((element) => {return element.displayed;}) : this.bookmarks.filter((element) => {return element.displayed;}).splice(0, 4);
    }
  }
}
</script>
