<template lang="pug">
  .block__alertPeriods(
    :class="{ 'mobile-display': isMobileDisplay }"
    :key="reload"
  )
    vue-scroll
      mobile-period-choice(v-if="openedCalendar && isMobileDisplay" @close="openedCalendar=false" @reload="getAlertPeriods('loaded')")
      .content(v-else)
        .header-title(v-if="isMobileDisplay")
          .prev-btn.material-icons(@click="goBack") arrow_back_ios
          .title.header {{ $t('notifications.title') }}
        .container
          .title.header(v-if="!isMobileDisplay") {{ $t('notifications.title') }}
          skeleton(
            v-if="loading"
            type="notifications"
          )
          div(v-else :class="{'fader' : !isMobileDisplay}")
            .desc.oneline {{ $t('notifications.date.description') }}
            .notification(
              v-for="(alert, index) in alertPeriods"
              v-if="index == displayedAlert"
            )
              //- .prev-btn.material-icons(v-if="index > 0") arrow_back_ios
              .content
                .modify-notification-dates(:class="{'opened': openedCalendar}")
                  .holiday-dates(:class="{'opened': openedCalendar}")
                    .header(@click="editDates")
                      .header-input
                        i.material-icons.calendar event
                      text-field.input-title(
                        :value=" alert.title && alert.title != '' ? alert.title.toUpperCase() : 'MON SEJOUR'"
                        :isDisabled="false"
                        v-if="openedCalendar"
                        ref="notificationTitle"
                        v-model="alert.title"
                        @input="sendEvent();alert.title = alert.title.toUpperCase()"
                      )
                      .notifications-title(v-if="!openedCalendar") {{ alert.title }}
                      .dates(v-if="!isMobileDisplay") {{ $t('alerts.from') }} <strong>{{ formatDate(alert.start_date) }} </strong> {{ $t('alerts.to') }} <strong> {{ formatDate(alert.end_date) }} </strong>
                      .dates(v-else) {{ $t('alerts.from') }} <strong>{{ formatDate(alert.start_date) }} </strong> <br> {{ $t('alerts.to') }} <strong> {{ formatDate(alert.end_date) }} </strong>
                      i.material-icons.edit(v-if="!openedCalendar") edit
                    period-choice-desktop(
                      :class="{'opened': openedCalendar}"
                      :value="formattedValue"
                      @periodChange="handlePeriodInput"
                    )
                    .actions(
                      :class="{'opened': openedCalendar}"
                    )
                      .abort(@click="closeCalendar(true)") {{ $t('cancel') }}
                      .save(@click="saveCalendar(alert)") {{ $t('save') }}
                .notifications-selection
                  .desc {{ $t('notifications.selection.description') }}
                  .subscriptions
                    .subscription(
                      v-for="subscription in alert.subscriptions_type"
                      :class="{ 'large': alert.subscriptions_type.length == 1}"
                    )
                      .text
                        .text-container
                          .alert-title {{ subscription.name_translations[language] ? subscription.name_translations[language] : subscription.name }}
                          .desc.notification-desc {{ subscription.description_translations[language] ? subscription.description_translations[language] : subscription.description }}
                      .switchers
                        #email-switch(v-if="subscription.expedition_mode.includes('email')")
                          span(:class="{ selected: (isSelectedSwitch(subscription, 'email') && isEmailSet) || toToogle('email', subscription.id)}") {{ $t('by_mail') }}
                          .switch(:active="(subscription.subscribe.includes('email') && isEmailSet) || toToogle('email', subscription.id)" @click="toggleSubscription(alert, subscription, 'email')")
                        #mobile-switch(v-if="subscription.expedition_mode.includes('mobile')")
                          span(:class="{ selected: (isSelectedSwitch(subscription, 'mobile') && isMobileSet) || toToogle('mobile', subscription.id)}") {{ $t('by_phone') }}
                          .switch(:active="(subscription.subscribe.includes('mobile') && isMobileSet) || toToogle('mobile', subscription.id)" @click="toggleSubscription(alert, subscription, 'mobile')")
                //- .prev-btn.material-icons(v-if="hasNext(index)" @click="nextAlert(index)") arrow_forward_ios

</template>

<script>
import Skeleton from '@c/skeletons/Skeleton.vue';
import TextField from '@c/rc-text-field/RcTextField.vue'
import contactService from '@s/contacts';
import PeriodChoiceDesktop from '../../components/period-choice/PeriodChoiceDesktop';
import MobilePeriodChoice from '@v/Account/MobilePeriodChoice.vue';
const delay = 500;

export default {
  components: {
    PeriodChoiceDesktop,
    TextField,
    Skeleton,
    MobilePeriodChoice
  },
  data() {
    return {
      loading: false,
      date: null,
      openedCalendar: false,
      alertPeriods: this.$store.state.alerts.myAlerts | [{subscriptions_type: [], title: "mon séjour"}],
      displayedAlert: 0,
      formattedValue: {},
      reload: 0
    };
  },
  mounted () {
    this.checkConnection();
    this.getAlertPeriods();
    if(!this.alertPeriods[0].subscriptions_type.length) {
      this.$router.push({ name: 'Dashboard'});
    }
  },
  created() {
    var self = this;//Enable call to this in function
    document.addEventListener('swiped-right', function(e) {
      self.goBack();
    });

  },
  computed: {
    language() {
      return this.$store.state.settings.site_lang;
    },
    isMobileSet() {
      return this.$store.state.contacts.information.mobile;
    },
    isEmailSet() {
      return this.$store.state.contacts.information.email;
    },
    modalState() {
      return this.$store.state.modal.opened;
    }
  },
  watch: {
    modalState(newVal, oldVal) {
        this.reload = Math.random(); //forces re-render
    }
  },
  methods: {
    async checkConnection() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      this.$store.dispatch('alerts/setSubscriptionsType', authResponse.alerts_period.subscriptions_type);
      if ( authResponse == "error" || authResponse.connected == false) {
          this.$router.push({ name: 'Login' });
      }
    },
    newPeriod() {
      this.$router.push({ name: 'AlertSelectedPeriod', params: { periodId: 0 } });
    },
    formatDate(timestamp) {
      return timestamp ? this.$moment.unix((timestamp + 2 * 60 * 60 * 1000) / 1000).format('ddd DD MMM YYYY') : "...";
    },
    async getAlertPeriods(loaded = false) {
      if (!loaded || !this.$store.state.alerts.myAlerts) {
        this.loading = true;
      }
      this.alertPeriods = await contactService.getAlertPeriods();

      if(this.alertPeriods == 0) {
        //User has created an account and skipped steps of alertperiods
        this.alertPeriods[0] = this.$store.state.alerts.alertsPeriod;
      }

      this.$store.dispatch("alerts/setUserAlerts", this.alertPeriods);

      this.formattedValue = {
        from: this.alertPeriods[0] ? this.$moment.unix(this.alertPeriods[0].start_date / 1000) : null,
        to: this.alertPeriods[0] ? this.$moment.unix(this.alertPeriods[0].end_date / 1000) : null
      };
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    async deleteAlertPeriod(periodId) {
      const response = await contactService.deleteAlertPeriod(periodId);
      await this.getAlertPeriods();
      if (response) {
        this.alertPeriods = this.alertPeriods.filter(it => it.id !== periodId);
      }
    },
    goToPeriod(alertPeriod) {
      setTimeout(() => {
        this.$router.push({ name: 'AlertSelectedPeriod', params: { periodId: alertPeriod.id } });
      }, delay);
    },
    closeCalendar(cancel = false) {
      this.getAlertPeriods('loaded'); // reset alert periods
      this.openedCalendar = false;
      if(cancel) {
        this.$store.dispatch("tagManager/sendEvent", {
          event: 'notifications_cancel',
        });
      }
    },
    async saveCalendar(alertPeriod) {
      this.$store.dispatch('alerts/hasSet', true);
      const periodId = alertPeriod.id;
      alertPeriod.start_date = !Number.isInteger(alertPeriod.start_date) ? parseInt(alertPeriod.start_date.format('x')) : alertPeriod.start_date;
      alertPeriod.end_date = !Number.isInteger(alertPeriod.end_date) ? parseInt(alertPeriod.end_date.format('x')) : alertPeriod.end_date;
      await contactService.setAlertPeriod(periodId, alertPeriod);
      await this.getAlertPeriods('loaded');
      this.closeCalendar();
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'notifications_saved',
      });
    },
    async handlePeriodInput({ from, to }) {
      this.alertPeriods[0].start_date = from;
      this.alertPeriods[0].end_date = to;
    },
    editDates() {
      this.openedCalendar = true;
    },
    toggleSubscription(alertPeriod, subscription, type) {

      this.missingInfoInputOpened = null;

      this.alertPeriods.forEach((element, index) => {

        if (element.id == alertPeriod.id) {

          element.subscriptions_type.forEach((item, item_index) => {

            if (item.id == subscription.id) {

              if (item.subscribe.includes(type)) {
                this.alertPeriods[index].subscriptions_type[item_index].subscribe = this.alertPeriods[index].subscriptions_type[item_index].subscribe.filter(element => element != type);

                if(document.querySelectorAll('.toast-desubscription').length < 2) {
                  this.$toast( this.$t('toast.desubscription') + type + '.', {
                    toastClassName: "toast-desubscription",
                    position: "top-center",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                  });
                }
            } else {
                if( type == 'mobile' && !this.isMobileSet || type == 'email' && !this.isEmailSet ) {
                  this.$toast( this.$t('toast.missing-information') + type, {
                    toastClassName: "toast-missing-info",
                    position: "top-center",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                  });

                  this.$store.dispatch('contacts/setInformationByKey', {
                    key: "toToogle",
                    value: {
                      type: type,
                      id: subscription.id
                    }
                  });

                  this.openModal();

                }
                else {
                  this.alertPeriods[index].subscriptions_type[item_index].subscribe.push(type);
                  if(document.querySelectorAll('.toast-subscription').length < 2) {
                    this.$toast( this.$t('toast.subscription') + type, {
                      toastClassName: "toast-subscription",
                      position: "top-center",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false
                    });
                  }
                }
              }
            }
          });
        }
      });
      this.saveAlert(alertPeriod);
    },
    async saveAlert(alertPeriod) {
      await contactService.setAlertPeriod(alertPeriod.id, alertPeriod);
      await this.getAlertPeriods('loaded');
    },
    hasNext(index) {
      return index < this.alertPeriods.length - 1;
    },
    nextAlert(index) {

    },
    isSelectedSwitch(subscription, type) {
      return subscription.subscribe.includes(type);
    },
    goBack() {
      this.$store.commit("pageTransition/setPageTransition", "back");
      const { previousRoute } = this.$route.meta;

      if (this.isMobileDisplay) {
        this.$router.push({ name: 'AccountMenu' });
      } else if (!previousRoute || previousRoute == this.$route.name) {
        this.$router.push({ name: 'Dashboard' });
      } else if (typeof previousRoute === 'string' ) {
        this.$router.push({ name: previousRoute });
        return;
      }
    },
    toToogle(type, id) {
      if(this.$store.state.contacts.information.tmp_login && this.$store.state.contacts.information.toToogle && this.$store.state.contacts.information.tmp_login.type == type && this.$store.state.contacts.information.toToogle.id == id && this.$store.state.contacts.information.tmp_login.validated) {
        this.alertPeriods[0].subscriptions_type.forEach( (subscription) => {
          if(subscription.id == id) {
            subscription.subscribe.push(type);
          }
        });
        this.$store.state.contacts.information.toToogle = null;
        this.saveAlert(this.alertPeriods[0]);
        return true;
      }
      return false;
    },
    openModal() {
      this.$store.commit('modal/setType', 'MissingInformation');
      this.$store.commit('modal/setOpened', true);
    },
    sendEvent() {
      this.$store.dispatch("tagManager/sendEvent", {
          event: 'notifications_name',
        });
    }
  }
}
</script>
