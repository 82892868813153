import Vue from 'vue';

const state = {
  information: {},
};

const mutations = {
  setInformation(currentState, information) {
    currentState.information = information;
  },
  setInformationByKey(currentState, data) {
    currentState.information[data.key] = data.value;
  },
  setMobile(currentState, newMobile) {
    Vue.set(currentState.information, 'mobile', newMobile);
  },
  resetInformation(currentState) {
    Vue.set(currentState.information = {});
  }
};

export { mutations, state };