<template lang="pug">
section.block__createAccount(:class="{ 'mobile-display': isMobileDisplay , 'fader' : !isMobileDisplay }")
    welcome(v-if="!isMobileDisplay")
    header-container(v-else type="create_account")
    vue-scroll
      .containt.create
          .close-app(v-if="!isMobileDisplay" @click="closeApp") Fermer
            .material-icons close
          .sub-containt.small
              .tunnel-title {{ $t('create.title')}}
              .tunnel-text {{ $t('create.description')}}
              text-field(
                :isDisabled="false"
                ref="loginInputField"
                v-model="email"
                :class="emailClasses"
                :placeholder="$t('login.email')"
                :isEmail="true"
              )
              .or {{ $t('login.or') }}
              .spaceHorizontale
              phone-field(
                default-country-code="FR"
                :value="mobile"
                :translations="translations"
                @update="value => mobile = value"
              )
              phone.logo-phone(v-if="!isMobileDisplay")
              .error-message(:class="{ active: error }") {{ error }}
              .spaceHorizontale
              .actions
                button.next-btn.full(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="next")  {{ $t('create.next') }}
                .spaceHorizontale
                a.user-conditions(:href="userConditionsURL" @click="checkUserConditions()") {{ $t( 'user_conditions') }}
          footer-redirect(redirect="Login" spanText="footer_redirect.account_yet.span" btnText="footer_redirect.account_yet.btn")
</template>

<script>
import HeaderContainer from '@/views/HeaderContainer.vue'
import Welcome from '@/views/Login/Welcome.vue'
import TextField from '@/components/rc-text-field/RcTextField.vue';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import FooterRedirect from '@/components/FooterRedirect.vue';
import PhoneField from 'vue-phone-number-input';
import authService from '@/services/auth.js';
import Phone from '@/assets/phone.svg';
import store from "../../store";


export default {

  components: {
    TextField,
    PhoneField,
    FooterRedirect,
    HeaderContainer,
    Welcome,
    Phone
  },
  data() {
    return {
      code: null,
      loading: false,
      email: this.$store.state.auth.login.type == "mail" ? this.$store.state.auth.login.value : null,
      transientMobile: null,
      translations: {
        countrySelectorLabel: this.$t('phone_input.country_selector_label'),
        countrySelectorError: this.$t('phone_input.country_selector_error'),
        phoneNumberLabel: this.$t('phone_input.phone_number_label'),
        example: this.$t('phone_input.example'),
      },
      error: null
    }
  },
  async created() {
    this.$store.dispatch('settings/setBackDesktop', true);
    const authResponse = await this.$store.dispatch('auth/getAuth');
    this.$store.dispatch('alerts/setSubscriptionsType', authResponse.alerts_period.subscriptions_type);
    document.addEventListener('keypress', this.handleEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },
  methods: {
    handleEnter(e) {
      if(e.key == "Enter") this.next();
    },
  displayError(errorMsg){
      this.$toast( errorMsg, {
        toastClassName: "toast-error",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    async handleResponse(response, type, login) {
      switch (response) {
        case 'success':
          await this.$store.dispatch('login/setLogin', login);
          await this.$store.dispatch('auth/setOTPLogin', { type, value: login });
          this.$router.push({ name: 'ValidateLogin' , params: {previousRoute: 'create'}});
          break;
        case 'invalid_login':
          this.displayError(this.$t('login.error.invalid_login'));
          break;
        case 'user_not_found':
          this.displayError(type == "mobile" ? this.$t('input_mobile.error.user_not_found') : this.$t('input_email.error.user_not_found'));
        break;
        case 'already_in_use':
          this.displayError(type == "mobile" ? this.$t('input_mobile.error.already_in_use') : this.$t('input_email.error.already_in_use'));
        break;
        case 'otp_quota_exceeded':
          this.displayError(this.$t('login.error.otp_quota_exceeded'));
        break;
        default:
          this.displayError(type == "mobile" ? this.$t('input_mobile.error.unknown_error') : this.$t('input_email.error.unknown_error'));
        break;
      }
    },
    async next() {
      if(this.email || this.mobile) {
          // detect which value is complete or valid
          const data = this.getValidLogin();

          if (data === 'invalid_login') {
            this.displayError(this.$t('login.error.invalid_login'));
          } else if (data.login) {
            const type = data.type;

            const response =  await authService.sendValidationCode({ type, value: data.login, is_login: !!this.userId });
            this.handleResponse(response, type, data.login);
            this.loading = false;
      }
      }
    },
    cancel() {
      this.$router.push({ name: 'CreateAccount' });
    },
    getValidLogin() {
      if (this.email) {
        const validationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validationRegex.test(this.email)) {
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'create_account_by_email' ,
          });
          return {
            login: this.email,
            type: "email"
          };
        }
      }

      if (this.mobile) {
        let mobileIsValid = true;
        const mobile = parsePhoneNumber(this.mobile || '', 'FR');
        mobileIsValid = mobile.isPossible() && mobile.isValid();
        if (!mobileIsValid) {
          return 'invalid_login';
        } else {
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'create_account_by_phone' ,
          });
          return {
            login: mobile.number,
            type: "mobile"
          }
        }
      }

      return 'invalid_login';
    },
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
      document.querySelector('destination-connect-front').classList.remove('active');
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: 'create_account'
      });
    },
    checkUserConditions() {
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'click_on_laws',
      });
    }
  },
  computed: {
    mobile: {
      get() {
        const savedValue = this.$store.state.auth.auth.mobile;
        return savedValue ? parsePhoneNumber(savedValue).formatNational() : this.transientMobile;
      },
      set(newValue) {
        this.transientMobile = newValue.phoneNumber ? new AsYouType('FR').input(newValue.phoneNumber) : null;
        this.$store.dispatch('auth/setMobile', newValue.isValid ? newValue.e164 : null);
      },
    },
    emailClasses() {
      if (!!this.error) {
        return 'error';
      }

      let email_pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email_pattern.test(this.email)) {
        return 'valid';
      } else if(this.email && this.email.length > 0) {
        return 'error';
      }

      return '';
    },
    userId() {
      return this.$store.state.auth.auth.user_id;
    },
    userConditionsURL() {
      switch(this.$store.state.settings.env) {
        case 'les2alpes':
          return 'https://www.les2alpes.com/mentions-legales/';
        case 'superot':
          return 'https://sandbox.woody-wp.com/mentions-legales/';
        default:
          return 'https://www.les2alpes.com/mentions-legales/';
      }
    }
  }
}
</script>