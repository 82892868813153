<template lang="pug">
  section.block__welcome(:class="{ 'mobile-display': isMobileDisplay }")
    .containt
      .header-container
        .prev-btn.material-icons(v-if="isMobileDisplay && $route.name === 'Welcome'" @click="back") arrow_back_ios
        component.welcome-logo( :is="Welcome" )
        .close-app(v-if="isMobileDisplay && $route.name === 'Welcome'" @click="closeApp")
          .material-icons close
      .subtext-logo {{ $t('welcome.subtext-logo') }}
      .sub-containt
        .containt-desktop(v-if="!isMobileDisplay")
          .slider-container
            .slider
              .tunnel-left-text(ref="left") {{ $t('welcome.left-text1') }} <span> {{ $t('welcome.left-text2') }}</span>
              .tunnel-middle-text(ref="middle") {{ $t('welcome.middle-text1') }} <span> {{ $t('welcome.middle-text2') }}</span>
              .tunnel-right-text.active(ref="right") {{ $t('welcome.left-text1') }} <span>{{ $t('welcome.left-text2') }}</span>
          illu.img-desktop(v-if="$route.name === 'CreateAccount'")
        .containt-mobile(v-else)
          .slider-container
            .slider
              .tunnel-left-text(ref="left") {{ $t('welcome.left-text1') }} <span> {{ $t('welcome.left-text2') }} </span>
              .tunnel-middle-text(ref="middle") {{ $t('welcome.middle-text1') }} <span> {{ $t('welcome.middle-text2') }}</span>
              .tunnel-right-text.active(ref="right") {{ $t('welcome.left-text1') }} <span> {{ $t('welcome.left-text2') }}</span>

          illu.img-mobile
    .actions(v-if="isMobileDisplay")
      button.btn(@click="$router.push({name: 'CreateAccount'})")
        .account
        .btn-text {{ $t('footer_redirect.no_account_yet.btn') }}
      button.btn(@click="$router.push({name: 'Login'})")
        .account
        .btn-text {{ $t('footer_redirect.account_yet.btn') }}
</template>

<script>
import FooterRedirect from "@/components/FooterRedirect.vue";
import Illu from '@/assets/illu.svg';


const delay = 500;
export default {
  components: {
    FooterRedirect,
    Illu
  },
  data() {
    return {
      intervalId: null
    }
  },
  created() {
    this.intervalId = this.startInterval(this);
  },
  computed: {
    siteKey() {
      if(this.$store.state.settings.context == "app") {
        return 'les-deux-alpes'; //dev
      }
      return this.$store.state.settings.siteKey ;
    },
    Welcome() {
      return () => import(`@/assets/${this.siteKey}.svg`);
    },
  },
  methods: {
    async next() {
      if (this.$store.state.auth.tunnel != "token") {
        const authResponse = await this.$store.dispatch('auth/getAuth');
        this.$store.dispatch("auth/resetAuth");
        this.$store.dispatch("contacts/resetInformation");
      }
      setTimeout(() => {
        if (this.$store.state.auth.tunnel === "token") {
          this.$router.push({ name: "AlertPeriod" });
        } else {
          this.$router.push({ name: "CreateAccount" });
        }
      }, delay);
    },
    hasAccount() {
      setTimeout(() => {
        this.$router.push({ name: "Login" });
      }, delay);
    },
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document
          .querySelector("destination-connect-front")
          .dispatchEvent(event);
      }
      document
        .querySelector("destination-connect-front")
        .classList.remove("active");
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: 'welcome'
      });
    },
    back() {
      this.$store.commit("pageTransition/setPageTransition", "back");
      this.$router.push({ name: "Login" });
    },
    startInterval:
      function (context) {
        setInterval(() => {
          if(this.$route.name == "Welcome" || this.$route.name == "Dashboard" || (this.$route.name == "CreateAccount" && !this.isMobileDisplay) ) {
            if (this.$refs.left && this.$refs.right && this.$refs.middle) {
              let text_left = this.$refs.left;
              let text_right = this.$refs.right;
              let text_middle = this.$refs.middle;

              if(text_right.classList.contains('active')) {
                text_left.classList.add('active');
                text_right.classList.remove('active');
                text_right.classList.add('disappear');
                setTimeout( () => { text_right.classList.remove('disappear') }, 500);

              }
              else if(text_left.classList.contains('active')) {
                text_middle.classList.add('active');
                text_left.classList.remove('active');
                text_left.classList.add('disappear');
                setTimeout( () => { text_left.classList.remove('disappear') }, 500);

              }
              else {
                text_right.classList.add('active');
                text_middle.classList.remove('active');
                text_middle.classList.add('disappear');
                setTimeout( () => { text_middle.classList.remove('disappear') }, 500);

              }
            }
          }
          else {
            clearInterval(this.intervalId);
          }
        }, 6000);
      }
  }
};
</script>
