export default {
  setBackDesktop({ commit }, newState) {
    commit('setBackgroundDesktop', newState);
  },
  setSiteLang({commit}, newState) {
    commit('setSiteLang', newState);
  },
  setBookmarks({commit}, newState) {
    commit('setBookmarks', newState);
  },
  setContext({commit}, newState) {
    commit('setContext', newState);
  },
  setEndpoint({commit}, newState) {
    commit('setEndpoint', newState);
  },
  setSeason({commit}, newState) {
    commit('setSeason', newState);
  },
  setSiteKey({commit}, newState) {
    commit('setSiteKey', newState);
  },
  setRoadbook({commit}, newState) {
    commit('setRoadbook', newState);
  },
  setEnv({commit}, newState) {
    commit('setEnv', newState);
  }
};