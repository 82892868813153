<template lang="pug">
  #finish-authentication
    skeleton(
    type="Authentification"
    )
</template>

<script>
import authService from '@/services/auth.js';
import favoritesService from '@/services/favorites.js';
import contactService from '@/services/contacts.js';
import alertService from '@/services/alerts.js';
import Skeleton from '@c/skeletons/Skeleton.vue';
import subscriptionsService from '@s/subscriptions';

export default {
  components: {
    Skeleton
  },
  data() {
    return {
      loading: true
    };
  },
  mounted () {
    this.authenticate();

  },
  computed: {
    hasInputSubscriptionContact() {
      return this.$store.state.contacts.information?.tmp_login?.validated;
    },
    loginParams() {
      const login = this.$store.state.auth.login;
      if (login.otp && login.otp != 'undefined' && login.otp != null) {
        delete login.token
      }

      return login;
    },
    isLogin() {
      return this.$store.state.auth.isLogin;
    }
  },
  methods: {
    async authenticate() {
      await this.createSession();
    },
    async createSession() {
      const response = await authService.createSession(this.loginParams);

      if (response != 'success') {
        this.$router.push({ name: 'Login'});
        return;
      } else {
        await this.mergeFavorites();

        if(this.hasInputSubscriptionContact) {
          this.$store.dispatch('contacts/setInformationByKey', {
            key: this.$store.state.contacts.information.tmp_login.type,
            value:this.$store.state.contacts.information.tmp_login.value
          });
        }

        if (this.isLogin) {
          this.alertPeriods = await contactService.getAlertPeriods();
          if(this.alertPeriods == 0) {
            //User has created an account and skipped steps of alertperiods
            this.alertPeriods[0] = this.$store.state.alerts.alertsPeriod;
          }
          this.$store.dispatch("alerts/setUserAlerts", this.alertPeriods);
          await this.setAlerts(this.alertPeriods[0]);
        } else {
          await this.setAlerts(this.$store.state.alerts.alertsPeriod);
        }
        this.getSubscriptions();
        this.$store.dispatch('contacts/getInformation', false);
        this.isMobileDisplay ? this.$router.push({name: 'AccountMenu'}) : this.$router.push({name: 'Dashboard'});
      }
    },
    async setAlerts(alert) {
      await alertService.set(alert);
    },
    async mergeFavorites() {
      if (this.$store.state.settings.context == "woody") {
        // check if bookmarks comes from woody and merge it if it is the case
        let event = new CustomEvent("setupBookmark");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
    },
    async getSubscriptions() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      this.$store.dispatch('alerts/setSubscriptionsType', authResponse.alerts_period.subscriptions_type);
      const subscriptionsList = await subscriptionsService.getSubscriptions();
      this.$store.dispatch("subscriptions/setSubscriptions", subscriptionsList);
    }
  }
}
</script>