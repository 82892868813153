import client from './api/apiConnector';

export default {
  async set(alertsPeriod) {
    try {
      if (!!alertsPeriod.id) {
        await client().put(`/contact/alerts/${alertsPeriod.id}`, alertsPeriod);
      } else {
        await client().post(`/contact/alerts`, alertsPeriod);
      }
      return true;
    } catch(error) {
      return false;
    }
  },
};
