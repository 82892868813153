<template lang="pug">
  .previous-route-button(@click="goBack")
    .prev-btn.material-icons arrow_back_ios
    span(v-if="!isMobileDisplay") {{ $t('back') }}
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$store.commit("pageTransition/setPageTransition", "back");
      const { previousRoute } = this.$route.meta;

      if(this.$route.name == "AlertPeriod" ) {
        this.$store.dispatch("tagManager/sendEvent", {
          event: 'create_account_return' ,
        });
      }

      if (!previousRoute) {
        if( this.$route.name == "Login") {
          this.$router.push({ name: "CreateAccount" });
        }
        else if(this.$route.name == "ValidateLogin" || this.$route.name == "CreateAccount") {
          this.$router.push({ name: "Login" });
        }
        return;
      } if (typeof previousRoute === 'string') {
        this.$router.push({ name: previousRoute });
        return;
      }
    },
  },
}
</script>