import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n'
import moment from 'moment'
import isMobileDisplay from '@/mixins/isMobileDisplay'
import PreviousRouteButton from '@c/previous-route-button/PreviousRouteButton.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueCustomElement from 'vue-custom-element';
import VWave from 'v-wave'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vuescroll from 'vuescroll';
import swipedEvent from './swiped-events.js';
import './gtm.js';

Vue.use(swipedEvent);

Vue.use(vuescroll, {} );

Vue.prototype.$vuescrollConfig = {
  bar: {
    showDelay: 500,
    onlyShowBarOnScroll: true,
    keepShow: false,
    background: '#c1c1c1',
    opacity: 1,
    minSize: 0,
    size: '6px',
    disable: false
  }
};

Vue.use(VueCustomElement);

Vue.config.productionTip = false;

Vue.prototype.$moment = moment;

Vue.component('previous-route-button', PreviousRouteButton);

Vue.mixin(isMobileDisplay);

Vue.use(VWave, {
  color: 'red',
  initialOpacity: 0.5,
  easing: 'ease-in',
})


Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});

App.router = router;
App.store = store;
App.i18n = i18n;

Vue.customElement('destination-connect-front', App);

const options = {
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: true
};


Vue.use(Toast, options);