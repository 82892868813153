import authService from '@/services/auth.js';

export default {
  setEmail({ commit }, newEmail) {
    commit('setEmail', newEmail);
  },
  setMobile({ commit }, newMobile) {
    commit('setMobile', newMobile);
  },
  validateChannel({ commit, state }, channel) {
    commit('validateChannel', channel);
  },
  setOTPLogin({ commit }, params) {
    commit('setOTPLogin', params);
  },
  setRedirectUrl({ commit }, redirectUrl) {
    commit('setRedirectUrl', redirectUrl);
  },
  setAutoconnectToken({ commit }, autoconnectToken) {
    commit('setAutoconnectToken', autoconnectToken);
  },
  async getAuth({ state, commit, rootState }) {
    const token = state.login.token;
    const auth = await authService.getAuth(token);
    if (rootState && rootState.alerts && !rootState.alerts.alertsPeriod) {
      commit('alerts/set', auth.alerts_period, { root: true });
    }
    commit('setAuth', auth);

    return auth;
  },
  setTunnel({ commit }, newState ) {
    commit('setTunnel', newState);
  },
  setLoginType({ commit }, subscriptions) {
    commit('setLoginType', subscriptions);
  },
  resetAuth({ commit }) {
    commit('resetAuth');
  },
  isLogin({ commit }) {
    commit('isLogin');
  }
};