<template lang='pug'>
  div.date-picker(:disabled="disabled")
    div.days
      div.date-picker-header
        span {{ monthName | capitalize }} {{ year }}
      table(cellpadding="0" cellspacing="0")
        thead
          tr
            th.text(v-for="day in $moment.localeData('fr').weekdays(true)") {{ day.slice(0,3)|capitalize }}.
        tbody
          tr(v-if="week.days.length == 7" v-for="week in weeks")
            td(v-for="day in week.days")
              div.day(:class="{out: isOut(day), hovering: isHovering(day), selected: isSelected(day), inrange: isPeriod ? inRange(day) : false , from: isPeriod ? isFrom(day) : false , to: isPeriod ? isTo(day) : false }" @click="select(day)" @mouseenter="setHovering(day)")
                .marker {{ day.format('DD') }}
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: {
      type: Object,
      default: () => moment(),
    },
    minYear: {
      type: Number,
      default: 1969,
    },
    maxYear: {
      type: Number,
      default: moment().year() + 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    monthOffset: {
      type: Number,
      default: 0,
    },
    range: Object,
    hovering: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Boolean,
      default: true,
    },
    isPeriod: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selecting() {
      return this.value.from && !this.value.to;
    },
    _moment() {
      return this.$moment(`01/${this.month+1}/${this.year}`, 'DD/MM/YYYY');
    },
    monthName() {
      return this.$moment.localeData('fr').months()[this.month];
    },
    weekdays() {
      return this.$moment.localeData('fr').weekdays(true);
    },
    months() {
      return this.$moment.localeData('fr').months().map((m, i) => ({value: i, text: m}));
    },
    years() {
      const years = [];
      for(let i = this.minYear; i <= this.maxYear; i++) {
        years.push({value: i, text: i});
      }
      return years;
    },
    weeks() {
      const weeks = [];
      const day = this._moment.clone().startOf('week');
      const end = this._moment.clone().endOf('month').endOf('week');
      while(end.diff(day, 'days') >= 0 || weeks.length < 7) {
        const weekId = day.week();
        let week = weeks.find(x => x.weekNumber == day.week());
        if(!week) {
          week = {weekNumber: day.week(), days: []};
          weeks.push(week);
        }
        week.days.push(day.clone());
        day.add(1, 'day');
      }
      return weeks;
    }
  },
  data() {
    return {
      month: null,
      year: null,
    }
  },
  methods: {
    setHovering(day) {
      if(this.isPeriod) {
        this.$emit('hover', day);
      }
    },
    select(day) {
      if(!this.isPeriod) {
        let newValue = {
           from: day,
           to: day
         };
          this.$emit('input', newValue);
         return;
      }
      const newValue = this.value;
      if (!this.value.from && !this.value.to) {
        newValue.from = day;
      } else if (this.value.from && this.value.to) {
        newValue.from = day;
        newValue.to = null;
      } else {
        if (day < newValue.from) {
          newValue.to = newValue.from;
          newValue.from = day;
        } else {
          newValue.to = day;
        }
      }
      this.$emit('input', newValue);
    },
    isHovering(day) {
      return this.selecting && this.hovering && this.value.from < day && day <= this.hovering;
    },
    isOut(day) {
      return day.month() != this.month;
    },
    isSelected(day) {
      return this.$moment(this.value.to).isSame(day, 'day') || this.$moment(this.value.from).isSame(day, 'day') && !(this.selecting && this.hovering > day);
    },
    inRange(day) {
      let formattedDay = day.format("YYYY MM DD");
      return this.range && this.range.from && this.range.to && formattedDay > this.range.from.format("YYYY MM DD") && formattedDay < this.range.to.format("YYYY MM DD");
    },
    isFrom(day) {
      return (this.range && this.range.from && day.isSame(this.range.from, 'day')) || (day.isSame(this.range.to, 'day') && !this.range.from) || (day.isSame(this.range.from, 'day') && this.selecting && this.hovering > day);
    },
    isTo(day) {
      return ((this.range && this.range.to && day.isSame(this.range.to, 'day')) || (day.isSame(this.range.from, 'day') && !this.range.to) || (this.selecting && this.hovering.isSame(day) && day.isAfter(this.range.from))) && !(day.isSame(this.range.from, 'day') && this.selecting && this.hovering > day);
    },
    previousMonth() {
      let newMonth = this.month - 1;
      let newYear = this.year;
      if(newMonth < 0) {
          newMonth = 11;
          newYear--;
      }

      if(newYear < this.minYear) return;
      this.month = newMonth;
      this.year = newYear;
    },
    nextMonth() {
      let newMonth = this.month + 1;
      let newYear = this.year;
      if(newMonth > 11) {
          newMonth = 0;
          newYear++;
      }

      if(newYear > this.maxYear) return;

      this.month = newMonth;
      this.year = newYear;
    },
    initMonthAndYear() {
      const referenceDate = this.$moment();
      const nextMonthReferenceDate = referenceDate.clone().add(this.monthOffset, 'M');
      this.month = this.monthOffset ? nextMonthReferenceDate.month() : referenceDate.month();
      this.year = this.monthOffset ? nextMonthReferenceDate.year() : referenceDate.year();
    },
  },
  mounted() {
    this.initMonthAndYear();
  },
  watch: {
    value() {
      this.initMonthAndYear();
    },
    monthOffset() {
      this.initMonthAndYear();
    },
  },
}
</script>
