<template lang="pug">
    div.skeleton-column
        div.skeleton.skeleton-line.first-title(v-if="hasDescription && isMobileDisplay")
        div.skeleton-row.bookmarks
            div.bookmark(v-for="i in [0, 1]")
                div.skeleton.skeleton-fill
                    div.skeleton.skeleton-fill.photo
                    div.skeleton.skeleton-line.title
                    div.skeleton.skeleton-line.desc
                    div.skeleton.skeleton-line.desc
                    div.skeleton.skeleton-line.desc
            div.bookmark(v-for="i in [0, 1]")
                div.skeleton.skeleton-fill
                    div.skeleton.skeleton-fill.photo
                    div.skeleton.skeleton-line.title
                    div.skeleton.skeleton-line.desc
                    div.skeleton.skeleton-line.desc
                    div.skeleton.skeleton-line.desc
</template>

<script>
import SkeletonMixin from '@/mixins/Skeleton.mixin';

export default {
    mixins: [SkeletonMixin],
    props: {
        hasDescription: {
            type: Boolean,
            default: true
        }
    }
}

</script>

<style lang="scss" scoped>
@import '../../styles/skeleton.scss';

.bookmarks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 0;
    column-gap: 2rem;
    row-gap: 2rem;
    //  @media (max-width: 1300px) {
    //     justify-content: center;
    //  }
}


.bookmark {
    width: 45%;
    max-width: 405px;
    border-radius: 0;

    @media (max-width: 1300px) {
        width: 100%;
    }

    @media (max-width: 1400px) {
        width: 80vw;
    }

    .photo {
        height: 358px;
        border-radius: 0;
    }

    .title {
        margin: 10px 0 30px 30px;
        height: 48px;
        border-radius: 5px;
    }

    .title, .desc {
        margin-left: 30px;
    }
    .desc {
        margin-bottom: 1rem !important;
    }
}

.bookmark > div.skeleton {
    .photo, .title, .desc {
        background-color: #d6d6d6;
    }
}

.first-title {
    margin-top: 50px;
    width: 15% !important;
}

@include media('<desktop') {
    .first-title {
        margin-right: auto;
        margin-left: auto;
        margin-top: 60px;
        margin-bottom: 40px;
        width: 60% !important;
    }

    .bookmark {
        width: 90% !important;
        margin-right: auto;
        margin-left: auto;

        .photo {
            width: 100%;
            height: 350px;
        }

        .desc {
            margin-bottom: 1rem !important;
        }
    }
}

</style>
