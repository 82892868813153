export default {
  setSubscriptions({commit}, subscriptions) {
    commit('setSubscriptions', subscriptions);
  },
  resetSubscriptions({commit}) {
    commit('resetSubscriptions');
  },
  updateUserSubscriptions({commit}) {
    commit('updateSubscription', subscription);
  },
  addSubscription({commit}, subscription) {
    commit('addSubscription', subscription);
  },
};