<template lang="pug">
  .alert-period-choice.calendar
    .nav-btn.previous-month-btn(@click="previousMonth")
      i.material-icons keyboard_arrow_left
    date-picker(
      v-for="(_, i) in isMobileDisplay ? 12 : 2"
      :key="`picker-${i}`"
      :month-offset="i + monthOffset"
      :value="value"
      :range="value"
      :hovering="hovering"
      :mobile="false"
      @hover="day => { hovering = day; }"
      @input="handleInput"
    )
    .nav-btn.next-month-btn(@click="nextMonth")
      i.material-icons keyboard_arrow_right
</template>

<script>
import periodChoiceMixin from './periodChoiceMixin';

export default {
  mixins: [periodChoiceMixin],
  data() {
    return {
      monthOffset: 0,
    }
  },
  methods: {
    previousMonth() {
      if (this.monthOffset) {
        this.monthOffset = this.monthOffset - 1;
      }
    },
    nextMonth() {
      this.monthOffset = this.monthOffset + 1;
    },
  },
}
</script>

<style lang="scss" scoped>
  .alert-period-choice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    border: 1px solid var(--secondary);
    position: relative;
    .date-picker {
      padding: 1rem 1rem 0;
      height: 355px;
    }
    .nav-btn {
      position: absolute;
      top: 50%;
      cursor: pointer;
      padding: 4px 4px 0px 5px;
      background-color: white;
      border-radius: 50%;
      box-shadow: 0px 0px 16px -4px var(--secondary);
      i {
        font-size: 30px;
      }
      &:hover {
        background-color: #F8F8F8;
      }
    }
    .next-month-btn {
      margin-right: -20px;
      right: 0;
    }
    .previous-month-btn {
      margin-left: -20px;
      left: 0;
    }
  }
</style>