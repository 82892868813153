<template lang="pug">
  label.rc-text-field( @click="enableInputFromField" :class="{ error: !!error }")
    input(
      v-if="isDate"
      ref="inputField"
      :placeholder="placeholder"
      @input="e => handleInput(e)"
      @keydown.tab="saveValue($event.target.value)"
      @focus="handleFocus()"
      v-closable="{exclude: ['modify', 'save'], handler: 'saveInputMobile' , active: modify}"
      type="date"
      @keyup.enter="saveValue"
      @keyup="checkPlaceholder()"
      :class="{ 'placeholder': hasSetDate }"
    )
    input(
      v-else-if="!isLock"
      ref="inputField"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="e => handleInput(e)"
      @keydown.tab="saveValue($event.target.value)"
      @focus="handleFocus()"
      :maxlength="length"
      v-closable="{exclude: ['modify', 'save'], handler: 'saveInputMobile' , active: modify}"
      @keyup.enter="saveValue"
      @keyup="checkEmail ? validateEmail(): '' "
    )
    input(
      v-else
      ref="inputField"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="e => handleInput(e)"
      @keydown.tab="saveValue($event.target.value)"
      @focus="handleFocus()"
      v-closable="{exclude: ['modify', 'save'], handler: 'saveInputMobile' , active: modify}"
      max=999999
      @keyup="isGoodFormat"
      type="number"
    )
    mail.logo-email(v-if="isEmail")
    lock.logo-lock(v-if="isLock")
    span.checkmark(v-if="modify && value")
      .checkmark_stem
      .checkmark_kick
    slot
    .error-message {{ error }}
</template>

<script>
import Vue from 'vue'
import Mail from '@/assets/mail.svg';
import Lock from '@/assets/lock.svg';

let handleOutsideClick;

Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
    e.stopPropagation()
    const { handler, exclude} = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          if (vnode.context.$refs[refName]) {
            clickedOnExcludedEl = vnode.context.$refs[refName].contains(e.target);
          }
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }

    const {active} = binding.value;

    if (active) {
      document.addEventListener('click', handleOutsideClick)
    }
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
  }
});

export default {
  components: {
    Mail,
    Lock
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modify: {
      type: Boolean,
      default: false
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    isLock: {
      type: Boolean,
      default: false
    },
    isDate: {
      type: Boolean,
      default: false
    },
    checkEmail: {
      type: Boolean,
      default: false
    },
    length: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      stage: null,
      error: null,
      code: null,
      previousCode: 0,
      hasSetDate: false
    }
  },
  mounted() {
    if (this.modify) {
      this.stage = "modify";
    }
    if(this.isLock) {
      this.isGoodFormat();
      var invalidChars = [
        "-",
        "+",
        "e",
        "E",
        ","
      ];

      this.$refs.inputField.addEventListener( 'keydown', (e) => {
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      });
    }

    this.hasSetDate = this.date;
    this.$refs.inputField.value = this.value;//Fix Date input initial value not set
  },
  methods: {
    handleInput(e) {
      if (!this.modify) {
        this.$emit('input', e.target.value);
      }
    },
    saveInputMobile() {
      if(this.isDate && this.$refs.inputField) {
        this.$refs.inputField.style['border'] = "none";
      }
      this.saveValue();
    },
    enableInputFromField() {
      if(this.isMobileDisplay || this.stage == 'modify') {
        this.enableInput();
      }
    },
    enableInput() {
      if(this.stage == 'save') {
        this.saveValue();
      }
      else if(this.stage = 'modify') {
        this.$refs.inputField.disabled = false;
        this.$refs.inputField.focus();

        this.stage = "save";
      }
    },
    modifyInputStage() {
      if (this.modify && this.$refs.inputField) {
        setTimeout( () => {this.stage = 'modify'; if(this.isDate); this.$refs.inputField.style['border'] = "none";}, 100);
      }
    },
    saveValue() {
      if(this.modify && this.stage == 'save' && !(this.isDate && this.$refs.inputField.style['border'] == "solid 1px red")) {
        this.modifyInputStage();
        this.$refs.inputField.value || this.$refs.inputField.value == ''  ? this.$emit('input', this.$refs.inputField.value) : null;
      }
    },
    inputError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = null;
      }, 2000);
    },
    isGoodFormat() {
      if(this.$refs.inputField.value != ""){
        if(parseInt(this.$refs.inputField.value) < 0){
          this.$refs.inputField.value = 0;
        }
        if(parseInt(this.$refs.inputField.value) > 999999) {
          this.$refs.inputField.value = this.previousCode;
        }
      }

      this.previousCode = this.$refs.inputField.value;

      let validateBtn = this.$route.name == "ValidateLogin" ? document.querySelector('.actions .next-btn'): document.querySelector('.validate-btn.next-btn');
      if(this.$refs.inputField.value.length == 6) {
        if(validateBtn) {
          validateBtn.style['pointer-events'] = 'initial';
          validateBtn.style['opacity'] = 1;
        }
        this.$refs.inputField.style['border'] = "solid 1px green";
      }
      else {
        //disable validate btn
        if(validateBtn) {
          validateBtn.style['pointer-events'] = 'none';
          validateBtn.style['opacity'] = 0.5;
        }
        this.$refs.inputField.style['border'] = "solid 1px black";
      }
    },
    checkPlaceholder() {
      this.$nextTick(() => {
        this.hasSetDate = ! (this.$refs.inputField.value.length && this.$refs.inputField.value.length > 2);
      });
      if(this.$refs.inputField.value.length >= 10) {
        if(this.$refs.inputField.value.length > 10) {
           this.$refs.inputField.value =  this.$refs.inputField.value.substr(0, 4) + this.$refs.inputField.value.substr( this.$refs.inputField.value.indexOf('-'), this.$refs.inputField.value.length);
        }
        if(this.$refs.inputField && this.$refs.save && parseInt(this.$refs.inputField.value.substr(0, 4)) >= 1900 && parseInt(this.$refs.inputField.value.substr(0, 4)) <= new Date().getFullYear()) {
          this.$refs.inputField.style['border'] = "solid 1px green";
          this.$refs.save.style['pointer-events'] = "initial";
        }
        else if(this.$refs.inputField && this.$refs.save) {
          this.$refs.save.style['pointer-events'] = "none";
          this.$refs.inputField.style['border'] = "solid 1px red";
        }
      }
      else if(this.$refs.save && this.$refs.inputField) {
        this.$refs.save.style['pointer-events'] = "none";
        this.$refs.inputField.style['border'] = "solid 1px red";
      }
    },
    validateEmail() {
      const validationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validationRegex.test(this.$refs.inputField.value)) {
        this.$refs.inputField.style['border'] = "solid 1px red";
      }
      else {
        this.$refs.inputField.style['border'] = "solid 1px green";
      }
    },
    handleFocus() {
      this.stage = 'save';
    },
  },
  computed: {
    date() {
      return this.$store.state.contacts.information.birthday;
    }
  }
}
</script>

<style lang="css" scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  display: block !important;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

input[type="date"] {
  position: relative;
  display: flex;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


.rc-text-field {
  position: relative;
}

.rc-text-field input {
  font-family: 'Montserrat';
  --pure-material-font: "Roboto";
  --pure-material-onsurface-rgb: 51, 51, 51;
  --pure-material-primary-rgb: 51, 51, 51;
  position: relative;
  display: inline-block;
  padding-left: 15px;
  width: calc( 100% - 20px);
  background-color: white;
  font-size: 16px;
  height: 45px;
  line-height: 1.5;
  border: 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  transition: all .3s;
}
.rc-text-field input .placeholder {
    color: var(--color_blue_grey);
}

.rc-text-field.error input {
  border: solid 1px var(--color_red) !important;
}

.rc-text-field input::placeholder {
  font-size: 14px;
}

.rc-text-field.error {
  --pure-material-primary-rgb: 244, 67, 54;
  --pure-material-onsurface-rgb: 244, 67, 54;
}

.rc-text-field .error-message {
  opacity: 0;
  padding-top: 2px;
  height: 16px;
  transition: opacity 0.2s;
}
.rc-text-field.error .error-message {
  opacity: 1;
  font-size: 75%;
  transition: opacity 0.2s;
  color: rgba(var(--pure-material-primary-rgb));
}

/* Focus */
.rc-text-field > input:focus,
.rc-text-field > textarea:focus {
  outline: none;
  border: 1px solid var(--color_blue);
  transition: all .3s;
}

.rc-text-field.valid input {
    border: 1px solid var(--color_green);
}

.rc-text-field.error input {
    border: 1px solid var(--color_red);
}

.rc-text-field .material-icons.absolute {
  position: absolute;
  top: -2px;
  right: 15px;
}

.modify-btn, .save {
  position: absolute;
  z-index: 5;
  top: 13px;
  right: 15px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--color_text_blue);
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .rc-text-field > input,
        .rc-text-field > input + span,
        .rc-text-field > input + span::after,
        .rc-text-field > textarea,
        .rc-text-field > textarea + span,
        .rc-text-field > textarea + span::after {
            transition-duration: 0.1s;
        }
    }
}

.checkmark {
  top: 11px;
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg) scale(1.4);
  position: absolute;
  right: 18px;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color: #05B400;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#05B400;
    left:8px;
    top:12px;
}
</style>