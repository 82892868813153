<template lang="pug">
  .disconnect.containt
    .disconnect-title {{ $t("disconnect.title") }}
    .disconnect-text {{ $t("disconnect.description") }}

    button.next-btn(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="closeSession") {{ $t('disconnect.button') }}
    button.skip(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="closeModal") {{ $t('back') }}
</template>

<script>
import sessionsService from '@/services/sessions.js';
import auth from '@/services/auth.js';

export default {
  data() {
    return {};
  },
  methods: {
    async closeSession() {
      // Supress current session
      const items = await sessionsService.getSessions();
      const current_session = items.find(item => item.current_session === true);

      // Remove current session
      const status = await sessionsService.disconnectSession(current_session.session_uuid);

      // check if response status starts with 20
      if (status == 200 || status == 204) {
        this.$store.commit('login/resetLogin');
        this.$store.commit('auth/resetAuth');
        this.$store.commit('contacts/resetInformation');
        this.$store.commit('alerts/reset');
        this.$store.commit('subscriptions/resetSubscriptions');
        this.closeModal();
        this.restartApp();
      } else {
        console.log("Disconnection failed");
      }
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'popup_disconnected_validate' ,
      });
    },
    restartApp() {
      this.$router.push({name: 'Init'});
    },
    closeModal() {
      this.$store.commit('modal/resetModal');
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'popup_disconnected_cancel' ,
      });
    },
  },
}
</script>