<template lang="pug">
    .bookmark-card(ref="bookmarkCard" :key="item.id" @click="e => openTab(item.url, e)" @blur="closeActions")
        .blur-mask(v-if="isMobileDisplay")

        .params.material-icons(@click="$event => actionsModal($event)" :class="{'active': isActive}") more_vert
        .actions(v-droppable="{exclude: ['bookmarkCard'], handler: 'closeActions' , active: true}" :class="{'active': isActive}")
            //- .restore(v-if="type == 'history' && !inFavorites(item)" @click="clickEvent('putInFavorites', item.id, $event)") {{ $t('favorites.put-in-favorites') }}
            //- .put-in-history(v-if="type != 'history' || inFavorites(item)" @click="clickEvent('putInHistory', item.id, $event)") {{ $t('favorites.put-in-history') }}
            //- .put-in-trash(v-if="type != 'deleted'" @click="clickEvent('putInTrash', item.id, $event)") {{ $t('favorites.put-in-trash') }}
            .delete(@click="clickEvent('delete', item.id, $event)") {{ $t('favorites.delete') }}
            .back(v-if="isMobileDisplay" @click="closeActions()") {{ $t('cancel') }}
        img.img(:src="item.img")
        .data
            .head
                .title-container
                    .title {{ item.title }}
                .heart.heart-filled(v-if="type == 'favorite' || (inFavorites(item) && !toGrey )")
                    heart-filled(@click="clickEvent('putInHistory', item.id, $event)")
                .heart(v-else :class="{'heart-filled': toRed}")
                    heart-filled(v-if="toRed" @click="clickEvent('putInHistory', item.id, $event)")
                    heart(v-else @click="clickEvent('putInFavorites', item.id, $event)")
            .desc(v-html="item.desc")
</template>

<script>
import Vue from 'vue';
import Heart from '@/assets/heart.svg';
import HeartFilled from '@/assets/heart-filled.svg';

let handleOutsideClick;

Vue.directive('droppable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
    e.stopPropagation()
    const { handler, exclude} = binding.value
      let clickedOnExcludedEl = !vnode.context._data.isActive;
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          if (vnode.context.$refs[refName]) {
            clickedOnExcludedEl = vnode.context.$refs[refName].contains(e.target);
          }
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }

    const {active} = binding.value;

    if (active) {
      document.addEventListener('click', handleOutsideClick)
    }
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
  }
});

export default {
    components: {
        Heart,
        HeartFilled,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: 'favorite'
        },
        favorites: {
            type: Array
        }
    },
    data() {
        return {
            toRed: false,
            toGrey: false,
            isActive: false
        }
    },
    created() {
        document.addEventListener( 'click', (e) => {
            if(document.querySelector('.actions') && document.querySelector('.actions').classList.contains('active') && e.target.classList.contains('blur-mask') ) {
                this.closeActions();
            }
        });
    },
    methods: {
        inFavorites(item) {
            return this.favorites.includes(item.id);
        },
        actionsModal(e) {
            this.isActive = !e.target.classList.contains('active');

            const blurMask = this.$el.querySelector('.blur-mask');

            if (blurMask) {
                blurMask.style['display'] = 'unset';
            }
        },
        closeActions() {
            document.querySelector('.actions').classList.add('down');
            const blurMask = this.$el.querySelector('.blur-mask');
            if (blurMask) {
                blurMask.style['display'] = 'none';
            }
            this.isActive = false;
            setTimeout(() => {
                document.querySelector('.actions.down') ? document.querySelector('.actions.down').classList.remove('down') : '';
            }, 400);
        },
        openTab(url, e) {
            if (!e.target.classList.contains('params') && !e.target.classList.contains('heart') && !e.target.classList.contains('back') && !e.target.classList.contains('blur-mask') ) {
                window.open(url);
            }
            else if (e.target.classList.contains('blur-mask')) {
                this.closeActions();
            }
        },
        clickEvent(type, id, e) {
            this.toGrey  = false;
            this.toRed = false;
            let actions = document.querySelector('.actions.active');
            if(!this.isMobileDisplay && actions) {
                this.isActive = false;
            }
            e.preventDefault();
            e.stopPropagation();

            if((type == 'putInHistory' && this.$route.name == 'BookmarksDeleted')) {
                this.$el.classList.add('grey-disappear');
            }
            else if(this.$route.name != 'BookmarksHistory') {
                this.$el.classList.add('disappear');
            }
            else {
                this.toGrey = true;
                this.$el.classList.add('no-heart');
            }

            if( ( type == 'putInTrash' && this.$route.name == 'BookmarksHistory')  || type == 'delete' ){
                this.$el.classList.add('no-heart');
            }
            else if( type == 'putInFavorites' && ( this.$route.name == 'BookmarksHistory' ||  this.$route.name == 'BookmarksDeleted') ){
                this.toRed = true;
            }
            if (e) {
                e.stopPropagation();
            }

            this.$emit(type, id);
            this.closeActions();
        },
    },
}
</script>