import Vue from 'vue';

const state = {
  login: "",
  lastTimeModified: null
};

const mutations = {
  setLogin (currentState, newState) {
    currentState.login = newState;
    currentState.lastTimeModified = new Date().getTime();
  },
  resetLogin(currentState) {
    currentState.login = "";
    currentState.lastTimeModified = null;
  }
};

export { mutations, state };