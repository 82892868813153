<template lang="pug">
  .block__destinationConnect(:class="{ 'mobile-display': isMobileDisplay, 'summer': season == 'ete' }")
    .blur-mask(v-if="isMobileDisplay")
    transition(
      v-if="isMobileDisplay"
      :name="transitionName"
      :mode="transitionMode"
      v-on:after-enter="afterEnter"
      v-on:after-leave="afterLeave"
    )
      router-view.main-content(class='transition')
    router-view.main-content(v-else)
    modal
</template>
<script>
import AccountMenu from '@v/Account/AccountMenu.vue';
import Modal from '@c/Modal.vue';
import contactService from '@s/contacts';

export default {
  components: {
    AccountMenu,
    Modal
  },
  props: {
    context: {
      type: String,
      default: 'app'
    },
    sitelang: {
      type: String,
      default: 'en'
    },
    season: {
      type: String,
    },
    sitekey: {
      type: String,
      default: null
    },
    bookmarksendpoint: {
      type: String,
      default: ''
    },
    roadbook: {
      type: String,
      default: 'false'
    },
    active: {
      type:   String,
      default: 'true'
    },
    env: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: !!this.$store.state.auth.auth,
      alertPeriods: [{subscriptions_type: [], title: "mon séjour"}],
    };
  },
  mounted() {
    this.setAutoconnectToken();
    this.setSettings();
    this.setBookmarks();
    this.destinationConnectAuth();
    if(this.$store.state.auth.auth.connected) {
      this.getAlertPeriods();
    }
    this.setColor();
  },
  created() {
    this.$moment.locale('fr');
  },
  methods: {
    async getAlertPeriods() {
      this.alertPeriods = await contactService.getAlertPeriods();

      if (this.alertPeriods == 0) {
        //User has created an account and skipped steps of alertperiods
        this.alertPeriods[0] = this.$store.state.alerts.alertsPeriod;
      }

      this.$store.dispatch("alerts/setUserAlerts", this.alertPeriods);
    },
    getDateOverlap(suggested_start_date, suggested_end_date, start_date, end_date) {
      if (suggested_start_date >= start_date && suggested_end_date <= end_date) {
        return 'contained';
      }
      const overlapBefore = suggested_end_date >= start_date && suggested_end_date <= end_date;
      const overlapAfter = suggested_start_date >= start_date && suggested_start_date <= end_date;
      if (overlapBefore || overlapAfter) {
        return 'overlap';
      }
      return 'excluded';
    },
    async destinationConnectAuth() {
      this.loading = true;
      const authResponse = await this.$store.dispatch('auth/getAuth');

      if ( authResponse == "error" ) {
        console.warn('An error occured while fetching api.');
        this.$router.push({ name: 'Login' });
      } else {
        const { connected, token_consumed, suggested_start_date, suggested_end_date, alerts_period } = authResponse;
        const { start_date, end_date } = alerts_period;

        if (connected) {
          if (this.$store.state.settings.context != "app") {
            const connectEvent = new Event('destiConnectAuth');
            window.dispatchEvent(connectEvent);
          }

          if (token_consumed === null) {
            this.continueRoute();
            return;
          }
          if (token_consumed) {
            this.continueRoute();
            return;
          }
          const dateOverlap = this.getDateOverlap(suggested_start_date, suggested_end_date, start_date, end_date);
          if (dateOverlap === 'contained') {
            this.continueRoute();
            return;
          }

          // simple tunnel
          if (this.$route.name != 'AlertPeriods') {
            this.$router.push({ name: 'AlertPeriods' });
          }
          this.loading = false;
          return;
        } else if (this.$store.state.settings.context != "app") {
          const disconnectEvent = new Event('destiConnectEnd');
          window.dispatchEvent(disconnectEvent);
        }

        if (token_consumed === null) {
          // login tunnel
          if (this.$route.name != 'Login') {
            this.$router.push({ name: 'Login' });
          }
          this.loading = false;
          return;
        } if (token_consumed) {
          // Consumed Token tunnel
          if (this.$route.name != 'ConsumedToken') {
            this.$router.push({ name: 'ConsumedToken' });
          }
          this.loading = false;
          return;
        }

        if (!token_consumed) {
          // complete tunnel
           this.$store.dispatch('auth/setOTPLogin', {
            token: this.$store.state.auth.login.token,
            type: this.$store.state.auth.auth.login_type,
            value: this.$store.state.auth.auth[this.$store.state.auth.auth.login_type],
          });
          this.$router.push({ name: 'FinishAuthentication' });
          return;
        }
      }
    },
    continueRoute() {
      if (this.$route.name == "PersonalInfo" || this.$route.name == "Favorites" || this.$route.name == "AlertPeriods") {
        // stay here
      } else if (this.$route.name != 'AccountMenu') {
        this.$router.push({ name: 'AccountMenu' });
      }
      this.loading = false;
    },
    setSettings() {
      this.$store.dispatch('settings/setContext', this.context);
      this.$store.dispatch('settings/setEndpoint', this.bookmarksendpoint);
      if (this.$store.state.settings.context === "app") {
        this.$store.dispatch('settings/setEndpoint', 'https://www.les-deux-alpes.wp.rc-preprod.com/wp-json/woody');
      }
      this.$store.dispatch('settings/setSiteKey', this.sitekey);
      this.$store.dispatch('settings/setRoadbook', this.roadbook == '1' || this.roadbook == 'true');//Hotfix - woody send "1" instead of true ?
      this.$store.dispatch('settings/setSeason', this.season);
      this.$store.dispatch('settings/setSiteLang', this.sitelang);
      this.$store.dispatch('settings/setEnv', this.env);

      this.$i18n.locale = this.sitelang || 'fr';
      this.$store.commit('modal/resetModal');
      if(this.$store.state.settings.context == "app" || this.active) {
        document.querySelector('destination-connect-front').classList.add('active');
      }
    },
    setColor() {
      if (this.$store.state.settings.context == "app") {
        this.$el.style.setProperty('--primary-color', '#202945');
        this.$el.style.setProperty('--secondary-color', '#d2252b');
      }
      else {
        //set sandbox font
        if( this.$store.state.settings.siteKey == 'woody-sandbox') this.$el.style.setProperty('--siteFont', '"Patua One",sans-serif');
      }
    },
    setAutoconnectToken() {
      const urlParams = window.location.hash.substr(window.location.hash.indexOf("token=") + 6, window.location.hash.length);
      const token = urlParams || null;
      this.$store.dispatch('auth/setAutoconnectToken', token);
      if (token) {
        this.$store.dispatch('auth/setTunnel', 'token');
      } else {
        this.$store.dispatch('auth/setTunnel', null);
      }
    },
    setBookmarks() {
      const urlParams = new URLSearchParams(window.location.search);
      const bookmarks = urlParams.get('bookmarks') || null;
      if (bookmarks) {
        this.$store.dispatch('settings/setBookmarks', bookmarks);
      } else {
        this.$store.dispatch('settings/setBookmarks', null);
      }
    },
    selectTransition() {
      if(!this.isMobileDisplay) {
        return 'fade';
      }
      if(!this.$router.currentRoute) {
        return 'fade';
      }
      if(this.$router.currentRoute.name == 'AccountMenu') {
        return 'invert-slide';
      }
      else {
        return 'slide';
      }
    },
    afterEnter() {
      window.scrollTo(0, 0);
    },
    afterLeave() {
      this.initPageTransition();
    },
    initPageTransition() {
      this.$store.commit("pageTransition/setPageTransition", "default");
    },
  },
  computed : {
    transitionName() {
      return this.$store && this.$store.state.pageTransition.name;
    },
    transitionMode() {
      return this.$store && this.$store.state.pageTransition.mode;
    },
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
  @import './styles/styles.scss';
  @import './styles/mobile.scss';
</style>
