<template lang="pug">
  .accountMenu.desktop(
      ref="accountMenu"
      :class="{ 'mobile-display': isMobileDisplay }"
    )
    vue-scroll
      .container
        .header-account( v-if="isMobileDisplay")
          component.welcome-logo(:is="Welcome")
        .close-app(v-if="isMobileDisplay" @click="closeApp()")
          .material-icons close
        .head-sidebar
          .tunnel-title(@click="navigateToItem({ 'route': !isMobileDisplay ? 'Dashboard' : null})") {{ $t('my_account') }}
          p.tunnel-subtitle
            span.hello {{ $t(`account_menu.hello`)}}
            span.space {{ " " }}
            span.login(v-if="login != '' ") {{ login }} {{ " !"}}
          p.message(v-if="noSetNotifications && !noNotif" @click="navigateToItem( {'route': 'AlertPeriods'} )") {{ $t('account_menu.message-set-alert') }} <span>{{ $t('account_menu.message-set-alert-link') }} </span>
          p.message(v-else-if="noSetInformations" @click="navigateToItem( {'route': 'PersonalInfo' })") {{ $t('account_menu.message-set-info') }} <span>{{ $t('account_menu.message-set-info-link') }} </span>

          .menu
            .menu-item(
              v-for="item in menuItems"
              v-if="(item.icon != 'roadbook' || hasRoadbooks) && !(item.slug == 'alerts' && noNotif) && !(item.slug == 'subscriptions' && noSubscriptions) && !(item.slug == 'reservation' && siteKey != 'les-deux-alpes')"
              :key="item.slug"
              :class="{ large: (item.large && hasRoadbooks) || (item.adapt && !hasRoadbooks), selected: ($route.name === item.route && $route.name!='Favorites') || ($route.name =='Favorites' && $route.query.slug === item.slug) || (item.slug == 'favorites' && !$route.query.slug && $route.name == 'Favorites')}"
              @click="navigateToItem(item)"
            )

              button.next-btn
                corbeille.svg-icon(v-if="item.icon == 'corbeille' ")
                visite.svg-icon(v-if="item.icon == 'visite' ")
                heart.svg-icon(v-if="item.icon == 'favorite' ")
                roadbook.svg-icon(v-if="item.icon == 'roadbook' ")
                subscription.svg-icon.sub(v-if="item.icon == 'subscription'")
                home.svg-icon(v-if="item.icon == 'reservation'")
                i.item-icon.material-icons(v-if="!['reservation', 'visite', 'corbeille', 'favorite', 'roadbook', 'subscription'].includes(item.icon)") {{ item.icon }}
                .wrapper
                  .item-text {{ $t(`account_menu.${item.slug}`) }}
        .padder
          .disconnect-btn(v-if="!isMobileDisplay" @click="openModal") {{ $t('account_menu.disconnect') }}
            span.material-icons power_settings_new

          .disconnect-circle(v-else @click="openActions") {{ $t('account_menu.disconnect') }}

          .mobile-actions(v-if="actionsOpened")
            .disconnect-mobile-btn(@click="closeSession") {{ $t('disconnect.title') }}
            .back-btn(@click="back") {{ $t('back') }}
</template>

<script>
const delay = 500;
import sessionsService from '@/services/sessions.js';
import Corbeille from '@/assets/corbeille.svg';
import Visite from '@/assets/visite.svg';
import Heart from '@/assets/heart-filled.svg';
import Roadbook from '@/assets/roadbook.svg';
import Subscription from '@/assets/subscription.svg';
import Home from '@/assets/home.svg';

export default {
  components: {
    Corbeille,
    Visite,
    Heart,
    Roadbook,
    Subscription,
    Home
  },
  data() {
    return {
      actionsOpened: false,
      menuItems: [
        {
          slug: 'personal_info',
          desc: 'personal_desc',
          icon: 'account_circle',
          route: 'PersonalInfo',
          large: true,
          adapt: true
        },
        {
          slug: 'roadbook',
          icon: 'roadbook',
          route: 'Roadbook',
        },
        {
          slug: 'alerts',
          desc: 'alerts_desc',
          icon: 'notifications',
          route: 'AlertPeriods',
          adapt: true
        },
        {
          slug: 'subscriptions',
          desc: 'subscriptions_desc',
          icon: 'subscription',
          route: 'Subscriptions',
        },
        {
          slug: 'reservation',
          desc: 'reservation_desc',
          icon: 'reservation',
        },
        {
          slug: 'favorites',
          desc: 'favorites_desc',
          icon: 'favorite',
          route: 'Favorites',
        },
        {
          slug: 'history',
          desc: 'history_desc',
          icon: 'visite',
          route: 'BookmarksHistory',
        },
        // {
        //   slug: 'deleted',
        //   desc: 'deleted_desc',
        //   icon: 'corbeille',
        //   route: 'BookmarksDeleted',
        // }
      ],
    }
  },
  mounted() {
    this.checkConnection();
  },
  created() {
    document.addEventListener( 'click', (e) => {
        if(this.actionsOpened && e.target.classList.contains('blur-mask') ) {
          this.back();
        }
      }
    );
  },

  computed: {
    noSetNotifications() {
      if(this.$store.state.alerts.myAlerts[0] && this.$store.state.alerts.myAlerts[0].id) {
        this.$store.dispatch('alerts/hasSet', true);
      }
      return !this.$store.state.alerts.hasSet;
    },
    noNotif() {
      return this.$store.state.alerts.alertsPeriod?.subscriptions_type.length === 0;
    },
    noSubscriptions() {
      return this.$store.state.subscriptions.userSubscriptions?.length == 0;
    },
    noSetInformations() {
      return !(this.$store.state.contacts.information.email || this.$store.state.contacts.information.mobile) || !this.$store.state.contacts.information.firstname || !this.$store.state.contacts.information.lastname;
    },
    login() {
      let name = this.$store.state.contacts.information.mobile ? this.format(this.$store.state.contacts.information.mobile) : '';

      if (!!this.$store.state.contacts.information.lastname && !!this.$store.state.contacts.information.firstname) {
        name = this.$store.state.contacts.information.firstname + " " + this.$store.state.contacts.information.lastname;
      } else if (!!this.$store.state.contacts.information.lastname) {
        name = this.$store.state.contacts.information.lastname;
      } else if (!!this.$store.state.contacts.information.firstname) {
        name = this.$store.state.contacts.information.firstname;
      } else if (!!this.$store.state.contacts.information.email) {
        name = this.$store.state.contacts.information.email;
      }
      return name;
    },
    hasRoadbooks() {
      return false;//this.$store.state.settings.hasRoadbook;
    },
    siteKey() {
      if(this.$store.state.settings.context == "app") {
        return 'les-deux-alpes'; //dev
      }
      return this.$store.state.settings.siteKey ;
    },
    Welcome() {
      return () => import(`@/assets/${this.siteKey}.svg`);
    },
  },
  methods: {
    //return the phone number in format ; +33 3 33 33 33 33
    format(tel) {
      tel = tel.replaceAll(' ', '');
      if(tel[0] == 0 ) {
        tel = "+33" + tel.substring(1);
      }
      tel = "+33" + tel.replace('+33', '');
      let formattedTel = '';
      for( let i =0 ; i < tel.length ; i++) {
        if( ( i%2 == 0 && i > 2) || i == 3) {
          formattedTel += ' ';
        }
        formattedTel += tel[i];
      }
      return formattedTel;
    },
    async checkConnection() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      if ( authResponse == "error" || authResponse.connected == false) {
          this.$router.push({ name: 'Login' });
      }
      if (!this.isMobileDisplay) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    navigateToItem(item) {
      if(item.slug == "reservation") {
        window.open(this.$store.state.settings.site_lang == "fr" ? "https://reservation.les2alpes.com/identification.html" : "https://booking.les2alpes.com/identification.html", '_blank');
        return;
      }
      if (this.$route.name !== item.route) {
        setTimeout(() => {
          this.$router.push({ name: item.route });
        }, delay);
      }
      let routeName;
      switch(item.route) {
        case 'PersonalInfo':
          routeName = 'information';
          break;
        case 'Roadbook':
          routeName = 'roadbook';
          break;
        case 'AlertPeriods':
          routeName = 'notification';
          break;
        case 'Subscriptions':
          routeName = 'subscriptions';
          break;
        case 'Favorites':
          routeName = 'favorites';
          break;
        case 'BookmarksHistory':
          routeName = 'page_visited';
          break;
        case 'BookmarksDeleted':
          routeName = 'garbage';
          break;
        default:
          break;
      }
      if(routeName) {
        this.$store.dispatch("tagManager/sendEvent", {
          event: 'menu_' + routeName ,
        });
      }
    },
    openModal() {
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'menu_disconnected' ,
      });
      this.$store.commit('modal/setType', 'Disconnect');
      this.$store.commit('modal/setOpened', true);
    },
    openActions() {
      document.querySelector('.blur-mask').style['display'] = 'unset';
      this.actionsOpened = true;
    },
    async closeSession() {
      this.back();
      // Supress current session
      const items = await sessionsService.getSessions();
      const current_session = items.find(item => item.current_session === true);

      // Remove current session
      const status = await sessionsService.disconnectSession(current_session.session_uuid);

      // check if response status starts with 20
      if (status == 200 || status == 204) {
        this.$store.commit('login/resetLogin');
        this.$store.commit('auth/resetAuth');
        this.$store.commit('contacts/resetInformation');
        this.$store.commit('alerts/reset');
        this.$router.push({name: 'Init'});
      } else {
        console.log("Disconnection failed");
      }

      this.$store.dispatch("tagManager/sendEvent", {
        event: 'menu_disconnected' ,
      });
    },
    back() {
      document.querySelector('.mobile-actions').classList.add('down');
      document.querySelector('.blur-mask').style['display'] = 'none';
      setTimeout(() => { this.actionsOpened = false; }, 400);
    },
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
      document.querySelector('destination-connect-front').classList.remove('active');
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: 'menu'
      });
    }
  },
}
</script>
