const state = {
  userSubscriptions: [
  ]
};

const mutations = {
  addSubscription(currentState, subscription) {
    currentState.userSubscriptions.push(subscription);
  },
  updateSubscription(currentState, subscription) {
    const newState = currentState.userSubscriptions.map(obj =>
      obj.value === subscription.value ? { ...obj, status: subscription.status } : obj
    );
  },
  setSubscriptions(currentState, userSubscriptions) {
    currentState.userSubscriptions = userSubscriptions;
  },
  resetSubscriptions(currentState) {
    currentState.userSubscriptions = null;
  },
};

export { mutations, state };