<template lang="pug">
section.block__login(:class="{ 'mobile-display': isMobileDisplay  , 'fader' : !isMobileDisplay}")
  header-container(type="login")
  .containt
    vue-scroll
      .wrapper-scroll
        .sub-containt.small
            .tunnel-title {{ $t('login.title') }}
            .tunnel-text {{ $t('login.email_or_mobile' )}}
            text-field(
              :isDisabled="false"
              ref="loginInputField"
              v-model="email"
              :class="emailClasses"
              :placeholder="$t('login.email')"
              :isEmail="true"
            )
            .or {{ $t('login.or') }}
            .spaceHorizontale
            phone-field(
              default-country-code="FR"
              :value="mobile"
              :translations="translations"
              @update="value => mobile = value"
            )
            phone.logo-phone(v-if="!isMobileDisplay")
            .error-message(:class="{ active: error }") {{ error }}
            .spaceHorizontale
            .actions
              button.next-btn.full(v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}" @click="next") {{ $t('next') }}
        footer-redirect( v-if="isMobileDisplay" redirect="Welcome" spanText="footer_redirect.no_account_yet.span" btnText="footer_redirect.no_account_yet.btn")
        footer-redirect( v-else redirect="CreateAccount" spanText="footer_redirect.no_account_yet.span" btnText="footer_redirect.no_account_yet.btn")

</template>

<script>
import HeaderContainer from '@/views/HeaderContainer.vue';
import TextField from '@/components/rc-text-field/RcTextField.vue';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import PhoneField from 'vue-phone-number-input';
import FooterRedirect from '@/components/FooterRedirect.vue';
import authService from '@/services/auth.js';
import Phone from '@/assets/phone.svg';

const delay = 500;

export default {

  components: {
    TextField,
    PhoneField,
    FooterRedirect,
    HeaderContainer,
    Phone
  },
  data() {
    return {
      code: null,
      loading: false,
      email: this.$store.state.auth.login && this.$store.state.auth.login.type == "mail" ? this.$store.state.auth.login.value : null,
      transientMobile: null,
      translations: {
        countrySelectorLabel: this.$t('phone_input.country_selector_label'),
        countrySelectorError: this.$t('phone_input.country_selector_error'),
        phoneNumberLabel: this.$t('phone_input.phone_number_label'),
        example: this.$t('phone_input.example'),
      },
      error: null
    }
  },
  created() {
    document.addEventListener('keypress', this.handleEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },
  methods: {
    handleEnter(e) {
      if(e.key == "Enter") this.next();
    },
    displayError(errorMsg){
      this.$toast( errorMsg, {
        toastClassName: "toast-error",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    handleResponse(response, type, login) {
      switch (response) {
        case 'success':
          this.$store.dispatch('login/setLogin', login);
          this.$store.dispatch('auth/setOTPLogin', { type, value: login });
          this.$store.dispatch('auth/isLogin');
          this.$router.push({ name: 'ValidateLogin' });
          break;
        case 'invalid_login':
          this.displayError(this.$t('login.error.invalid_login'));
        case 'user_not_found':
          this.displayError(type == "mobile" ? this.$t('input_mobile.error.user_not_found') : this.$t('input_email.error.user_not_found'));
        break;
        case 'empty_login':
          this.displayError(this.$t('login.error.empty_login'));
        break;
        case 'otp_quota_exceeded':
          this.displayError(this.$t('login.error.otp_quota_exceeded'));
        break;
      }
    },
    async next() {
      // detect which value is complete or valid
      const data = this.getValidLogin();

      if (!!data && data.login) {
        // let now = new Date();
        const type = data.type;
        const response =  await authService.sendValidationCode({ type, value: data.login, is_login: true });
        this.handleResponse(response, type, data.login);
        this.loading = false;
      }
    },
    cancel() {
      this.$store.commit("pageTransition/setPageTransition", "default");
      this.$router.push({ name: 'CreateAccount' });
    },
    getValidLogin() {
      if (!!this.email) {
        let email_pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email_pattern.test(this.email)) {
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'connected_by_email' ,
          });
          return {
            login: this.email,
            type: "email"
          };
        }
      } else if (!!this.mobile) {
        let mobileIsValid = true;
        const mobile = parsePhoneNumber(this.mobile || '', 'FR');
        mobileIsValid = mobile.isPossible() && mobile.isValid();

        if (!mobileIsValid) {
          this.displayError(this.$t('login.error.invalid_login'));
          return null;

        } else {
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'connected_by_phone' ,
          });
          return {
            login: mobile.number,
            type: "mobile"
          }
        }
      } else {
        this.displayError(this.$t('login.error.empty_login'));
        return null;
      }

      this.displayError(this.$t('login.error.invalid_login'));
      return  null;
    },
    goBack() {
      return true;
    }
  },
  computed: {
    mobile: {
      get() {
        const savedValue = this.$store.state.auth.auth && this.$store.state.auth.auth.mobile;
        return savedValue ? parsePhoneNumber(savedValue).formatNational() : this.transientMobile;
      },
      set(newValue) {
        this.transientMobile = newValue.phoneNumber ? new AsYouType('FR').input(newValue.phoneNumber) : null;
        this.$store.dispatch('auth/setMobile', newValue.isValid ? newValue.e164 : null);
      },
    },
    emailClasses() {
      if (!!this.error) {
        return 'error';
      }

      let email_pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email_pattern.test(this.email)) {
        return 'valid';
      } else if(this.email && this.email.length > 0) {
        return 'error';
      }

      return '';
    }
  },
}
</script>
