export default {
    setOpened({ commit }, newState) {
        commit('setOpened', newState);
    },
    setType({ commit }, newState) {
        commit('setType', newState);
    },
    resetModal({ commit }) {
        commit('resetModal');
    }
}