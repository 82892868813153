<template lang="pug">
  .block.alert-list
    alert-tile(
      v-for="(subscription, index) in subscriptions"
      :key="`subscription-${index}` + key"
      :subscription="subscription"
      @change="changeAlert"
    )
</template>

<script>
import AlertTile from './AlertTile/AlertTile.vue';

export default {
  components: {
    AlertTile,
  },
  data() {
    return {
      key: 0
    }
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    changeAlert(newAlert) {
      const newAlerts = [...this.subscriptions];
      const replaceAtIndex = newAlerts.findIndex(it => it.id === newAlert.id);
      newAlerts.splice(replaceAtIndex, 1, newAlert);
      this.$emit('change', newAlerts);
      this.key++;
    },
  },
};
</script>