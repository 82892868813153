import Vue from 'vue';

const state = {
  auth: null,
  login: {},
  tunnel: "",
  isLogin: false
};

const mutations = {
  setEmail(currentState, newEmail) {
    Vue.set(currentState.auth, 'email', newEmail);
  },
  setMobile(currentState, newMobile) {
    Vue.set(currentState.auth, 'mobile', newMobile);
  },
  validateChannel(currentState, channel) {
    Vue.set(currentState.auth, `is_${channel}_validated`, true);
  },
  setOTPLogin(currentState, params) {
    Vue.set(currentState.login, 'otp', params.otp || currentState.login.otp);
    Vue.set(currentState.login, 'token', params.token || currentState.login.token);
    Vue.set(currentState.login, 'type', params.type || currentState.login.type);
    Vue.set(currentState.login, 'value', params.value || currentState.login.value);
  },
  setAutoconnectToken(currentState, token) {
    Vue.set(currentState.login, 'token', token);
  },
  setRedirectUrl(currentState, redirectUrl) {
    Vue.set(currentState.login, 'redirectUrl', redirectUrl);
  },
  setAuth(currentState, auth) {
    currentState.auth = auth;
  },
  setTunnel(currentState, tunnel) {
    currentState.tunnel = tunnel;
  },
  setLoginType(currentState, subscriptions) {
    let needEmail, needMobile = false;
    for (const subscription of subscriptions) {
      if(subscription.subscribe && subscription.subscribe.length > 0) {
        if (subscription.subscribe.includes('mobile')) {
          needMobile = true;
        }
        if (subscription.subscribe.includes('email')) {
          needEmail = true;
        }
      }
    }

    if ( needEmail ) {
      currentState.auth.login_type = "email";
    } else if ( needMobile ) {
      currentState.auth.login_type = "mobile";
    }
  },
  resetAuth(currentState) {
    currentState.isLogin = false;
    currentState.auth =  null;
    currentState.login =  {};
    currentState.tunnel =  "";
  },
  isLogin(currentState) {
    currentState.isLogin = true;
  }
};

export { mutations, state };