import axios from 'axios';
import store from '../../store';

export default (headers = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'x-env-key':  store.state.settings.env ? store.state.settings.env : 'les2alpes',
}) =>
  axios.create({
    timeout: 30000,
    headers,
    responseType: 'json',
    withCredentials: true,
    baseURL: (store.state.settings.context == "app" && !window.location.origin.includes('.travel')) || window.location.origin.includes('preprod') ?
        'https://api.destination-connect.rc-preprod.com/destination-connect' :
        // 'http://localhost:8091/destination-connect' :
        'https://api.madestinationconnect.travel/destination-connect',
  });
