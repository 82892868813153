import contactService from '@s/contacts';

export default {
  async getInformation({ commit }) {
    const information = await contactService.getInformation();
    commit('setInformation', information);
  },
  setMobile({ commit }, newMobile) {
    commit('setMobile', newMobile);
  },
  resetInformation({ commit }) {
    commit('resetInformation');
  },
  setInformationByKey({ commit }, data) {
    commit('setInformationByKey', data);
  }
};