<template lang="pug">
    .account.block__destinationConnect(:class="{ 'mobile-display': isMobileDisplay }")
        account-menu(v-if="!isMobileDisplay")
        .close-app(v-if="!isMobileDisplay" @click="closeApp") Fermer
          .material-icons close
        <transition name="fade" mode="out-in">
          router-view.main-content
        </transition>
</template>

<script>
import AccountMenu from '@v/Account/AccountMenu.vue'

export default {
  components: {
      AccountMenu
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
      this.getContactInformations();
  },
  methods: {
    getContactInformations() {
      this.loading = true;
      this.$store.dispatch('contacts/getInformation');
      this.loading = false;
    },
    closeApp() {
      if (this.$store.state.settings.context == "woody") {
        const event = new CustomEvent("closeDestiConnect");
        document.querySelector('destination-connect-front').dispatchEvent(event);
      }
      document.querySelector('destination-connect-front').classList.remove('active');
      let routeName;
      switch(this.$route.name) {
        case 'PersonalInfo':
          routeName = 'informations';
          break;
        case 'AlertPeriods':
          routeName = 'notifications';
          break;
        case 'Subscriptions':
          routeName = 'subscriptions';
          break;
        case 'Favorites':
          routeName = 'favorites';
          break;
        case 'History':
          routeName = 'page_visited';
          break;
        case 'Deleted':
          routeName = 'deleted';
          break;
        default:
          routeName = null;
          break;
      }
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'close_popup',
        view: routeName ? routeName : null,
      });
    },
  },
}
</script>