export default {
  setUserAlerts({commit}, periods) {
    commit('setUserAlerts', periods);
  },
  setAlertPeriod({ commit }, newPeriod) {
    commit('setAlertPeriod', newPeriod);
  },
  setSubscriptions({ commit }, newSubscriptions) {
    commit('setSubscriptions', newSubscriptions);
  },  
  setSubscriptionsType({ commit }, newSubscriptions) {
    commit('setSubscriptionsType', newSubscriptions);
  },
  setAlertPeriodTitle({ commit }, title) {
    commit('setAlertPeriodTitle', title);
  },
  reset({commit}) {
    commit('reset');
  },
  hasSet({commit}, hasSet) {
    commit('hasSet', hasSet);
  }
};