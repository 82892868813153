<template lang="pug">
  transition(name='popup')
    #modal(:class="{'open' : open}")
      .modal-wrapper
        i.modal-close.material-icons(@click="close") close
        .modal-container
          component(v-if="open" :is="type")
</template>

<script>
import ValidateOTP from '@/components/ValidateOTP.vue';
import MissingInformation from '@/components/MissingInformation.vue';
import Disconnect from '@/components/Disconnect.vue';

export default {
  computed: {
    open() {
      return this.$store.getters["modal/isOpened"]();
    },
    type() {
      return this.$store.getters["modal/getType"]();
    }
  },
  methods: {
    close() {
      this.$store.commit('modal/resetModal');
      if( this.$store.state.contacts.information.tmp_login && !this.$store.state.contacts.information.tmp_login.validated ) {
        this.$store.dispatch('contacts/getInformation', false);
      }
      if(this.type == "Disconnect") {
        this.$store.dispatch("tagManager/sendEvent", {
          event: 'popup_disconnected_closed' ,
        });
      }
    },
  },
  components: {
    ValidateOTP,
    Disconnect,
    MissingInformation
  },
}
</script>