<template lang="pug">
section.block__Dashboard.fader(v-if="!isMobileDisplay")
  vue-scroll
    .head
      welcome
      illu.img-dashboard
    .containt
      .cards
        .card(v-for="card in cards" v-if="!(card.route == 'AlertPeriods' && noNotif)")
          .card-title {{ card.title }}
          .card-description {{ card.description1 }} <span style="color: #202945; font-weight: 200">{{ card.description2 }}</span> {{ card.description3 }}  <span style="color: #202945; font-weight: 200">{{ card.description4 }}</span> {{ card.description5 }}
          .link(@click="redirect(card.route)") {{ card.manage }}
            i.material-icons east
</template>

<script>
import Welcome from '@/views/Login/Welcome.vue'
import Illu from '@/assets/illu.svg';

export default {

  components: {
    Welcome,
    Illu
  },
  data() {
    return {
    }
  },
  mounted() {
    this.checkConnection();
  },
  computed: {
      noNotif() {
        return this.$store.state.alerts.myAlerts[0]?.subscriptions_type.length == 0;
      },
      cards() {
          return {
              'card1' : {
                'title': this.$t('dashboard.card1.title'),
                'description1': this.$t('dashboard.card1.description1'),
                'description2': this.$t('dashboard.card1.description2'),
                'description3': this.$t('dashboard.card1.description3'),
                'description4': this.$t('dashboard.card1.description4'),
                "manage": this.$t('dashboard.manage') + this.$t('dashboard.card1.title').toLowerCase(),
                'route': "PersonalInfo"

              },
              'card2' : {
                'title': this.$t('dashboard.card2.title'),
                'description1': this.$t('dashboard.card2.description1'),
                'description2': this.$t('dashboard.card2.description2'),
                'description3': this.$t('dashboard.card2.description3'),
                "manage": this.$t('dashboard.manage') + this.$t('dashboard.card2.title').toLowerCase(),
                'route': "Subscriptions"
              },
              'card5' : {
                'title': this.$t('dashboard.card5.title'),
                'description1': this.$t('dashboard.card5.description1'),
                'description2': this.$t('dashboard.card5.description2'),
                "manage": this.$t('dashboard.manage') + this.$t('dashboard.card5.title').toLowerCase(),
                'route': "Accomodation"
              },
              // 'card4' : {
              //   'title': this.$t('dashboard.card4.title'),
              //   'description1': this.$t('dashboard.card4.description1'),
              //   'description2': this.$t('dashboard.card4.description2'),
              //   'description3': this.$t('dashboard.card4.description3'),
              //   'manage': this.$t('dashboard.manage') + this.$t('dashboard.card4.title').toLowerCase(),
              //   'route': 'BookmarksHistory'
              // },
              'card3' : {
                'title': this.$t('dashboard.card3.title'),
                'description1': this.$t('dashboard.card3.description1'),
                'description2': this.$t('dashboard.card3.description2'),
                'description3': this.$t('dashboard.card3.description3'),
                'manage': this.$t('dashboard.manage') + this.$t('dashboard.card3.title').toLowerCase(),
                'route': 'Favorites'
              },
          }
      }
  },
  methods: {
    async checkConnection() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      if ( authResponse == "error" || authResponse.connected == false) {
          this.$router.push({ name: 'Login' });
      }
      else if (this.isMobileDisplay) {
        this.$router.push({ name: 'AccountMenu' });
      }
    },
    redirect(route) {
      if(route == "Accomodation") {
        window.open(this.$store.state.settings.site_lang == "fr" ? "https://reservation.les2alpes.com/identification.html" : "https://booking.les2alpes.com/identification.html", '_blank');
        return;
      }
      this.$router.push({ name: route });

     let routeName;
      switch(route) {
        case 'PersonalInfo':
          routeName = 'information';
          break;
        case 'AlertPeriods':
          routeName = 'notification';
          break;
        case 'Favorites':
          routeName = 'favorites';
          break;
        case 'BookmarksHistory':
          routeName = 'page_visited';
          break;
        default:
          break;
      }
      if(routeName) {
        this.$store.dispatch("tagManager/sendEvent", {
          event: 'dashboard_' + routeName ,
        });
      }
    }
  },
}
</script>