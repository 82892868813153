<template lang="pug">
  .block__personalInfo(:class="{ 'mobile-display': isMobileDisplay, 'fader': !isMobileDisplay }")
    vue-scroll
      .header-title(v-if="isMobileDisplay")
        .prev-btn.material-icons(@click="goBack") arrow_back_ios
        .main-title {{ $t('personnal_information.title') }}

      .info(v-if="$route.name === 'PersonalInfo'")
        .center
          .main-title(v-if="!isMobileDisplay") {{ $t('personnal_information.title') }}
          .tunnel-subtitle(v-if="isMobileDisplay") {{ $t('my_profile')}}
          //- button.next-btn.save-info(v-if="!isMobileDisplay" @click="saveInfo()") {{ $t('save') }}

          .wrapper
            .edit
              label {{ $t('personnal_information.first_name') }}
              text-field(
                modify=true
                disabled=false
                ref="firstNameField"
                v-model="firstnameInput"
                @input="updateValue('firstname')"
              )
            .edit
              label {{ $t('personnal_information.last_name') }}
              text-field(
                modify=true
                disabled=false
                ref="lastNameField"
                v-model="lastnameInput"
                @input="updateValue('lastname')"
              )
            .edit
              label {{ $t('personnal_information.birthday') }}
              text-field(
                modify=true
                disabled=false
                isDate=true
                ref="birthdayField"
                v-model="birthdayInput"
                :placeholder="$t('personnal_information.birthday')"
                @input="updateValue('birthday')"
              )

            .edit(v-if="getProfileOptions.length")
              label {{ $t('personnal_information.profile') }}
              selector(
                :options="getProfileOptions"
                v-model="profileInput"
                :placeholder="$t('personnal_information.profile')"
                :translate="true"
                @input="updateValue('profile')"
              )
            .edit
              label {{ $t('personnal_information.interests') }}
              selector(
                :options="getInterestsOptions()"
                :checkable="true"
                v-model="interestInput"
                :placeholder="$t('personnal_information.interests')"
                :translate="true"
                @input="updateValue('interests')"
              )
            .edit
              label {{ $t('personnal_information.country') }}
              selector(
                :options="getCountryOptions"
                v-model="countryInput"
                :placeholder="$t('personnal_information.country')"
                @input="updateValue('country')"
              )
            .edit
              label {{ $t('personnal_information.postal_code') }}
              text-field(
                length=6
                modify=true
                disabled=false
                ref="postalField"
                v-model="postalInput"
                :placeholder="$t('personnal_information.postal_code')"
                @input="updateValue('zipcode')"
              )
            .edit
              label {{ $t('personnal_information.email_address') }}
                span.asterisk *
              text-field(
                modify=true
                disabled=false
                checkEmail=true
                ref="emailField"
                v-model="emailInput"
                :placeholder="$t('personnal_information.email_address')"
                @input="updateValue('email')"
              )
            .edit
              label {{ $t('personnal_information.mobile_number') }}
                span.asterisk *
              .phone-field(ref="phoneFieldContainer" @click="enableInput")
                phone-field(
                  ref="phoneField"
                  :translations="translations"
                  v-model="mobileInput"
                  default-country-code="FR"
                  :placeholder="$t('personnal_information.mobile_number')"
                  :class="{ error: error }"
                  v-closable="{exclude: ['modify', 'save'], handler: 'abortPhoneField' , active: phoneFieldState == 'modify'}"
                  @input="validateMobile()"
                )
                span.checkmark(v-if="mobileInput")
                  .checkmark_stem
                  .checkmark_kick
              .error-message.mobile(v-if="!isMobileDisplay" ref="errorMobile") {{ $t('personnal_information.input_mobile.error.invalid') }}
          .legend
            span.asterisk *
            div {{ $t('required_fields') }}
          sessions
          .spaceHorizontale
          .spaceHorizontale
          .spaceHorizontale
          .spaceHorizontale
          .spaceHorizontale
      router-view(v-else)
</template>

<script>
import Vue from 'vue'
import TextField from '@c/rc-text-field/RcTextField.vue';
import Selector from '@c/RcSelector.vue';
import PhoneField from 'vue-phone-number-input';
import Sessions from '@c/Sessions.vue';
import contactService from '@s/contacts';
import { parsePhoneNumber } from 'libphonenumber-js';

let handleOutsideClick;

Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
    e.stopPropagation()
    const { handler, exclude} = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          if (vnode.context.$refs[refName]) {
            clickedOnExcludedEl = vnode.context.$refs[refName].contains(e.target)
          }
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }

    const {active} = binding.value;

    if (active) {
      document.addEventListener('click', handleOutsideClick)
    }
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
  }
});

export default {
  components: {
    TextField,
    PhoneField,
    Sessions,
    Selector
  },
  data() {
    return {
      loading: false,
      error: false,
      phoneFieldState: "modify",
      phoneEditing: false,
      translations: {
        countrySelectorLabel: this.$t('phone_input.country_selector_label'),
        countrySelectorError: this.$t('phone_input.country_selector_error'),
        phoneNumberLabel: this.$t('phone_input.phone_number_label'),
        example: this.$t('phone_input.example'),
      },
      profiles: [],
      mobileInput: '',
      emailInput: '',
      firstnameInput: '',
      lastnameInput: '',
      countryInput: '',
      birthdayInput: '',
      profileInput: '',
      postalInput: '',
      interestInput: [],
      completeInterestsList: [],
    };
  },
  async mounted() {
    this.checkConnection();
    this.$store.dispatch('contacts/getInformation', false);
    this.setPhoneFieldState(true);
    this.mobileInput = this.mobile;
    this.emailInput = this.email;
    this.firstnameInput = this.firstname;
    this.lastnameInput = this.lastname;
    this.countryInput = this.country;
    this.birthdayInput = this.birthday;
    this.profileInput = this.profile;
    this.postalInput = this.postal;

    this.$refs.phoneField.$el.querySelector('.select-country-container').style['pointer-events'] = 'none';

    this.completeInterestsList = await contactService.getAllInterests();
    const response = await contactService.getProfiles();
    this.profiles = response;
  },
  created() {
    this.$store.dispatch('settings/setBackDesktop', false);

    var self = this;//Enable call to this in function
    document.addEventListener('swiped-right', function(e) {
      self.goBack();
    });

  document.addEventListener("keyup", event => {
    if (event.keyCode === 13 && this.phoneFieldState == 'save') {
      this.updateValue('mobile');
    }
  });
    this.birthdayInput = this.birthday;
  },
  computed: {
    interestChecked() {
      return this.$store.state.contacts.information.interests;
    },
    alertsPeriod() {
      return this.$store.state.alerts.alertsPeriod;
    },
    email: {
      get() {
        return this.$store.state.contacts.information.email || '';
      },
      set(val) {
        this.$store.state.contacts.information.email = val ? val : '';
      }
    },
      mobile: {
      get() {
        return this.$store.state.contacts.information.mobile || '';
      },
      set(val) {
        this.$store.state.contacts.information.mobile = val ? val : '';
      }
    },
    firstname: {
      get() {
        return this.$store.state.contacts.information.firstname || '';
      },
      set(val) {
        this.$store.state.contacts.information.firstname = val;
      }
    },
    lastname: {
      get() {
        return this.$store.state.contacts.information.lastname || '';
      },
      set(val) {
        this.$store.state.contacts.information.lastname = val;
      }
    },
    country: {
      get() {
        return this.$store.state.contacts.information.country || '';
      },
      set(val) {
        this.$store.state.contacts.information.country = val ? val : '';
      }
    },
    profile: {
      get() {
        return this.$store.state.contacts.information.profile || '';
      },
      set(val) {
        this.$store.state.contacts.information.profile = val ? val : '';
      }
    },
    birthday: {
      get() {
        return this.$store.state.contacts.information.birthday || '';
      },
      set(val) {
        this.$store.state.contacts.information.birthday = val ? val : '';;
      }
    },
    postal: {
      get() {
        return this.$store.state.contacts.information.zipcode || '';
      },
      set(val) {
        this.$store.state.contacts.information.zipcode = val;
      }
    },
    modalOpened: {
      get() {
        return this.$store.state.modal.opened;
      }
    },
    getCountryOptions() {
      return contactService.getCountries();
    },
    getProfileOptions() {
      return this.profiles;
    },
  },
  watch: {
    email(newValue) {
      this.emailInput = newValue;
    },
    mobile(newValue) {
      this.mobileInput = newValue;
    },
    modalOpened(newValue) {
      if(!newValue) {
        this.emailInput = this.email;
        this.mobileInput= this.mobile;
      }
    }
  },
  methods: {
    async checkConnection() {
      const authResponse = await this.$store.dispatch('auth/getAuth');
      if ( authResponse == "error" || authResponse.connected == false) {
          this.$router.push({ name: 'Login' });
      }
    },
    getInterestsOptions() {
      return this.completeInterestsList.map(item => {
        if (this.interestChecked.find(interest => interest === item.value)) {
          return {...item, status: true};
        } else {
          return item;
        }
      })
    },
    async updateValue(type) {
      let value;
      let response;
      let body;
      switch(type) {
        case 'firstname':
        case 'lastname':
          if(this.firstnameInput != this.firstname || this.lastnameInput != this.lastname ) {
            value = type == "firstname" ? this.firstnameInput : this.lastnameInput;
            body = {};
            body[type] = value;
            response = await contactService.setInformations(body);
            if ( response == "success") {
              this.$store.dispatch('contacts/setInformationByKey', {
                key: type,
                value: value
              });
            }
          }

        break;
        case 'zipcode':
          value = this.postalInput;
          body = {};
          body[type] = value;
          response = await contactService.setInformations(body);
          if ( response == "success") {
            this.$store.dispatch('contacts/setInformationByKey', {
              key: type,
              value: value
            });
          }
          break;
        case 'country':
          value = this.countryInput;
          body = {};
          body[type] = value;
          response = await contactService.setInformations(body);
          if ( response == "success") {
            this.$store.dispatch('contacts/setInformationByKey', {
              key: type,
              value: value
            });
          }
          break;
        case 'birthday':
          value = this.birthdayInput;
          body = {};
          body[type] = value;
          this.birthdayInput = this.birthday;
          if(value.length == 10) {
            response = await contactService.setInformations(body);
            if ( response == "success") {
              this.$store.dispatch('contacts/setInformationByKey', {
                key: type,
                value: value
              });
            }
          }
          break;
        case 'profile':
          value = this.profileInput;
          body = {};
          body[type] = value;
          response = await contactService.setInformations(body);
          if ( response == "success") {
            this.$store.dispatch('contacts/setInformationByKey', {
              key: type,
              value: value
            });
          }
          break;
        case 'email':
          if(!(this.emailInput == this.email) ) {
            const validationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!validationRegex.test(this.emailInput)) {
              this.$refs.emailField.inputError(this.$t(`personnal_information.input_email.error.invalid`));
              return;
            }

            value = this.emailInput;
            this.loading = true;
            const emailCode = await contactService.sendValidationCode({ type: type, value });
            if( emailCode == 'UserAlreadyExists') {
              //no changes
              this.$toast( this.$t('personnal_information.input_email.error.UserAlreadyExists'), {
                toastClassName: "toast-error",
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              this.loading = false;
              return;
            }
            else if (emailCode !== 'success') {
              //email send error -> quota opt exceed -> reset email & alert user
              this.$toast( this.$t('toast.otp-quota'), {
                toastClassName: "toast-error",
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              this.loading = false;
              this.$store.dispatch('contacts/getInformation', false); //reset value of field
              return;
            }
            this.loading = false;

            this.$store.dispatch('contacts/setInformationByKey', {
              key: "tmp_login",
              value: {
                type: "email",
                value: value,
                validated: false
              }
            });

            this.openModal();
          }

        break;
        case 'mobile':
          if(this.phoneFieldState == 'modify') {
            this.setPhoneFieldState(false);
            break;
          }
          const { countryCode } = this.$refs.phoneField.results;
          value = parsePhoneNumber(this.mobileInput, countryCode).format('E.164');

          if(!( this.mobile && (value == parsePhoneNumber(this.mobile, countryCode).format('E.164'))) ) {

            this.updating = true;
            if (!this.validateMobile()) {
              return;
            }
            const mobileCode = await contactService.sendValidationCode({ type: type, value });
            if( mobileCode == 'UserAlreadyExists') {
              //no changes
              this.$toast( this.$t('personnal_information.input_mobile.error.UserAlreadyExists'), {
                toastClassName: "toast-error",
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              this.loading = false;
              return;
            }
            else if (mobileCode !== 'success') {
              //email send error -> quota opt exceed -> reset email & alert user
              this.$toast( this.$t('toast.otp-quota'), {
                toastClassName: "toast-error",
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              this.loading = false;
              this.$store.dispatch('contacts/getInformation', false); //reset value of field
              return;
            }
            this.loading = false;

            this.$store.dispatch('contacts/setInformationByKey', {
              key: "tmp_login",
              value: {
                type: "mobile",
                value: value,
                validated: false
              }
            });

            this.openModal();
          }
          this.setPhoneFieldState(true);
        break;
        case 'interests':
          let newInterestCheck = [...this.interestChecked];
          if (newInterestCheck.find(interest => interest === this.interestInput.value)) {
            newInterestCheck = newInterestCheck.filter(interest => interest !== this.interestInput.value);
          } else {
            newInterestCheck.push(this.interestInput.value);
          }
          body = {};
          body[type] = newInterestCheck;
          response = await contactService.setInformations(body);
          if ( response == "success") {
            this.$store.dispatch('contacts/setInformationByKey', {
              key: type,
              value: newInterestCheck
            });
            this.completeInterestsList = await contactService.getAllInterests();
          }
        break;
      }
    },
    // saveInfo() {
    //   this.$toast( this.$t('toast.save'), {
    //     toastClassName: "toast-save",
    //     position: "top-center",
    //     timeout: 3000,
    //     closeOnClick: true,
    //     pauseOnFocusLoss: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     draggablePercent: 0.6,
    //     showCloseButtonOnHover: false,
    //     hideProgressBar: false,
    //     closeButton: "button",
    //     icon: true,
    //     rtl: false
    //   });
    // },
    openModal() {
      this.$store.commit('modal/setType', 'ValidateOTP');
      this.$store.commit('modal/setOpened', true);
    },
    validateMobile() {
      let mobileIsValid = true;
      try {
        const mobile = parsePhoneNumber(this.mobileInput || '', 'FR');
        mobileIsValid = mobile.isPossible() && mobile.isValid();
      } catch (e) {
        mobileIsValid = false;
      }

      if (!mobileIsValid) {
        this.inputError(this.$t('personnal_information.invalid_mobile'));
      }

      return mobileIsValid;
    },
    setPhoneFieldState(state) {
      const phoneField = this.$refs.phoneField;
      if (state) {
        if (phoneField) {
          phoneField.$el.querySelector('.country-selector__input').setAttribute('disabled', state);
          phoneField.$el.querySelector('.input-tel__input').setAttribute('disabled', state);
          phoneField.$el.querySelector('.select-country-container').style['pointer-events'] = 'none';
          phoneField.$el.querySelector('.input-tel__input').blur();
          this.phoneFieldState = 'modify';
        }
      } else {
        this.phoneFieldState = "save";
        if (phoneField) {
          phoneField.$el.querySelector('.select-country-container').style['pointer-events'] = 'initial';
          phoneField.$el.querySelector('.country-selector__input').removeAttribute('disabled');
          phoneField.$el.querySelector('.input-tel__input').removeAttribute('disabled');
          phoneField.$el.querySelector('.input-tel__input').focus();
        }
      }
    },
    inputError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = null;
      }, 2000);
    },
    goBack() {
      this.$store.commit("pageTransition/setPageTransition", "back");

      if (this.isMobileDisplay) {
        this.$router.push({ name: 'AccountMenu' });
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    enableInput() {
      if(this.isMobileDisplay || this.phoneFieldState == 'modify') {
        if(this.phoneEditing) {
          this.updateValue('mobile');
          this.phoneEditing = false;
        }
        else {
          this.phoneEditing = true;
          this.setPhoneFieldState(false);
        }
      }
      else if(!this.isMobileDisplay) {
        this.setPhoneFieldState(false);
      }
    },
    abortPhoneField() {
      if(this.$route.name == 'PersonalInfo' && !this.isMobileDisplay && !this.$store.state.modal.opened) {
        this.updateValue('mobile');
      }
    },
  },
}
</script>
<style lang="css" scoped>
  .checkmark {
  top: 11px;
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg) scale(1.4);
  position: absolute;
  right: 18px;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color: #05B400;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#05B400;
    left:8px;
    top:12px;
}
</style>
