<template lang="pug">
  section.block__alert(:class="{'fader' : !isMobileDisplay}")
    header-container(type="create_account")
    .containt
      vue-scroll
        .sub-containt
          .tunnel-title {{ $t('my_stay_dates') }}
          .tunnel-text {{ $t('my_stay_dates_desc') }}
          mobile-period-choice(v-if="openedCalendar && isMobileDisplay" @close="openedCalendar=false" :init="true")

          .holiday-dates(v-if="isMobileDisplay" :class="{'opened': openedCalendar}")
            .header(@click="editDates")
              .header-input
                i.material-icons.calendar event
                i.material-icons.edit(v-if="!openedCalendar") edit
              .notifications-title {{ alertsPeriod.title && alertsPeriod.title != '' ? alertsPeriod.title.toUpperCase() : 'MON SEJOUR' }}
              .dates {{ $t('alerts.from') }} <strong>{{ formatDate(alertsPeriod.start_date) }} </strong>  <br>  {{ $t('alerts.to') }} <strong> {{ formatDate(alertsPeriod.end_date) }} </strong>

          .wrapper__date(v-if="!isMobileDisplay")
            .period-summary
              .input-date
                i.material-icons event
                text-field.input-title(
                      :isDisabled="false"
                      :value="!!alertsPeriod.title && alertsPeriod.title != '' ? alertsPeriod.title.toUpperCase() : 'MON SEJOUR'"
                      ref="notificationTitle"
                      v-model="alertsPeriod.title"
                      @input="sendEvent();alertsPeriod.title = alertsPeriod.title.toUpperCase()"
                    )
              .date-container
                .date {{ $t('alerts.from') }} <strong>{{ formatDate(alertsPeriod.start_date) }} </strong>{{ $t('alerts.to') }} <strong> {{ formatDate(alertsPeriod.end_date) }} </strong>
            period-choice-desktop(
              class="opened"
              :value=`{
                from: alertsPeriod.start_date ? $moment.unix(alertsPeriod.start_date / 1000) : null,
                to: alertsPeriod.end_date ? $moment.unix(alertsPeriod.end_date / 1000) : null }
              `
              @periodChange="setAlertPeriod"
            )
            .actions(v-if="!isMobileDisplay")
              .skip(
                @click="skip"
              ) Passer cette étape
              .right-btns
                .reset-period(v-if="!!alertsPeriod.start_date" @click="setAlertPeriod({ from: null, to: null })") {{ $t('cancel') }}
                button.next-btn(
                  v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}"
                  @click="next"
                ) {{ $t('apply') }}

        .actions(v-if="isMobileDisplay && !openedCalendar")
          .skip(
            @click="skip"
          ) Passer cette étape
          button.next-btn(
            v-wave="{color: '#202945', initialOpacity: 0.5, easing: 'ease-in'}"
            @click="next"
          ) {{ $t('apply') }}
</template>

<script>
import AlertPeriodChoice from '@/components/period-choice/PeriodChoice.vue';
import PeriodChoiceDesktop from '@/components/period-choice/PeriodChoiceDesktop.vue';
import TextField from '@c/rc-text-field/RcTextField.vue';
import MobilePeriodChoice from '@v/Account/MobilePeriodChoice.vue';
import HeaderContainer from '@/views/HeaderContainer.vue';
import auth from '../../services/auth';
const delay = 500;

export default {
  components: {
    AlertPeriodChoice,
    PeriodChoiceDesktop,
    TextField,
    HeaderContainer,
    MobilePeriodChoice
  },
  data() {
    return {
      openedCalendar: false
    }
  },
  created(){
    this.$store.dispatch('settings/setBackDesktop', true);
    this.setAlertPeriod({'from': null, 'to': null});
    this.alertsPeriod.title = "MON SEJOUR";//default value in input field
  },
  async beforeMount() {
    const authResponse = await this.$store.dispatch('auth/getAuth');
    this.$store.dispatch('alerts/setSubscriptionsType', authResponse.alerts_period.subscriptions_type);
    if (this.$store.state.alerts.alertsPeriod.subscriptions_type.length === 0) {
      this.skip();
    }
  },
  methods: {
    editDates() {
      this.openedCalendar = true;
    },
    formatDate(timestamp) {
      return timestamp ? this.$moment.unix((timestamp + 2 * 60 * 60 * 1000) / 1000).locale('fr').format('ddd DD MMM YYYY') : '...';
    },
    next() {
      setTimeout(() => {
        if (this.alertsPeriod.start_date && this.alertsPeriod.end_date) {
          this.$store.dispatch('alerts/hasSet', true);
          this.$router.push({ name: 'AlertSubscriptions' });
          this.$store.dispatch("tagManager/sendEvent", {
            event: 'create_account_apply' ,
          });
        }
      }, delay);
    },
    skip() {
      this.setAlertPeriod({ from: null, to: null });

      this.$store.dispatch('auth/setOTPLogin', {
        token: this.$store.state.auth.login.token,
        type: this.$store.state.auth.auth.login_type,
        value: this.$store.state.auth.auth[this.$store.state.auth.auth.login_type],
      });
      this.$store.dispatch('alerts/hasSet', false);
      this.$router.push({ name: 'FinishAuthentication'});
      this.$store.dispatch("tagManager/sendEvent", {
        event: 'create_account_pass_step' ,
      });
    },
    setAlertPeriod(period) {
      let newPeriod = this.alertsPeriod;
      newPeriod.start_date = newPeriod.start = period.from ? this.$moment(period.from).unix() * 1000 : null;
      newPeriod.end_date = newPeriod.end = period.to ? this.$moment(period.to).unix() * 1000 : null;
      this.$store.dispatch('alerts/setAlertPeriod', newPeriod);
    },
    sendEvent() {
      this.$store.dispatch("tagManager/sendEvent", {
          event: 'create_account_name',
        });
    }
  },
  computed: {
    alertsPeriod() {
      return this.$store.state.alerts.alertsPeriod;
    },
  },
}
</script>