<template lang="pug">
  div#init
</template>

<script>
export default {
  mounted() {
    this.destinationConnectAuth();
  },
  methods: {
    async destinationConnectAuth() {
      this.loading = true;
      const authResponse = await this.$store.dispatch('auth/getAuth');

      if ( authResponse == "error" ) {
        console.warn('An error occured while fetching api.');
        this.$router.push({ name: 'Login' });
      } else {
        const { connected, token_consumed, suggested_start_date, suggested_end_date, alerts_period } = authResponse;
        const { start_date, end_date } = alerts_period;

        if (connected) {
          if (this.$store.state.settings.context != "app") {
            const connectEvent = new Event('destiConnectAuth');
            window.dispatchEvent(connectEvent);
          }

          if (token_consumed === null) {
            this.continueRoute();
            return;
          }
          if (token_consumed) {
            this.continueRoute();
            return;
          }
          const dateOverlap = this.getDateOverlap(suggested_start_date, suggested_end_date, start_date, end_date);
          if (dateOverlap === 'contained') {
            this.continueRoute();
            return;
          }

          // simple tunnel
          if (this.$route.name != 'AlertPeriods') {
            this.$router.push({ name: 'AlertPeriods' });
          }
          this.loading = false;
          return;
        } else if (this.$store.state.settings.context != "app") {
          const disconnectEvent = new Event('destiConnectEnd');
          window.dispatchEvent(disconnectEvent);
        }

        if (token_consumed === null) {
          // login tunnel
          if (this.$route.name != 'Login') {
            this.$router.push({ name: 'Login' });
          }
          this.loading = false;
          return;
        } if (token_consumed) {
          // Consumed Token tunnel
          if (this.$route.name != 'ConsumedToken') {
            this.$router.push({ name: 'ConsumedToken' });
          }
          this.loading = false;
          return;
        }

        if (!token_consumed) {
          // complete tunnel
          if (this.$route.name != 'Welcome') {
            this.$router.push({ name: 'CreateAccount' });
          }

          this.loading = false;
          return;
        }
      }
    },
    continueRoute() {
      if (this.$route.name == "PersonalInfo" || this.$route.name == "Favorites" || this.$route.name == "AlertPeriods") {
        // stay here
      } else if(this.$route.name != 'AccountMenu') {
        this.$router.push({ name: 'AccountMenu' });
      }
      this.loading = false;
    },
  }
}
</script>