<template lang="pug">
  .block__alertPeriodDesktop
    .containt
      .alert-subscriptions
        .main-title {{ $t('alert_periods.subscriptions.title') }}
        .text {{ $t('alert_periods.subscriptions.text') }}
        .spaceHorizontale
        alert-list.block__alert(
          v-if="alertPeriod"
          :subscriptions="alertPeriod.subscriptions_type"
          @change="handleSubscriptionChanges"
        )
      .alert-period-choice(v-if="alertPeriod")
        .main-title {{ $t('alert_periods.period_choice.title') }}
        .alert-period-date
          .start-date
            .from {{ $t('from') }}
            .date {{ formattedValue.from ? formatDate(formattedValue.from) : '' }}
          .divider
          .end-date
            .to {{ $t('to') }}
            .date {{ formattedValue.to ? formatDate(formattedValue.to) : '' }}
        period-choice-desktop(
          :value="formattedValue"
          @periodChange="handlePeriodInput"
        )
        .actions
          button.save-btn(@click="saveAlertPeriod('desktop')") {{ $t('alert_periods.period_choice.apply') }}
</template>

<script>
import PeriodChoiceDesktop from '@c/period-choice/PeriodChoiceDesktop';
import alertPeriod from './alertPeriod';

export default {
  mixins: [alertPeriod],

  components: {
    PeriodChoiceDesktop,
  },
  methods: {
    formatDate(timestamp) {
      return this.$moment.unix((timestamp + 2 * 60 * 60 * 1000) / 1000).locale('fr').format('ddd DD MMM YYYY');
    },
  },
}
</script>

<style lang="scss">
  // #alert-period-desktop {
  //   padding-top: 70px;
  //   width: 100%;
  //   flex-wrap: wrap;
  //   display: flex;
  //   justify-content: space-around;
  //   overflow-y: auto;
  //   .main-title {
  //     text-align: left;
  //   }
  //   .alert-period-date {
  //     @include date-period;
  //     justify-content: flex-start;
  //     margin: 35px 0;
  //     i {
  //       margin-left: 0;
  //     }
  //   }
  //   .alert-subscriptions {
  //     width: 340px;
  //     .main-title {
  //       margin-bottom: 102px;
  //     }
  //     .text {
  //       margin-bottom: 48px;
  //     }
  //   }
  //   .actions {
  //     display: flex;
  //     justify-content: flex-end;
  //     align-items: center;
  //     padding: 30px 0;
  //     .reset-period {
  //       color: var(--secondary);
  //       text-decoration: underline;
  //       cursor: pointer;
  //       margin-right: 48px;
  //     }
  //     .save-btn {
  //       @include primary-button;
  //       width: 160px;
  //     }
  //   }
  //   > div {
  //     margin: 0 20px;
  //   }
  // }
</style>