<template lang="pug">
  section.block__alert(:class="{'fader' : !isMobileDisplay}")
    header-container.subscriptions(type="create_account")
    vue-scroll
      .containt.subscriptions
        .tunnel-title {{ $t('alerts.my_alerts') }}
        .tunnel-text.subscriptions {{ $t('alerts.my_alerts_description') }}
        alert-list.block.alert-list(
          :subscriptions="alertsPeriod.subscriptions_type"
          :disabled="!alertsPeriod.start_date"
          @change="setSubscriptions"
        )
        .actions
          .skip(
                  @click="skip"
                ) Passer cette étape
          button.save-btn(@click="save") {{ $t('alerts.save') }}
        .spaceHorizontale(v-if="!isMobileDisplay")
</template>

<script>
import AlertList from '@/components/alerts/AlertList/AlertList.vue';
import HeaderContainer from '@/views/HeaderContainer.vue';

export default {
  components: {
    AlertList,
    HeaderContainer,
  },
  created() {
    if (!this.alertsPeriod.start_date) {
      this.$router.push({ name: 'AlertPeriod' });
    }
    this.$store.commit('contacts/resetInformation');
  },
  beforeMount() {
    if (this.$store.state.alerts.myAlerts[0]?.subscriptions_type.length === 0) {
      this.skip();
    }
  },
  methods: {
    save() {
      this.$store.dispatch('auth/setOTPLogin', {
        token: this.$store.state.auth.login.token,
        type: this.$store.state.auth.auth.login_type,
        value: this.$store.state.auth.auth[this.$store.state.auth.auth.login_type],
      });
      this.$router.push({ name: 'FinishAuthentication' });
    },
    setSubscriptions(subscriptions) {
      this.$store.dispatch('alerts/setSubscriptions', subscriptions);
    },
    skip() {
      this.$store.dispatch('auth/setOTPLogin', {
        token: this.$store.state.auth.login.token,
        type: this.$store.state.auth.auth.login_type,
        value: this.$store.state.auth.auth[this.$store.state.auth.auth.login_type],
      });

      this.$router.push({ name: 'FinishAuthentication'});
    },
  },
  computed: {
    alertsPeriod() {
      return this.$store.state.alerts.alertsPeriod;
    },
  },
}
</script>