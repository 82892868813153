export default {
    sendEvent({ rootState, commit }, data = {}) {
      commit('setLastEvent', data.event);

      if (process.env.NODE_ENV !== 'test') {
        let dataLayer = window.dataLayer || [];
        let dataLayerRaccourci = window.dataLayerRaccourci || [];

        //Add corresponding site key on event
        data.site = rootState.settings.siteKey;

        dataLayer.push({
          event: data.event,
          ...data,
        });

        dataLayerRaccourci.push({
          event: data.event,
          ...data,
        });
      }
    },
  };
