import Vue from 'vue';

const state = {
    pageTransition: {
        name: "router-view",
        mode: "in-out"
    }
};

const mutations = {
    setPageTransition(state, value) {
        if ("default" === value) {
            state.name = "router-view";
            state.mode = "in-out";
        }
        if ("back" === value) {
            state.name = "router-view-back";
            state.mode = "";
        }
    }
};

export { mutations, state };