const state = {
  lastEvent: null,
};

const mutations = {
  setLastEvent(currentState, event) {
    currentState.lastEvent = event;
  },
};

export { mutations, state };
